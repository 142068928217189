import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { userService } from '../../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import { Button, InputText } from '../../../atoms'
import { Form } from '../../../molecules'

const FIELDS = {
  email: 'email',
  password: 'password',
}

const UserSessionsNew: React.FC<{}> = props => {
  const [errors, setErrors] = React.useState<{ login: string; email: string; password: string }>({
    login: '',
    email: '',
    password: '',
  })
  const [password, setPassword] = React.useState<null | string>(null)
  const [email, setEmail] = React.useState<null | string>(null)

  const handleUpdateForm = (error, hasNoError) => {
    setErrors({ ...error })
  }

  const handleSubmit = async (initialValues, values) => {
    try {
      const { redirectUrl } = await userService.signIn({ user: values })
      errors.login = null
      setErrors({ ...errors })
      location.href = redirectUrl
    } catch (error) {
      errors.login = I18n.t('session.invalid_mail_or_password')
      setErrors({ ...errors })
    }
  }
  return (
    <S.UserSessionsNew>
      <S.Panel>
        <S.Title>
          <p>{I18n.t('generic.login')}</p>
        </S.Title>
        <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
          <S.FormItem>
            <InputText
              required={true}
              name="email"
              defaultValue=""
              label=""
              placeholder={I18n.t('generic.email')}
              error={errors.email}
            />
          </S.FormItem>
          <S.FormItem>
            <InputText
              required={true}
              password={true}
              name="password"
              defaultValue=""
              label=""
              placeholder={I18n.t('generic.password')}
              error={errors.password}
            />
          </S.FormItem>
          <S.Buttons>
            {errors.login && <span className="error">{errors.login}</span>}
            <Button primary={true}>{I18n.t('generic.login')}</Button>
            <p>
              <a href="/users/password/new">{I18n.t('session.forgot_password')}</a>
            </p>
            <p>
              <a href="/users/sign_up">{I18n.t('generic.create_new_account')}</a>
            </p>
            <p>
              <a href="/users/confirmation/new">
                {I18n.t('devise.confirmations.new.resend_confirmation_instructions')}
              </a>
            </p>
          </S.Buttons>
        </Form>
      </S.Panel>
    </S.UserSessionsNew>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.UserSessionsNew = styled.main`
  padding-top: 36px;

  .error {
    color: ${COLORS.Danger};
    display: block;
    margin-bottom: 12px;
  }
`
S.Title = styled.div`
  text-align: center;
  margin-bottom: 28px;
`

S.Buttons = styled.div`
  margin-top: 24px;
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
  }
`

S.Panel = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
`

S.FormItem = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;

  & + & {
    margin-top: 16px;
  }

  p {
    width: 48px;

    span {
      margin-left: 0px;
    }
  }
`
export default UserSessionsNew
