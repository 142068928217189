import moment from 'moment'
import { extendMoment } from 'moment-range'

import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { ICartItem, IPost, IUser, IWindow } from '../../../core/interfaces'
import { reservationService } from '../../../core/services'
import { BREAKPOINT_TABLET_MOBILE } from '../../../static/constants'
import { setNativeValue } from '../../../utils/form'
import { formatPrice } from '../../../utils/number'
import { Button, InputTextArea, Spinner } from '../../atoms'

import { Form, InputDateTime } from '../../molecules'
import AddressForm, { ADDRESS_FIELDS, emptyToNull } from '../../organisms/AddressForm'
import StripeCardSelect from '../../organisms/StripeCardSelect'
import { IReservationParams } from '../posts/show'
const _moment = extendMoment(moment)

interface IProps {
  post: any
  cartItems: ICartItem[]
  reservationParams: IReservationParams
  user: IUser
}
interface IPriceAndAmount {
  amount: number
  price: number
}
declare var window: IWindow

const ReservationNew: React.FC<IProps> = ({ post, user, cartItems, reservationParams }) => {
  const [updatedAddress, setAddress] = React.useState(user.address)
  const [isCompleted, setIsCompleted] = React.useState(false)
  const [showOverlay, setShowOverlay] = React.useState(false)

  const [createdReservation, setCreatedReservation] = React.useState(null)
  const [updatedReservationParams, setReservationParams] = React.useState<IReservationParams>(
    reservationParams
  )
  const [taxiFare, setTaxiFare] = React.useState<number | null>(null)
  const [deliveryDistance, setDeliveryDistance] = React.useState<number | null>(null)
  const [totalPrice, setTotalPrice] = React.useState<number | null>(null)
  const [couponValues, setCouponValues] = React.useState<number[]>([])
  const [storeCouponValue, setStoreCouponValue] = React.useState<number | null>(null)
  const [campaignValue, setCampaignValue] = React.useState<number | null>(null)
  const [campaignName, setCampaignName] = React.useState<string>('')
  const [selectedDeliveryTime, setSelectedDeliveryTime] = React.useState<moment.Moment | null>(null)

  const [priceAndAmount, setPriceAndAmount] = React.useState<IPriceAndAmount>({
    amount: 0,
    price: 0,
  })

  const start = post.event_flag ? moment(post.delivery_start_time) : null
  const end = post.event_flag ? moment(post.delivery_end_time) : null
  let range = post.event_flag ? _moment.range(start, end) : null

  const startNYF = moment('2020/12/29 00:00')
  const endNYF = moment('2021/01/04 00:00')
  const newyearFilterRange = _moment.range(startNYF, endNYF)

  React.useEffect(() => {
    setPriceAndAmount(
      cartItems.reduce(
        (base, item) => {
          ;(base.amount += item.amount), (base.price += item.price * item.amount)
          return base
        },
        { amount: 0, price: 0 }
      )
    )
  }, [cartItems])

  const getDefaultStart = () => {
    const today = moment().startOf('day')
    const after_tomorrow = moment()
      .startOf('day')
      .add(2, 'days')
    let defaultStartDate = post.delivery_start_time

    if (post.force_order_date) {
      if (after_tomorrow > start.startOf('day')) {
        range = _moment.range(moment().add(1, 'days'), end)
        defaultStartDate = after_tomorrow.format('YYYY-MM-DD 00:00:00')
      }
    } else {
      if (today > start.startOf('day')) {
        range = _moment.range(moment(), end)
        defaultStartDate = today.format('YYYY-MM-DD 00:00:00')
      }
    }
    return defaultStartDate
  }

  const startTime = post.event_flag ? start.format('HH:mm') : null
  const endTime = post.event_flag ? end.format('HH:mm') : null

  React.useEffect(() => {
    const result = { ...updatedReservationParams }
    result.address = {
      ...result.address,
      ...updatedAddress,
    }
    result.reservation.distance = deliveryDistance || 0

    const deliveryTimeTarget = selectedDeliveryTime || moment()
    result.reservation.specified_delivery_time_at = deliveryTimeTarget.format('YYYY-MM-DD HH:mm:ss')
    setReservationParams(result)
  }, [updatedAddress, taxiFare, deliveryDistance, selectedDeliveryTime])

  const setTaxiFareFromDistance = guestLatLng => {
    const distanceMatrixService = new google.maps.DistanceMatrixService()
    const { latitude1, longitude1 } = post.address

    distanceMatrixService.getDistanceMatrix(
      {
        origins: [new google.maps.LatLng(latitude1, longitude1)],
        destinations: [guestLatLng],
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: google.maps.TrafficModel.BEST_GUESS,
        },
      },
      async (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const results = response.rows[0].elements
          const distance = results[0].distance.value
          const {
            taxiFare: calculatedTaxiFare,
            price,
            couponValues: coupon_values,
            storeCouponValue: store_coupon_value,
            campaignValue: campaign_value,
            campaignName: campaign_name,
          } = await reservationService.calcTaxiFareAndTotal(
            distance,
            reservationParams.reservation.coupons,
            reservationParams.reservation.store_coupon,
            cartItems
          )
          setDeliveryDistance(distance)
          setTaxiFare(calculatedTaxiFare)
          setTotalPrice(price)
          setCouponValues(coupon_values)
          setStoreCouponValue(store_coupon_value)
          setCampaignValue(campaign_value)
          setCampaignName(campaign_name)
        }
      }
    )
  }
  const isOutsideRange = day => {
    let response = post.event_flag ? !range.contains(day) : false
    if (post.newyear_filter && response === false) {
      response = newyearFilterRange.contains(day) ? true : response
    }

    let day_name = day.lang('en').format('ddd')
    if (day_name === 'Thu') {
      day_name = 'Thur'
    }

    if (post.business_days.indexOf(day_name) === -1) {
      response = true
    }
    return response
  }

  const timeOptionsFilter = post.event_flag
    ? (time: string, index: number, array: string[]) => {
        const startIndex = array.findIndex(s_time => s_time === startTime)
        const endIndex = array.findIndex(e_time => e_time === endTime)
        return index >= startIndex && index <= endIndex
      }
    : null

  React.useEffect(() => {
    if (!isCompleted) {
      return
    }

    if (window.dataLayer) {
      window.dataLayer.push('event', 'conversion', {
        send_to: 'AW-458460316/nJJ1CKKR9-0BEJyZztoB',
        value: [priceAndAmount.price],
        currency: 'JPY',
        transaction_id: [priceAndAmount.amount],
      })
    }

    if (window.fbq) {
      window.fbq('track', 'Subscribe', {
        value: [priceAndAmount.price],
        currency: 'JPY',
        predicted_ltv: [priceAndAmount.amount],
      })
    }
  }, [priceAndAmount, isCompleted])
  return (
    <ReservationNewWrapper>
      <div className="Panel">
        {isCompleted ? (
          <div className="ReservationNew_Completed">
            <h2 className="ReservationNew_CompletedHeader">
              {I18n.t('reservation.reservation_completed')}
            </h2>
            <div className="ReservationNew_CompletedButtons">
              <Button>
                <a href={`/reservations/${createdReservation.slug}`}>
                  {I18n.t('reservation.reservation_detail')}
                </a>
              </Button>
              <Button>
                <a href={`/posts/search`}>{I18n.t('reservation.back_to_posts')}</a>
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="ReservationNew_Post">
              <div className="ReservationNew_Image">
                {post.post_images && post.post_images.length > 0 ? (
                  <img src={post.post_images[0].image_url} alt="" />
                ) : (
                  <S.NoImage>No Image</S.NoImage>
                )}
              </div>
              <div className="ReservationNew_PostInfo">
                <h2 className="ReservationNew_PostTitle">{post.name}</h2>
              </div>
            </div>
            <div className="Panel_Section">
              <h3>{I18n.t('reservation.reservation_detail')}</h3>
              <table className="ReservationNew_Table">
                <thead>
                  <tr>
                    <th>商品</th>
                    <th>個数</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.amount}</td>
                      <td>{formatPrice(item.price * item.amount)}</td>
                    </tr>
                  ))}
                </tbody>
                <tbody>
                  {/* TODO */}
                  <tr>
                    <td />
                    <td>小計</td>
                    <td>{formatPrice(priceAndAmount.price)}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>配送料</td>
                    <td>
                      {isNaN(taxiFare) ? '配達先住所を入力してください' : formatPrice(taxiFare)}
                    </td>
                  </tr>
                  {campaignValue !== 0 && (
                    <tr>
                      <td />
                      <td>{campaignName}</td>
                      <td>
                        -{''}
                        {isNaN(campaignValue)
                          ? '配達先住所を入力してください'
                          : formatPrice(campaignValue)}
                      </td>
                    </tr>
                  )}
                  {storeCouponValue !== 0 && storeCouponValue !== -1 && (
                    <tr>
                      <td />
                      <td>店舗クーポン</td>
                      <td>
                        -{''}
                        {isNaN(storeCouponValue)
                          ? '配達先住所を入力してください'
                          : formatPrice(storeCouponValue)}
                      </td>
                    </tr>
                  )}
                  {storeCouponValue === -1 && (
                    <tr>
                      <td />
                      <td>店舗クーポン</td>
                      <td>このクーポンを使えません</td>
                    </tr>
                  )}
                  {couponValues.some(c => c !== 0) && couponValues.some(c => c !== -1) && (
                    <tr>
                      <td />
                      <td>共通クーポン</td>
                      <td>
                        -{''}
                        {couponValues.some(c => isNaN(c))
                          ? '配達先住所を入力してください'
                          : formatPrice(couponValues.reduce((a, x) => a + x, 0))}
                      </td>
                    </tr>
                  )}
                  {couponValues.some(couponValue => couponValue === -1) && (
                    <tr>
                      <td />
                      <td>共通クーポン</td>
                      <td>このクーポンを使えません</td>
                    </tr>
                  )}
                  <tr>
                    <td />
                    <td colSpan={2}>店舗と配送場所の距離により料金が変わります</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td />
                    <td>合計</td>
                    <td>{totalPrice >= 0 ? formatPrice(totalPrice) : '計算中です'}</td>
                  </tr>
                </tfoot>
              </table>
              {post.event_flag && (
                <div className="Panel_Section">
                  <InputDateTime
                    required={post.event_flag}
                    name="set_delivery_time"
                    label="配達日時"
                    defaultValue={getDefaultStart()}
                    timeOptionsFilter={timeOptionsFilter}
                    isOutsideRange={isOutsideRange}
                    onChangeHandler={data => setSelectedDeliveryTime(data)}
                  />
                  <p>※配達時間は10:00～16:00の間で選択して下さい。ご指定時間から1時間内が配達時間帯となります。</p>
                  <p>　当日の交通状況により配達が遅れてしまう場合もございますのでご了承ください。</p>
                  <p>　例) 16:00をご指定した場合、16:00 ～ 17:00 が配達時間帯となります。</p>
                </div>
              )}
              <div className="Panel_Section">
                <h4>{I18n.t('reservation.remarks_notes')}</h4>
                {post.note && <div className="Post_Note">{post.note}</div>}
                <div className="Panel_Section">
                  <p>
                    注文確定後すぐにお届け準備が開始されますので、商品が食品である事から、ご注文後の変更・キャンセルは原則お受けしておりません。
                    ※詳細は特定商取引に基づく表記をご確認ください。
                  </p>
                  {/* <p>
                    基本的にご注文後の変更・キャンセルはお承りしておりませんが、発送準備前でしたら、ご注文のキャンセル、内容の変更をお受けできる場合がございます。店舗の電話番号まで、お電話にてご連絡ください。
                  </p> */}
                </div>
                {/* {post.address && (
                  <p>
                    <i className="material-icons">phone</i> {post.address.phone}
                  </p>
                )} */}
              </div>
            </div>
            <div className="Panel_Section">
              <ReservationForm
                post={post}
                user={user}
                reservationParams={updatedReservationParams}
                setAddress={setAddress}
                setCreatedReservation={setCreatedReservation}
                setIsCompleted={setIsCompleted}
                setShowOverlay={setShowOverlay}
                updateTaxiFare={setTaxiFareFromDistance}
                cartItems={cartItems}
                taxiFare={taxiFare}
                couponValues={couponValues}
                storeCouponValue={storeCouponValue}
                campaignValue={campaignValue}
                campaignName={campaignName}
                totalPrice={totalPrice}
                priceAndAmount={priceAndAmount}
              />
            </div>
          </>
        )}
      </div>
      <S.Cover show={showOverlay}>
        {' '}
        <Spinner />{' '}
      </S.Cover>
    </ReservationNewWrapper>
  )
}

interface IReservationFormProps {
  post: IPost
  user: any
  reservationParams: IReservationParams
  cartItems: any
  taxiFare: any
  couponValues: number[]
  storeCouponValue: any
  campaignValue: any
  campaignName: any
  totalPrice: any
  priceAndAmount: any
  setAddress(address: any): void
  setIsCompleted(flag: boolean): void
  setShowOverlay(flag: boolean): void
  setCreatedReservation(reservation: any): void
  updateTaxiFare(latLng: google.maps.LatLng): void
}

const FORM_RESERVATION_FIELDS = {
  remarks: 'remarks',
}

const FIELDS = {
  ...FORM_RESERVATION_FIELDS,
  ...ADDRESS_FIELDS,
}

const ReservationForm: React.FC<IReservationFormProps> = ({
  post,
  user,
  reservationParams,
  setAddress,
  setIsCompleted,
  setShowOverlay,
  setCreatedReservation,
  updateTaxiFare,
  cartItems,
  taxiFare,
  couponValues,
  storeCouponValue,
  campaignValue,
  campaignName,
  totalPrice,
  priceAndAmount,
}) => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [getStripeParams, setStripeParamsGetter] = React.useState<any>(null)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const formRef = React.useRef(null)
  const getStripeParamsRef = React.useRef(null)

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled, values) => {
    if (values.latitude1 && values.longitude1) {
      const guestLatLng = new google.maps.LatLng(values.latitude1, values.longitude1)
      updateTaxiFare(guestLatLng)
    }
    setAddress(values)
    setErrors(updatedErrors)
    setIsSubmitEnabled(!isProcessing && updatedIsSubmitEnabled)
  }

  const resetProcess = () => {
    setShowOverlay(false)
    setIsProcessing(false)
  }

  React.useEffect(() => {
    getStripeParamsRef.current = getStripeParams
  }, [getStripeParams])

  const handleSubmit = React.useCallback(
    async (initialValues, values) => {
      if (!values.google_full_address || !values.latitude1 || !values.longitude1) {
        window.flashMessages.addMessage({
          text: '住所が誤っている可能性があります。再入力してください。',
          type: 'error',
        })

        return
      }

      let params = {
        ...reservationParams,
        reservation: {
          ...reservationParams.reservation,
        },
        address: {
          ...reservationParams.address,
        },
      }

      // 予約関連の値
      Object.keys(FORM_RESERVATION_FIELDS).forEach(key => {
        params.reservation[key] = values[key]
      })

      // 住所関連の値
      Object.keys(ADDRESS_FIELDS).forEach(key => {
        params.address[key] = emptyToNull(key, values[key])
      })

      setIsProcessing(true)
      setShowOverlay(true)
      if (typeof getStripeParamsRef.current === 'function') {
        const stripeParams = await getStripeParamsRef.current()
        if (stripeParams !== null) {
          params = { ...params, ...stripeParams }
        } else {
          window.flashMessages.addMessage({
            text:
              'クレジットカード情報が取得できませんでした。再入力するか時間を置いて再度お試しください。',
            type: 'error',
          })
          resetProcess()
          return
        }
      } else {
        window.flashMessages.addMessage({
          text: 'クレジットカード情報が取得できませんでした。時間を置いて再度お試しください。',
          type: 'error',
        })
        resetProcess()
        return
      }

      const createReservationResponse = await reservationService.createReservation(params)
      resetProcess()

      if (createReservationResponse.reservation) {
        setCreatedReservation(createReservationResponse.reservation)
        setIsCompleted(true)
      }
    },
    [getStripeParamsRef]
  )

  return (
    <S.ReservationForm>
      <h3>配達先指定</h3>
      <Form
        fields={FIELDS}
        handleSubmit={handleSubmit}
        handleUpdateForm={handleUpdateForm}
        ref={formRef}
      >
        <S.FormItem>
          <AddressForm
            defaultAddress={user.address}
            withMap={true}
            errors={errors}
            isGuest={true}
            updateForm={() => {
              formRef?.current.handleFormChange()
            }}
          />
        </S.FormItem>
        <S.FormItem>
          <InputTextArea
            required={false}
            name="remarks"
            defaultValue=""
            label={I18n.t('generic.note')}
            error={errors.remarks}
          />
        </S.FormItem>
        <S.FormComment>※さび抜きなど、ご注文に関する特記事項をご記入ください</S.FormComment>
        <StripeCardSelect setStripeParamsGetter={setStripeParamsGetter} />

        <div className="Panel_Section">
          <h3>{I18n.t('reservation.reservation_detail')}</h3>
          <table className="ReservationNew_Table">
            <thead>
              <tr>
                <th>商品</th>
                <th>個数</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.amount}</td>
                  <td>{formatPrice(item.price * item.amount)}</td>
                </tr>
              ))}
            </tbody>
            <tbody>
              {/* TODO */}
              <tr>
                <td />
                <td>小計</td>
                <td>{formatPrice(priceAndAmount.price)}</td>
              </tr>
              <tr>
                <td />
                <td>配送料</td>
                <td>{isNaN(taxiFare) ? '配達先住所を入力してください' : formatPrice(taxiFare)}</td>
              </tr>
              {campaignValue !== 0 && (
                <tr>
                  <td />
                  <td>{campaignName}</td>
                  <td>
                    -{''}
                    {isNaN(campaignValue)
                      ? '配達先住所を入力してください'
                      : formatPrice(campaignValue)}
                  </td>
                </tr>
              )}
              {storeCouponValue !== 0 && storeCouponValue !== -1 && (
                <tr>
                  <td />
                  <td>店舗クーポン</td>
                  <td>
                    -{''}
                    {isNaN(storeCouponValue)
                      ? '配達先住所を入力してください'
                      : formatPrice(storeCouponValue)}
                  </td>
                </tr>
              )}
              {storeCouponValue === -1 && (
                <tr>
                  <td />
                  <td>店舗クーポン</td>
                  <td>このクーポンを使えません</td>
                </tr>
              )}
              {couponValues.some(c => c !== 0) && couponValues.some(c => c !== -1) && (
                <tr>
                  <td />
                  <td>共通クーポン</td>
                  <td>
                    -{''}
                    {couponValues.some(c => isNaN(c))
                      ? '配達先住所を入力してください'
                      : formatPrice(couponValues.reduce((a, x) => a + x, 0))}
                  </td>
                </tr>
              )}
              {couponValues.some(c => c === -1) && (
                <tr>
                  <td />
                  <td>共通クーポン</td>
                  <td>このクーポンを使えません</td>
                </tr>
              )}
              <tr>
                <td />
                <td colSpan={2}>店舗と配送場所の距離により料金が変わります</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td>合計</td>
                <td>{totalPrice >= 0 ? formatPrice(totalPrice) : '計算中です'}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <Button disabled={!isSubmitEnabled || isProcessing} primary={true}>
          {I18n.t('generic.reservation')}
        </Button>
      </Form>
    </S.ReservationForm>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.ReservationForm = styled.div``
S.FormItem = styled.div<{ hidden?: true }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`

S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
const ReservationNewWrapper = styled.div`
  padding: 24px 12px;

  .Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    max-width: 880px;
    margin: 0 auto;
    padding: 24px;
  }

  .Panel_Section {
    margin-top: 24px;
    padding-top: 16px;
    border-top: solid 1px #eaedef;

    > h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .ReservationNew_CompletedHeader {
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
  }

  .ReservationNew_CompletedButtons {
    display: flex;
    justify-content: center;
  }

  .ReservationNew_Table {
    width: 100%;
    max-width: 600px;
    font-size: 15px;
    tr {
      height: 40px;
      td {
        min-width: 100px;
      }
    }
    tbody {
      border-bottom: 1px solid #eaedef;
    }
  }

  .ReservationNew_Post {
    display: flex;
    align-items: center;
  }

  .ReservationNew_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .ReservationNew_PostInfo {
    flex: 1;
    margin-left: 16px;
  }

  .ReservationNew_PostTitle {
    font-size: 20px;
  }

  .Button {
    margin-top: 24px;
  }

  .Post_Note {
    white-space: pre-wrap;
    overflow: auto;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      max-height: 10em;
    }
  }
`
S.Cover = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.4);

  .Spinner {
    z-index: 20000;
  }
`

export default ReservationNew
