import * as Color from 'color'
import ColorString from 'color-string'
import { createGlobalStyle } from 'styled-components'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from './constants'

const themeColorString = getComputedStyle(document.documentElement)
  .getPropertyValue(`${THEME_COLOR_VARIABLE_NAME}`)
  .trim()
const themeColor = !ColorString?.get?.(themeColorString)
  ? Color(COLORS.Primary)
  : Color(themeColorString)

const dateRangePickerCustomStyle = createGlobalStyle`
  .DateRangePicker.DateRangePicker_1 {
    width: 100%;
  }
  .DateRangePickerInput.DateRangePickerInput_1 {
    display: flex;
    align-items: center;
    width: 100%;
    border: solid 1px ${COLORS.Border};
  }
  .DateInput.DateInput_1 {
    width: 50%;
  }
  input#startDate,
  input#endDate {
    &.DateInput_input__focused {
      border-bottom: solid 1px ${themeColor.hex()};
    }
  }
  .CalendarDay__selected_span {
    background: ${themeColor.lighten(0.25).hex()};
    border: 1px double ${themeColor.lighten(0.15).hex()};
    color: #fff;
  }
  .CalendarDay__selected {
    background: ${themeColor.hex()};
    border: 1px double ${themeColor.hex()};
    color: white;
  }
  .CalendarDay__selected:hover {
    background: ${themeColor.hex()};
    color: white;
  }
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${themeColor.lighten(0.25).hex()};
    border: 1px double ${themeColor.lighten(0.15).hex()};
    color: #fff;
  }
`

export default dateRangePickerCustomStyle
