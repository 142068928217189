import * as React from 'react'
import SliderMenu, { IMenuPanel } from '../molecules/SliderMenu'

const MENU_PANELS: IMenuPanel[] = [
  {
    link: '/posts/search?category_ids[]=1',
    title: 'ファーストフード',
    image_path: '/images/top/img_american.jpg',
  },
  {
    link: '/posts/search?category_ids[]=2',
    title: 'デザート',
    image_path: '/images/top/img_desert.jpg',
  },
  {
    link: '/posts/search?category_ids[]=3',
    title: '中華料理',
    image_path: '/images/top/img_chinese.jpg',
  },
  {
    link: '/posts/search?category_ids[]=4',
    title: '韓国料理',
    image_path: '/images/top/img_korean.jpg',
  },
  {
    link: '/posts/search?category_ids[]=5',
    title: '和食',
    image_path: '/images/top/img_japanese.jpg',
  },
  {
    link: '/posts/search?category_ids[]=6',
    title: 'アジア料理',
    image_path: '/images/top/img_asian.jpg',
  },
  {
    link: '/posts/search?category_ids[]=7',
    title: '無国籍料理',
    image_path: '/images/top/img_international.jpg',
  },
  {
    link: '/posts/search?category_ids[]=8',
    title: 'イタリア料理',
    image_path: '/images/top/img_italian.jpg',
  },
]

const GenreSelect: React.FC<{}> = props => {
  return <SliderMenu title="カテゴリを選択" link={0} menuPanels={MENU_PANELS} />
}

export default GenreSelect
