import ClassNames from 'classnames'
import { isEqual, range } from 'lodash'
import * as moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import styled from 'styled-components'
import { IWindow } from '../../../core/interfaces'
import { postService } from '../../../core/services'
import * as constants from '../../../static/constants'
import CalendarDetail from './Detail'
import CalendarGrid from './Grid'
import CalendarYearMonthSelect from './YearMonthSelect'

declare var window: IWindow

interface IDay {
  date: moment.Moment
  blocked: boolean
  reserved: boolean
  price: number
  selected?: boolean
  start?: boolean
  end?: boolean
}
interface ICollection {
  available: boolean
  blocked: boolean
  created_at: string
  daily_price: null | number
  day: string
  id: number
  post_id: number
  reserved: boolean
  updated_at: string
}
interface ISelectedDate {
  start: null | moment.Moment
  end: null | moment.Moment
}
interface IState {
  yearMonth: moment.Moment
  selectedDate: ISelectedDate
  dragItem: null | moment.Moment
  data: IDay[]
  edit: {
    reserved: boolean
    price: string
    availablility: string
  }
  focusedInput: null | 'startDate' | 'endDate'
  anyTouched: boolean
  collections: ICollection[]
  fetching: boolean
}
interface IProps {
  post: any
}

function getJsonData(selectedYearMonth: moment.Moment, post): IDay[] {
  const year = selectedYearMonth.year()
  const month = selectedYearMonth.month()
  const daysInMonth = selectedYearMonth.daysInMonth()

  return range(daysInMonth).map((count: number) => ({
    date: moment([year, month, count + 1]),
    blocked: false,
    reserved: false,
    price: post.price_numeric || constants.DEFAULT_PRICE,
  }))
}

const getInitialState = post => ({
  yearMonth: moment([moment().year(), moment().month(), 1]),
  selectedDate: {
    start: null,
    end: null,
  },
  dragItem: null,
  data: getJsonData(moment(), post),
  edit: {
    reserved: false,
    price: String(constants.DEFAULT_PRICE),
    availablility: 'available',
  },
  focusedInput: null,
  anyTouched: false,
  collections: [],
  fetching: false,
  price: post.price_numeric,
})

class Calendar extends React.Component<IProps, IState> {
  public static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.post.price_numeric !== prevState.price) {
      const nextData = prevState.data.map(date => ({
        ...date,
        price: date.price === prevState.price ? nextProps.post.price_numeric : date.price,
      }))

      return {
        ...prevState,
        data: nextData,
        price: nextProps.post.price_numeric,
      }
    } else {
      return null
    }
  }

  constructor(props) {
    super(props)
    this.state = getInitialState(props.post)
  }

  public componentDidMount() {
    if (this.props.post.id === null) {
      return
    }
    this.getCalendarCollections(moment().format('YYYY-MM'))
  }

  public getCalendarCollections = async yearMonth => {
    this.setState({ fetching: true })
    const { calendarCollections } = await postService.getCalendarCollections(
      this.props.post.id,
      yearMonth
    )
    const nextData = [...this.state.data]

    calendarCollections.forEach(collection => {
      const date = moment(collection.day)

      nextData.some((data, index) => {
        if (data.date.isSame(date)) {
          nextData[index] = {
            date: data.date,
            blocked: collection.blocked,
            price: collection.daily_price || data.price,
            reserved: collection.reserved,
          }

          return true
        }
      })
    })

    this.setState({
      fetching: false,
      data: nextData,
      collections: calendarCollections,
    })
  }

  public changeFocusedInput = (focusedInput: any) => {
    this.setState({ focusedInput })
  }

  public changeYearMonth = (event: React.FormEvent<HTMLSelectElement | HTMLButtonElement>) => {
    const element = event.currentTarget as HTMLSelectElement | HTMLButtonElement
    const date = moment(element.value)

    this.clearSelectedDate()
    this.setState({
      yearMonth: date,
      data: getJsonData(date, this.props.post),
    })
    this.getCalendarCollections(date.format('YYYY-MM'))
  }

  // 選択日付をリセット
  public clearSelectedDate = () => {
    const nextData = this.state.data.map((day: IDay) => ({
      ...day,
      selected: false,
      start: false,
      end: false,
    }))

    this.setState({
      selectedDate: {
        start: null,
        end: null,
      },
      data: nextData,
    })
  }

  // 選択した日付の情報を更新
  public updateDateInfo = async () => {
    const { start, end } = this.state.selectedDate

    if (!moment.isMoment(start) || !moment.isMoment(end)) {
      return
    }

    const params = []
    const { price, availablility } = this.state.edit
    const selectedStartDate = start.format(constants.DATE_FORMAT)
    const selectedEndDate = end.format(constants.DATE_FORMAT)
    const blocked = availablility === 'available' ? false : true
    const data = this.state.data.map((day: IDay) => {
      const nextDay = {
        ...day,
        selected: false,
        start: false,
        end: false,
      }

      if (day.date.isSameOrAfter(selectedStartDate) && day.date.isSameOrBefore(selectedEndDate)) {
        const nextPrice = price === '' ? day.price : Number(price)
        const nextBlocked = availablility === '' ? day.blocked : blocked

        nextDay.price = nextPrice
        nextDay.blocked = nextBlocked

        params.push({
          blocked: nextBlocked,
          reserved: day.reserved,
          daily_price: nextPrice,
          day: day.date.format('YYYY/MM/DD'),
        })
      }

      return nextDay
    })

    this.setState({
      data,
      selectedDate: {
        start: null,
        end: null,
      },
      anyTouched: false,
    })

    const { flush } = await postService.createCollectionCalendar(this.props.post.id, params)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }

  // 日付選択範囲の更新
  public updateDateSelection = (selectedDate: ISelectedDate) => {
    const { start, end } = selectedDate

    if (!moment.isMoment(start) || !moment.isMoment(end)) {
      return
    }

    const nextData = this.state.data.map((day: IDay) => {
      const selectedStartDate = start.format(constants.DATE_FORMAT)
      const selectedEndDate = end.format(constants.DATE_FORMAT)

      if (day.date.isSameOrAfter(selectedStartDate) && day.date.isSameOrBefore(selectedEndDate)) {
        // 選択範囲内
        const formatDate = day.date.format(constants.DATE_FORMAT)
        const nextDay = {
          ...day,
          selected: true,
          start: formatDate === selectedStartDate,
          end: formatDate === selectedEndDate,
        }

        return nextDay
      } else {
        // 選択範囲外
        return {
          ...day,
          selected: false,
        }
      }
    })

    this.setState({
      data: nextData,
    })
  }

  // inputのvalueを更新
  public upDateEditValue = (event: React.FormEvent<HTMLInputElement>) => {
    const edit = { ...this.state.edit }
    const input = event.currentTarget as HTMLInputElement
    edit[input.name] = input.value

    this.setState({
      anyTouched: true,
      edit,
    })
  }

  public getCalendarClassNames = () => {
    const { start, end } = this.state.selectedDate

    return ClassNames({
      hasSelectedDate: start && end,
    })
  }

  public dragstart = (day: IDay, index: number) => {
    const { start, end } = this.state.selectedDate

    if (moment.isMoment(start) && moment.isMoment(end)) {
      if (day.date.isSameOrAfter(start) && day.date.isSameOrBefore(end)) {
        // すでに選択されている日付内をクリックした場合、startかend近い方をドラッグ可能に
        const distanceToStart = Math.abs(day.date.diff(start, 'days'))
        const distanceToEnd = Math.abs(day.date.diff(end, 'days'))
        const startIsClose = distanceToStart <= distanceToEnd
        const selectedDate = {
          start: startIsClose ? day.date : start,
          end: startIsClose ? end : day.date,
        }

        this.updateDateSelection(selectedDate)
        this.setState({
          dragItem: startIsClose ? end : start,
          selectedDate,
        })

        return
      } else {
        // すでに選択されている日付があり、範囲外をクリックした場合選択された日付をリセット
        this.clearSelectedDate()
      }
    } else {
      // 日付を選択
      const selectedDate = {
        start: day.date,
        end: day.date,
      }

      const nextData = [...this.state.data]
      nextData[index] = {
        ...nextData[index],
        selected: true,
        start: true,
        end: true,
      }

      this.setState({
        dragItem: day.date,
        selectedDate,
        edit: {
          reserved: day.reserved,
          price: String(day.price),
          availablility: day.blocked === true ? 'blocked' : 'available',
        },
        data: nextData,
      })
    }
  }

  public onDrag = (day: IDay) => {
    if (!this.state.dragItem) {
      return
    }
    const selectedDate = { ...this.state.selectedDate }

    if (day.date.isAfter(this.state.dragItem)) {
      selectedDate.start = this.state.dragItem
      selectedDate.end = day.date
    } else {
      selectedDate.start = day.date
      selectedDate.end = this.state.dragItem
    }

    const edit = { ...this.state.edit }
    const { start, end } = selectedDate
    const selectedData = this.state.data.filter(
      (item: IDay) => item.date.isSameOrAfter(start) && item.date.isSameOrBefore(end)
    )
    const nextData = this.state.data.map((item: IDay) => {
      if (item.date.isSameOrAfter(start) && item.date.isSameOrBefore(end)) {
        const nextDay = {
          ...item,
          selected: true,
          start: item.date.isSame(start),
          end: item.date.isSame(end),
        }

        return nextDay
      } else {
        return {
          ...item,
          selected: false,
        }
      }
    })

    // 複数選択されている日付の値段が共通の場合inputに値を表示
    if (selectedData.every((item: IDay) => item.price === selectedData[0].price)) {
      edit.price = String(selectedData[0].price)
    } else {
      edit.price = ''
    }

    // 複数選択されている日付の利用可否が共通の場合にradioを選択
    if (selectedData.every((item: IDay) => item.blocked === selectedData[0].blocked)) {
      edit.availablility = selectedData[0].blocked === true ? 'blocked' : 'available'
    } else {
      edit.availablility = ''
    }

    // 予約されている日付を含む場合は編集不可に
    if (selectedData.every((item: IDay) => item.reserved === false)) {
      edit.reserved = false
    } else {
      edit.reserved = true
    }

    this.setState({
      selectedDate,
      edit,
      data: nextData,
    })
  }

  public dragend = () => {
    this.setState({ dragItem: null })
  }

  // DateRangePicker変更時
  public onDateRangePickerChange(dates: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) {
    const selectedDate = {
      start: dates.startDate,
      end: dates.endDate,
    }

    this.setState({
      selectedDate,
    })

    this.updateDateSelection(selectedDate)
  }

  public render() {
    return (
      <Wrapper>
        <CalendarWrapper className={this.getCalendarClassNames()}>
          <CalendarYearMonthSelect
            changeYearMonth={this.changeYearMonth}
            yearMonth={this.state.yearMonth}
          />
          <CalendarGrid
            fetching={this.state.fetching}
            data={this.state.data}
            clearSelectedDate={this.clearSelectedDate}
            dragstart={this.dragstart}
            dragend={this.dragend}
            onDrag={this.onDrag}
          />
        </CalendarWrapper>
        <CalendarDetail
          selectedDate={this.state.selectedDate}
          updateDateInfo={this.updateDateInfo}
          focusedInput={this.state.focusedInput}
          onDateRangePickerChange={this.onDateRangePickerChange}
          changeFocusedInput={this.changeFocusedInput}
          upDateEditValue={this.upDateEditValue}
          clearSelectedDate={this.clearSelectedDate}
          edit={this.state.edit}
          anyTouched={this.state.anyTouched}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  color: #34495e;
  letter-spacing: 0.5px;
  -webkit-user-select: none;
`

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: scroll;
`

export default Calendar
