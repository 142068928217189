import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { settingsService } from '../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import { Panel, Toggle } from '../../atoms'
import SettingLayoutIndex from './_layouts'
import { ActiveTypes } from './Nav'

declare var window: IWindow

interface IProps {
  user: any
  active_path: ActiveTypes
}

interface IState {
  email_notification: boolean
  isSubmitEnabled: boolean
  errors: { [key: string]: null | string }
}

export default class Show extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      email_notification: props.email_notification,
      isSubmitEnabled: true,
      errors: {},
    }
  }

  public handleChangeCheck = async event => {
    const data: any = {}
    const updatedParams: any = {}
    updatedParams[event.target.name] = event.target.checked
    data.settings = updatedParams
    const { flush } = await settingsService.updateNotification(data)

    this.setState({ email_notification: !this.state.email_notification })
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }

  public render() {
    return (
      <SettingLayoutIndex
        activePath={this.props.active_path}
        main={
          <Panel title={I18n.t('settings.set_notification')}>
            <S.Field>
              <label>{I18n.t('settings.email_notification')}</label>
              <div>
                <Toggle
                  name="email_notification"
                  checked={this.state.email_notification}
                  onChangeHandler={this.handleChangeCheck}
                />
              </div>
            </S.Field>
          </Panel>
        }
      />
    )
  }
}
const S: { [key: string]: AnyStyledComponent } = {}
S.Field = styled.div`
  display: flex;

  & + & {
    margin-top: 24px;
    padding-top: 20px;
    border-top: solid 1px ${COLORS.Border};
  }

  > label {
    width: 200px;
    font-weight: bold;
  }

  > div {
    flex: 1;
  }

  a {
    color: var(${THEME_COLOR_VARIABLE_NAME});
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
  }
`
