import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../../../static/constants'
import { BREAKPOINT_TABLET_LARGE, HEADER_HEIGHT } from '../../../../static/constants'
import SettingsSidebar, { ActiveTypes } from '../Nav'

interface IProps {
  activePath: ActiveTypes
  main: React.ReactElement
}

const LayoutIndex: React.FC<IProps> = ({ activePath, main }) => (
  <S.LayoutIndex>
    <SettingsSidebar active={activePath} />
    <S.Main>{main}</S.Main>
  </S.LayoutIndex>
)

const S: { [key: string]: AnyStyledComponent } = {}

S.LayoutIndex = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`

S.Main = styled.section`
  flex: 1;
  padding: 16px;
  margin: 0 auto;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: calc(100vh - ${constants.HEADER_HEIGHT}px - 40px);
  }
`

export default LayoutIndex
