import { Button, Select } from 'components/atoms'
import { utilService } from 'core/services'
import { postService } from 'core/services'
import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import { formatPrice } from 'utils/number'
import { BREAKPOINT_TABLET_MOBILE, COLORS } from '../../../static/constants'

interface IProps {
  posts?: any
  payments: {
    payments: any
  }
}

export const formatSelectOptions = options => {
  if (options.length === 0) {
    return []
  }
  return [
    { value: '', label: '全て' },
    ...options.map((option: { id: number; name: string }) => ({
      value: option.id.toString(),
      label: option.name,
    })),
  ]
}

const getFormattedDate = (created_at: string) =>
  moment(created_at, 'YYYYMMDD HHmmss').format('YYYY/MM/DD')
const params = new URLSearchParams(location.search.substring(1))
const filterDateLabel = params.get('created_at')
const currentPostID = params.get('post_id') || ''

const PaymentPage: React.FC<IProps> = props => {
  const { data: payments } = utilService.getDataFromJson(props.payments.payments)

  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>支払い履歴</h2>
        <div className="buttons">
          <Button small={true}>
            <a href="./">設定ページへ戻る</a>
          </Button>
        </div>
      </div>
      <div className="Wrapper_Main">
        <p>※店舗承認後に反映されます</p>
        <table>
          <thead>
            <tr>
              <th>注文日</th>
              <th>支払い金額</th>
              <th>注文詳細</th>
            </tr>
          </thead>
          <tbody>
            {payments.map(payment => (
              <tr key={payment.id}>
                <td>
                  {moment(payment.reservation.created_at, 'YYYYMMDD HHmmss').format('YYYY/MM/DD')}
                </td>
                <td>¥{payment.reservation.total_price}</td>
                <td>
                  <a href={`/reservations/${payment.reservation.id}`}>詳細を見る</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 12px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Wrapper_Main {
    overflow: scroll;
  }

  .filter {
    padding: 8px 16px;
    max-width: 420px;
    display: flex;

    p {
      width: 140px;
    }
  }

  .buttons {
    padding: 8px;
    display: flex;
  }

  table {
    text-align: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }

    tr {
      th,
      td {
        padding: 4px 8px;
      }
    }
  }
`

export default PaymentPage
