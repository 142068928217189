import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  id?: string
  handleClick?: () => void
  primary?: boolean
  small?: boolean
  disabled?: boolean
  textColor?: string
  backgroundColor?: string
  hoverColor?: string
}

const Button: React.FC<IProps> = props => {
  return (
    <S.Button
      className="Button"
      type={props.primary ? 'submit' : 'button'}
      onClick={props.handleClick || undefined}
      disabled={props.disabled}
      {...props}
    >
      {props.children}
    </S.Button>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Button = styled.button<IProps>`
  height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  line-height: 1;
  font-size: 14px;

  &:hover {
    ${({ hoverColor }) =>
      hoverColor
        ? `
        background-color: ${hoverColor};
        border-color: ${hoverColor};
      `
        : 'opacity: .6;'}
  }

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    text-decoration: none;
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }

  ${({ small }) =>
    small &&
    `
    height: 30px;
    font-size: 14px;
    padding: 0px 12px;
  `}

  ${({ primary }) =>
    primary
      ? `
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: #fff;
    border: none;

    > a {
      color: #fff;
    }
  `
      : `
    background-color: #fff;
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #f3f3f3;
    color: #fff;
  `}

  ${({ textColor }) =>
    textColor &&
    `
    color: ${textColor};

    > a {
      color: ${textColor};
    }
  `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
    border-color: ${backgroundColor};
  `}

  & + & {
    margin-left: 8px;
  }
`

export default Button
