import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'
import Button from './Button'

interface IWindow {
  globalModal: GlobalModal
}
declare var window: IWindow

interface IState {
  title: string
  showModal: boolean
  body: React.ReactElement
  closeText?: string
  submitText: string
  handleSubmit: any
  submitDanger?: boolean
}

const initialState = {
  title: '',
  showModal: false,
  body: null,
  closeText: '',
  submitText: '',
  handleSubmit: () => null,
  submitDanger: false,
}

export default class GlobalModal extends React.PureComponent<{}, IState> {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
    window.globalModal = this
  }

  public showModal = config => {
    const { title, body, closeText, submitText, handleSubmit, submitDanger } = config
    this.setState({
      showModal: true,
      title,
      body,
      closeText,
      submitText,
      handleSubmit,
      submitDanger,
    })
  }

  public closeModal = () => {
    this.setState({ ...initialState })
  }

  public render() {
    return (
      <>
        {this.state.showModal && (
          <S.Modal className="Modal">
            <S.Inner>
              <S.Header>
                <p>{this.state.title}</p>
              </S.Header>
              <S.Body>{this.state.body}</S.Body>
              <S.Footer>
                {this.state.closeText && (
                  <Button handleClick={this.closeModal}>{this.state.closeText}</Button>
                )}
                {this.state.handleSubmit && (
                  <Button
                    textColor="#fff"
                    backgroundColor={
                      this.state.submitDanger ? COLORS.Danger : `var(${THEME_COLOR_VARIABLE_NAME})`
                    }
                    handleClick={this.state.handleSubmit}
                  >
                    {this.state.submitText}
                  </Button>
                )}
              </S.Footer>
              <S.Close onClick={() => this.setState({ showModal: false })}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </S.Close>
            </S.Inner>
          </S.Modal>
        )}
      </>
    )
  }
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.4s;
  transform: translate3d(0, 100%, 0);
  transform: none;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 6000;
`

S.Inner = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  max-height: 95%;
  margin: 0 16px;
  padding: 30px;
  overflow-x: hidden;
  border-radius: 2px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
`

S.Header = styled.div`
  > p {
    font-size: 18px;
    font-weight: bold;
  }
`

S.Body = styled.div`
  padding-top: 24px;
`

S.Footer = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
`

S.Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`
