import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost } from '../../../../core/interfaces'
import { BREAKPOINT_TABLET_SMALL } from '../../../../static/constants'
import { Button, InputTextArea } from '../../../atoms'
import { Form } from '../../../molecules'

const MEMO_TO_TAXI_FIELD = {
  memo_to_taxi: 'taxi_id',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  handleFormSubmit(initialValues: any, values: any): void
}

const Taxi: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})
  const handleUpdateForm = React.useCallback((updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }, [])

  return (
    <>
      <Form
        fields={MEMO_TO_TAXI_FIELD}
        handleUpdateForm={handleUpdateForm}
        handleSubmit={props.handleFormSubmit}
      >
        <ul>
          <InputTextArea
            name="memo_to_taxi"
            label="伝達事項"
            defaultValue={props.post.memo_to_taxi}
            error={errors.memo_to_taxi}
          />
          <S.FormComment>ピックアップ場所指定など</S.FormComment>
          <S.FormComment>*最大1000文字</S.FormComment>
        </ul>
        <S.Footer className="Editor_Footer">
          <Button primary={true} disabled={!isSubmitEnabled}>
            {I18n.t('generic.update')}
          </Button>
        </S.Footer>
      </Form>
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Footer = styled.div`
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-top: 20px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  text-align: right;
  color: #777;
`
export default Taxi
