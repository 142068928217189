import * as React from 'react'
import I18n from '../../../core/i18n'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { adminService } from '../../../core/services'
import { EditTable } from '../../organisms'
import AdminLayoutIndex from './_layouts/'

interface IProps {
  contacts: {
    contacts: IJsonResponse
  }
}

const COLUMNS = [
  {
    name: 'Id',
    field: 'id',
  },
  {
    name: 'Name',
    field: 'name',
  },
  {
    name: 'Email',
    field: 'email',
  },
  {
    name: 'Subject',
    field: 'subject',
  },
  {
    name: 'Body',
    field: 'body',
  },
  {
    name: 'Status',
    field: 'status',
  },
  {
    name: 'Note',
    field: 'note',
  },
  {
    name: 'User id',
    field: 'user_id',
  },
  {
    name: 'Created at',
    field: 'created_at',
  },
  {
    name: 'Updated at',
    field: 'updated_at',
  },
]

const Index: React.FC<IProps> = props => {
  const { data } = adminService.getDataFromJson(props.contacts.contacts)

  return (
    <AdminLayoutIndex
      model="contact"
      heading={I18n.t('activerecord.models.contact')}
      main={
        <EditTable
          editable={false}
          columns={COLUMNS}
          records={data}
          pagination={props.contacts.contacts.meta}
        />
      }
    />
  )
}

export default Index
