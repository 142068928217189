import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { postService } from '../../../../core/services'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  HEADER_HEIGHT,
  PAYMENT_REQUIRED,
} from '../../../../static/constants'
import { DateRangeFilter, PriceFilter, Toggle, TypeFilter } from '../../../atoms'
import Search from '../../../organisms/Header/Search'

interface IProps {
  categories: IJsonResponse
  tags: IJsonResponse
  showMap?: boolean
  handleOnFilterSubmit(params: any): void
  setShowMap?(showMap: boolean): void
}

const Header: React.FC<IProps> = props => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = React.useState(false)
  const { data: categories } = postService.getDataFromJson(props.categories)
  const { data: tags } = postService.getDataFromJson(props.tags)

  return (
    <S.Header>
      <S.Fixed isFilterPanelOpen={isFilterPanelOpen}>
        <Search />
        {/* <S.Filters>
          <DateRangeFilter
            name={I18n.t('date')}
            field="date"
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            handleOnSubmit={props.handleOnFilterSubmit}
          />
          {PAYMENT_REQUIRED && (
            <PriceFilter
              name={I18n.t('generic.price')}
              field="price"
              setIsFilterPanelOpen={setIsFilterPanelOpen}
              handleOnSubmit={props.handleOnFilterSubmit}
            />
          )}
          <TypeFilter
            name={I18n.t('generic.category')}
            field="category_ids"
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            handleOnSubmit={props.handleOnFilterSubmit}
            types={categories}
          />
          <TypeFilter
            name={I18n.t('generic.tag')}
            field="tag_ids"
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            handleOnSubmit={props.handleOnFilterSubmit}
            types={tags}
          />
        </S.Filters> */}
        {typeof props.setShowMap === 'function' && (
          <S.MapToggle>
            <span>地図を表示</span>
            <Toggle
              name="toggle_map"
              checked={props.showMap}
              onChangeHandler={() => {
                props.setShowMap(!props.showMap)
              }}
            />
          </S.MapToggle>
        )}
      </S.Fixed>
      <S.Space />
    </S.Header>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Header = styled.div``

S.Fixed = styled.div<{ isFilterPanelOpen: boolean }>`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 10px 24px;
  border-bottom: solid 1px ${COLORS.Border};
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: auto;
    padding: 6px 12px;
  }

  ${({ isFilterPanelOpen }) =>
    isFilterPanelOpen &&
    `
    overflow: visible;
  `}
`

S.Space = styled.div`
  height: 56px;
`

S.MapToggle = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    display: none;
  }

  > span {
    margin-right: 12px;
    font-size: 14px;
  }
`

S.Filters = styled.div`
  margin: 0 -6px;
  white-space: nowrap;

  > div {
    display: inline-block;
  }
`

export default Header
