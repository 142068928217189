import * as React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../static/constants'

// interface IProps {}

const SamplePage: React.FC = props => {
  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>サンプルページ</h2>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;

  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Wrapper_Main {
    overflow: scroll;
  }
`

export default SamplePage
