import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { BREAKPOINT_TABLET_MOBILE } from '../../static/constants'

interface IPanelProps {
  title?: string | React.ReactElement
  fit?: boolean
}

const Panel: React.FC<IPanelProps> = ({ title, fit = false, children }) => {
  return (
    <S.Panel className="Panel" fit={fit}>
      {title && <S.Title>{title}</S.Title>}
      <S.Body>{children}</S.Body>
    </S.Panel>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Panel = styled.div<{ fit: boolean }>`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: ${({ fit }) => (fit ? '100%' : '880px')};
  margin: 0 auto;
`

S.Title = styled.h1`
  font-size: 18px;
  border-bottom: solid 1px #eaedef;
  padding: 16px 20px 8px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding: 12px 8px;
  }
`

S.Body = styled.div`
  padding: 20px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding: 12px 8px;
  }
`

export default Panel
