import * as React from 'react'
import Slider from 'react-slick'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../static/constants'
import { BREAKPOINT_TABLET_LARGE, BREAKPOINT_TABLET_MOBILE } from '../../static/constants'
import SliderMenuPanel from '../atoms/SliderMenuPanel'

export interface IMenuPanel {
  title: string
  link: string
  image_path?: string
}
interface IPanelMenuProps {
  title: string
  link: number
  menuPanels?: IMenuPanel[]
  children?: React.ReactNode[]
}

const NextArrow = props => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <div className="arrow-inner">
        <i className="material-icons">navigate_next</i>
      </div>
    </div>
  )
}
const PrevArrow = props => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <div className="arrow-inner">
        <i className="material-icons">navigate_before</i>
      </div>
    </div>
  )
}
const SliderMenu: React.FC<IPanelMenuProps> = props => {
  const [clickable, setClickable] = React.useState<boolean>(true)
  const showTargetArray = props.menuPanels || props.children
  const arrayLength = showTargetArray?.length

  const option = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: arrayLength > 2 ? 5 : arrayLength,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    initialSlide: arrayLength > 1 ? 1 : 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: constants.BREAKPOINT_TABLET_LARGE,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: constants.BREAKPOINT_TABLET_MOBILE,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
        },
      },
    ],
    afterChange: () => setClickable(true),
    beforeChange: () => setClickable(false),
  }
  return (
    <PanelMenuWrapper>
      <h1 className="heading">{props.title}</h1>
      {props.link > 0 && (<a className="heading_link" href={`/posts/search?category_ids[]=${props.link}`}> (一覧)</a>)}
      <Slider {...option}>
        {props.children && props.children}
        {!props.children &&
          props.menuPanels &&
          props.menuPanels.map((panelData: IMenuPanel, index: number) => {
            return (
              <div key={index}>
                <SliderMenuPanel
                  link={panelData.link}
                  title={panelData.title}
                  image_path={panelData.image_path}
                  clickable={clickable}
                />
              </div>
            )
          })}
      </Slider>
    </PanelMenuWrapper>
  )
}

const PanelMenuWrapper = styled.div`
  display: block;
  position: relative;
  padding: 8px 16px;
  max-width: 1980px;
  overflow: hidden;

  .heading {
    font-size: 24px;
    display: inline;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 16px;
    }
  }

  .heading_link {
    font-size: 20px;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 14px;
    }
  }

  /* Slider */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-arrow {
    cursor: pointer;
    position: absolute;
    background-color: #eee;
    top: 0px;
    bottom: 0px;
    width: 35px;
    height: 35px;
    z-index: 15;
    transition: opacity 0.1s;
    opacity: 0.7;
    margin: auto;
    border-radius: 50%

    &:hover {
      opacity: 0.9;
    }
    .arrow-inner {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  .slick-next {
    right: 5px;
  }
  .slick-prev {
    left: 5px;
  }
  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
    max-height: 240px;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    white-space: nowrap;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    padding: 0 4px;
    height: 100%;
    min-height: 1px;
    line-height: 0;
    @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      padding: 0 3px;
    }
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`

export default SliderMenu
