import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { CheckBox, InputText, Select, Spacer } from '../../../atoms'
import { InputDateTime } from '../../../molecules'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  coupon: IJsonResponse
}

const FIELDS = {
  id: 'id',
  store_limited_flag: 'store_limited_flag',
  once_a_day_flag: 'once_a_day_flag',
  target_post_id: 'target_post_id',
  code: 'code',
  value: 'value',
  start_at: 'start_at',
  end_at: 'end_at',
  remaining_quantity: 'remaining_quantity',
  quantity: 'quantity',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('coupon', { scope: 'activerecord.models' }),
})

const AdminCouponEdit: React.FC<IProps> = props => {
  const { data: coupon } = adminService.getDataFromJson(props.coupon)
  const [posts, setPosts] = React.useState([])
  const [storeLimitedChecked, setStoreLimitedChecked] = React.useState(coupon.store_limited_flag)
  const [onceADayChecked, setOnceADayChecked] = React.useState(coupon.once_a_day_flag)

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { updatedCoupon, flush } = await adminService.updateCoupon(values)
    location.href = '/admin/coupons'
  }, [])

  const formatSelectOptions = options => {
    return options.map((option: { id: number; name: string }) => ({
      value: option.id,
      label: option.name,
    }))
  }

  const getPosts = async () => {
    const { postList } = await adminService.getPosts()
    setPosts([{ value: '', label: '-' }, ...formatSelectOptions(postList)])
  }
  React.useEffect(() => {
    getPosts()
  }, [])

  return (
    <AdminLayoutEdit
      model="coupon"
      title={title}
      fields={FIELDS}
      indexLink="/admin/coupons"
      formItems={
        <>
          <input name="id" defaultValue={coupon.id} type="hidden" />
          <CheckBox
            readonly={true}
            name="store_limited_flag"
            defaultChecked={coupon.store_limited_flag}
            onChangeHandler={e => setStoreLimitedChecked(e.target.checked)}
            label={I18n.t('generic.store_limited_flag')}
          />
          <Spacer />
          {storeLimitedChecked && posts.length > 0 ? (
            <>
              <InputText
                readonly={true}
                required={true}
                name="target_post_name"
                defaultValue={coupon.target_post_name}
                label={I18n.t('generic.target_post_id')}
              />
              <input name="target_post_id" type="hidden" value={coupon.target_post_id} />
              <Spacer />
            </>
          ) : (
            <input name="target_post_id" type="hidden" />
          )}
          <CheckBox
            readonly={true}
            name="once_a_day_flag"
            defaultChecked={coupon.once_a_day_flag}
            onChangeHandler={e => setOnceADayChecked(e.target.checked)}
            label={I18n.t('generic.once_a_day_flag')}
          />
          <Spacer />
          <InputText
            readonly={true}
            required={true}
            name="code"
            defaultValue={coupon.code}
            label={I18n.t('generic.code')}
          />
          <Spacer />
          <InputText
            required={true}
            name="value"
            defaultValue={coupon.value}
            label={I18n.t('generic.value')}
          />
          <Spacer />
          <InputDateTime
            required={true}
            name="start_at"
            defaultValue={coupon.start_at}
            label={I18n.t('generic.start_at')}
          />
          <Spacer />
          <InputDateTime
            required={true}
            name="end_at"
            defaultValue={coupon.end_at}
            label={I18n.t('generic.end_at')}
          />
          <Spacer />
          <InputText
            readonly={true}
            required={false}
            name="remaining_quantity"
            defaultValue={coupon.remaining_quantity}
            label={I18n.t('generic.remaining_quantity')}
          />
          <Spacer />
          <InputText
            required={false}
            name="quantity"
            defaultValue={''}
            label={I18n.t('generic.add_quantity')}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminCouponEdit
