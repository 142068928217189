import { clamp } from 'lodash'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'

interface IProps {
  initValue?: number
  min: number
  max: number
  handleChange: (count: number) => void
}

const Counter: React.FC<IProps> = ({ initValue = 0, min, max, handleChange }) => {
  const [count, setCount] = React.useState<number>(initValue)
  const addCount = () => {
    setCount(old => clamp(old + 1, min, max))
  }

  const minusCount = () => {
    setCount(old => clamp(old - 1, min, max))
  }

  React.useEffect(() => handleChange(count), [count])

  return (
    <S.Count>
      <S.IconButton className={count <= min && 'disabled'} onClick={minusCount}>
        <i className="large material-icons">remove_circle_outline</i>
      </S.IconButton>
      <span>{count}</span>
      <S.IconButton className={count >= max && 'disabled'} onClick={addCount}>
        <i className="large material-icons">add_circle_outline</i>
      </S.IconButton>
    </S.Count>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Count = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 16px 0;

  > span {
    margin: 0 24px;
  }
`

S.IconButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #56a78a;

  > i {
    font-size: 24px;
  }

  &.disabled {
    pointer-events: none;
    color: #888888;
  }
`
export default Counter
