import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS } from '../../static/constants'
import FormItem from './_layouts/FormItem'

interface IProps {
  name: string
  options: any[]
  defaultValue: string
  label?: null | string
  error?: null | string
  required?: boolean
  selectRef?: any
  onChangeHandler?(event): any
  onBlurHandler?(event): any
}

const Select: React.FC<IProps> = ({
  name,
  options,
  defaultValue,
  label,
  error,
  required,
  selectRef,
  onChangeHandler,
  onBlurHandler,
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <S.Select
        id={name}
        name={name}
        required={required ? true : false}
        defaultValue={defaultValue}
        ref={selectRef || null}
        onChange={event => {
          if (typeof onChangeHandler === 'function') {
            onChangeHandler(event)
          }
        }}
        onBlur={event => {
          if (typeof onBlurHandler === 'function') {
            onBlurHandler(event)
          }
        }}
      >
        {/* <option value="">-</option> */}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </S.Select>
    </FormItem>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Select = styled.select`
  appearance: none;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  outline: none;

  width: 100%;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  padding: 8px 12px;
`

export default Select
