import IReservationErrorItem from 'core/interfaces/IReservationErrorItem'
import { clamp } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import {
  ICartItem,
  IJsonResponse,
  IPost,
  IPostItem,
  IReservation,
  IUser,
  IWindow,
} from '../../../../core/interfaces'
import {
  postService,
  reservationService,
  userService,
  utilService,
} from '../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../static/constants'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { formatPrice } from '../../../../utils/number'
import { Button, Counter } from '../../../atoms'
import ReservationNew from '../../reservations/new'
import Gallery from './Gallery'
// import RelatedPosts from './RelatedPosts'
import SideBar from './SideBar'

declare var window: IWindow

const DEFAULT_PAGE = 'show'

interface IProps {
  editable: boolean
  isOwner: boolean
  isSignedIn: boolean
  post_owner: IUser
  post: IJsonResponse
  related_posts: IPost[]
  user: IUser
  identification_required: boolean
  profile_required: boolean
}

const DAYS_OF_WEEK = {
  Mon: '月',
  Tue: '火',
  Wed: '水',
  Thur: '木',
  Fri: '金',
  Sat: '土',
  Sun: '日',
}

export interface IReservationParams {
  reservation: {
    cooking_time: string
    require_delivery: boolean
    specified_delivery_time_at: string
    distance: number
    post_id: number
    remarks: string
    reservation_item_attributes: Array<{ post_item_id: number; quantity: number }>
    coupons: string[]
    store_coupon: string
  }
  address: {
    addressable: null | boolean
    city1: string
    state1: string
    other1_1: string
    other1_2: string
    postal_code1: string
    addressable_type: string
    phone: string
  }
  controller: 'api/reservations'
  action: 'create'
}

function getReservationParams(
  cartItems: ICartItem[],
  post: IPost,
  user: any,
  couponCodes: string[],
  storeCouponCode: string
): IReservationParams {
  return {
    reservation: {
      cooking_time: '',
      require_delivery: true,
      specified_delivery_time_at: '',
      distance: 0,
      coupons: couponCodes,
      store_coupon: storeCouponCode,
      post_id: post.id,
      remarks: '',
      reservation_item_attributes: cartItems.map(({ amount, id }) => ({
        post_item_id: id,
        quantity: amount,
      })),
    },
    address: {
      addressable: null,
      addressable_type: 'Reservation',
      city1: user.address?.city1 || '',
      state1: user.address?.state1 || '',
      other1_1: user.address?.other1_1 || '',
      other1_2: user.address?.other1_2 || '',
      postal_code1: user.address?.postal_code1 || '',
      phone: user.address?.phone || '',
    },
    controller: 'api/reservations',
    action: 'create',
  }
}

export const PostShow: React.FC<IProps> = props => {
  const { data: post } = utilService.getDataFromJson(props.post)
  const [reservationParams, setReservationParams] = React.useState(null)
  const [page, setPage] = React.useState(DEFAULT_PAGE)
  const [currentUser, setCurrentUser] = React.useState<IUser | null>(null)
  React.useEffect(() => {
    userService.getUserMe().then(({ data }) => {
      if (data && data.user) {
        const { data: user } = utilService.getDataFromJson(data.user)
        setCurrentUser(user)
      }
    })
  }, [])
  const changePage = changedPage => {
    setPage(changedPage)
    history.pushState(null, null, `?active=${changedPage}`)
  }
  const changePageFromParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const active = urlParams.get('active')

    if (active) {
      setPage(active)
    } else {
      setPage(DEFAULT_PAGE)
    }
  }

  React.useEffect(() => {
    changePageFromParam()
    window.onpopstate = () => {
      changePageFromParam()
    }
  }, [])

  // Cart Items
  // const storage = localStorage
  // const cartItemsFromStorage = JSON.parse(storage.getItem('currentCartItems'))
  // const currentCart =
  //   cartItemsFromStorage && props.isSignedIn ? cartItemsFromStorage[props.post.id] : null
  // const currentCartItems = currentCart || []
  const [cartItems, setCartItems] = React.useState<ICartItem[]>([])

  if (reservationParams === null || page === 'show') {
    const PostDetailWithGoogleMap = injectGoogleMaps(PostDetail)
    return (
      <PostDetailWithGoogleMap
        {...props}
        post={post}
        page={page}
        user={currentUser}
        changePage={changePage}
        setReservationParams={setReservationParams}
        cartItems={cartItems}
        setCartItems={setCartItems}
      />
    )
  } else {
    return (
      <ReservationNew
        post={post}
        cartItems={cartItems}
        reservationParams={reservationParams}
        user={currentUser}
      />
    )
  }
}

interface IPostDetailProps {
  editable: boolean
  isOwner: boolean
  isSignedIn: boolean
  user_already_used_coupon: boolean
  post_owner: IUser
  related_posts: IPost[]
  user: IUser
  identification_required: boolean
  profile_required: boolean
  page: string
  post: IPost
  cartItems: ICartItem[]
  setCartItems: React.Dispatch<React.SetStateAction<ICartItem[]>>
  changePage(page: string): void
  setReservationParams(reservation: any): void
}

export const PostDetail: React.FC<IPostDetailProps> = ({ cartItems, setCartItems, ...props }) => {
  // const [deliveryTime, setDeliveryTime] = React.useState<string | null>(null)
  const [localCartItems, setLocalCartItems] = React.useState<ICartItem[]>(cartItems)
  const [deliveryDistance, setDeliveryDistance] = React.useState<number>(0)
  const [couponCodes, setCouponCodes] = React.useState<string[]>([])
  const [storeCouponCode, setStoreCouponCode] = React.useState<string>('')
  const [couponValues, setCouponValues] = React.useState<number[]>([])
  const [storeCouponValue, setStoreCouponValue] = React.useState<any>(0)
  const [totalPrice, setTotalPriceWithDeliveryFee] = React.useState<number | null>(null)
  const [reservationErrors, setShowReservationError] = React.useState<IReservationErrorItem[]>(null)
  const [userLiked, setUserLiked] = React.useState(props.post.user_liked)
  const [showReservationPanel, setShowReservationPanel] = React.useState<boolean>(false)
  const [ordable, setOrdable] = React.useState<boolean>(false)
  const [available, setAvailable] = React.useState<boolean>(false)
  const scrollContainerRef = React.useRef(null)

  const canOrderState = props.post.is_open && props.post.aasm_state === 'published'

  const TIME_FORMAT = new RegExp('^([01]?[0-9]|2[0-4]):([0-5][0-9])$')

  const getBusinessDays = business_days => {
    let days = ''
    business_days.forEach((day, index) => {
      if (index === 0) {
        days = DAYS_OF_WEEK[day]
      } else {
        days = days + '、' + DAYS_OF_WEEK[day]
      }
    })
    return days + '。'
  }

  const createReservation = () => {
    if (props.editable) {
      window.flashMessages.addMessage({
        text: I18n.t('post.cannot_reserve_own_post'),
        type: 'error',
      })
      return
    }

    const reservationParams = getReservationParams(
      localCartItems,
      props.post,
      props.user,
      couponCodes,
      storeCouponCode
    )
    setCartItems(localCartItems)
    props.setReservationParams(reservationParams)
    props.changePage('reservation')
    document.scrollingElement.scrollTo(0, 0)
  }

  React.useEffect(() => {
    if (google && props.post.address) {
      initializeMap()
    }
  }, [google, props.page])

  React.useEffect(() => {
    if (localCartItems.length === 0) {
      setShowReservationPanel(false)
    }
  }, [localCartItems])

  React.useEffect(() => {
    setTotalPrice(deliveryDistance, couponCodes, storeCouponCode, localCartItems)
  }, [deliveryDistance, couponCodes, storeCouponCode, localCartItems])

  React.useEffect(() => {
    setOrdable(localCartItems.length > 0 && totalPrice >= props.post.display_min_price)
  }, [totalPrice])

  React.useEffect(() => {
    setAvailable(reservationErrors == null)
  }, [reservationErrors])

  const setCouponValuesDisplay = couponVals => {
    setCouponValues(couponVals)
  }

  const setStoreCouponValueDisplay = storeCouponVal => {
    setStoreCouponValue(storeCouponVal)
  }

  const setTotalPrice = async (
    distance: number,
    coupon_codes: string[],
    store_coupon_code: string,
    items: ICartItem[]
  ) => {
    if (items.length > 0) {
      const {
        response,
        coupon_values,
        store_coupon_value,
      } = await reservationService.calcRetailPriceTotal(
        distance,
        coupon_codes,
        store_coupon_code,
        items
      )
      if (response instanceof Array) {
        setShowReservationError(response)
      } else {
        setTotalPriceWithDeliveryFee(response)
        setShowReservationError(null)
      }
      setCouponValuesDisplay(coupon_values)
      setStoreCouponValueDisplay(store_coupon_value)
    }
  }

  const initializeMap = () => {
    const { latitude1: postLatitude, longitude1: postLongitude } = props.post.address
    if (!postLatitude || !postLongitude) {
      return
    }
    const postLatLng = new google.maps.LatLng(postLatitude, postLongitude)

    const Options = {
      zoom: 15,
      center: postLatLng,
      mapTypeId: 'roadmap',
      scrollwheel: false,
      draggable: false,
      clickableIcons: false,
    }
    const initializedMap = new google.maps.Map(document.getElementById('map'), Options)
    if (props.user && props.user.address) {
      const { latitude1: gusetLatitude, longitude1: gusetLongitude } = props.user.address
      if (gusetLatitude && gusetLongitude) {
        const userLatLng = new google.maps.LatLng(gusetLatitude, gusetLongitude)
        setDuration(postLatLng, userLatLng)
      }
    }
    new google.maps.Marker({
      position: postLatLng,
      title: props.post.name,
      map: initializedMap,
    })
  }

  const setDuration = (post, guest) => {
    const distanceMatrixService = new google.maps.DistanceMatrixService()

    distanceMatrixService.getDistanceMatrix(
      {
        origins: [post],
        destinations: [guest],
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: google.maps.TrafficModel.BEST_GUESS,
        },
      },
      (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const results = response.rows[0].elements
          const distance = results[0].distance.value
          // const duration = results[0].duration.text

          setDeliveryDistance(distance)
          // setDeliveryTime(duration)
        }
      }
    )
  }

  const toggleLike = async () => {
    if (!props.isSignedIn) {
      location.href = '/users/sign_in'
      return
    }
    const { like } = await postService.toggleLike(props.post)
    setUserLiked(like.isUserLiked)
  }

  const getCartItem = (item, amount) => {
    return {
      id: item.id,
      name: item.name,
      amount,
      price: item.retail_price,
      post_id: item.post_id,
    }
  }

  const updateCartItems = (newItem, amount, found) => {
    setLocalCartItems(old => {
      const newItems = old
        .map(alreadyItem => {
          if (alreadyItem.id === newItem.id) {
            alreadyItem.amount = clamp(amount, 0, 10)
          }
          return alreadyItem
        })
        .filter(item => item.amount > 0)

      if (found === undefined) {
        newItems.push(getCartItem(newItem, amount))
      }

      // currentCartItems[props.post.id] = newItems
      // storage.setItem('currentCartItems', JSON.stringify(currentCartItems))
      return newItems
    })
  }

  const showCartDetailModal = newItem => {
    const found = localCartItems.find(item => item.id === newItem.id)
    const ModalBody: React.FC<{}> = modalBodyProps => {
      const MIN = 1
      const MAX = 10
      const [itemAmount, setItemAmount] = React.useState(found ? found.amount : 1)
      const addItemAmount = val => {
        setItemAmount(val)
      }
      const addItem = () => {
        const currentAmount = found ? found.amount : 0
        updateCartItems(newItem, currentAmount + itemAmount, found)
        window.globalModal.closeModal()
      }

      return (
        <S.CartModal>
          {newItem.image_url === '/images/no-avatar.svg' ? (
            <S.ItemNoImageWrap>
              <S.NoImage>No Image</S.NoImage>
            </S.ItemNoImageWrap>
          ) : (
            <S.ModalItemImage>
              <img src={newItem.image_url} />
            </S.ModalItemImage>
          )}
          <S.CartItemName>{newItem.name}</S.CartItemName>
          <S.Price>{formatPrice(newItem.retail_price)} </S.Price>

          <S.ModalItemDescription>{newItem.description}</S.ModalItemDescription>
          <S.AddCartWrapper>
            <Counter initValue={1} min={MIN} max={MAX} handleChange={addItemAmount} />
            <S.ButtonWrapper>
              <Button handleClick={window.globalModal.closeModal}>キャンセル</Button>
              <Button primary={true} handleClick={addItem} backgroundColor={COLORS.Primary}>
                {formatPrice(itemAmount * newItem.retail_price)} カートに追加
              </Button>
            </S.ButtonWrapper>
          </S.AddCartWrapper>
        </S.CartModal>
      )
    }
    window.globalModal.showModal({
      title: null,
      body: <ModalBody />,
      closeText: null,
      submitText: null,
      handleSubmit: null,
    })
  }

  const handleSetCartItem = item => {
    if (isNotPublishedPostItem(item)) {
      return
    }

    showCartDetailModal(item)
  }

  const isNotPublishedPostItem = (item: IPostItem) => {
    return item.aasm_state !== 'published'
  }

  const isOutOfStock = (item: IPostItem) => {
    return item.is_quantity_limited === true
      ? item.quantity - item.total_ordered_count <= 0
        ? true
        : false
      : false
  }

  const renderPostImages = () => {
    if (!props.post.post_images.length) {
      return (
        <S.Image>
          <S.NoImage>No Image</S.NoImage>
        </S.Image>
      )
    }

    return (
      <S.Images>
        {props.post.post_images.length === 1 ? (
          <S.Image>
            <img src={props.post.post_images[0].image_url} alt="" />
          </S.Image>
        ) : (
          <Gallery postImages={props.post.post_images} />
        )}
        {/* <S.MobileToggleLike onClick={toggleLike}>
          {userLiked ? (
            <i className="material-icons liked">favorite</i>
          ) : (
            <i className="material-icons">favorite_border</i>
          )}
        </S.MobileToggleLike> */}
      </S.Images>
    )
  }
  const previewTotalPrice = () => {
    if (localCartItems.length === 0) {
      return 'カートが空です'
    }

    return totalPrice ? formatPrice(totalPrice) : '計算中'
  }
  const checkTimeValue = (start, end) => {
    return start && end && TIME_FORMAT.test(start) && TIME_FORMAT.test(end)
  }

  const getFullAddress = () => {
    if (props.post.address) {
      // let address = '住所: '

      // const keys = ['state1', 'city1', 'other1_1', 'other1_2']

      // keys.forEach(key => {
      //   const value: string = props.post.address[key]
      //   if (value) {
      //     address += ` ${value}`
      //   }
      // })

      return `住所: ${props.post.address.google_full_address || ''}`
    }
    return ''
  }

  return (
    <S.PostShow ref={scrollContainerRef}>
      {props.editable && (
        <S.Owner>
          <p>{I18n.t('post.it_is_your_post')}</p>
          <a href={`/posts/${props.post.slug}/edit`}>{I18n.t('generic.edit')}</a>
        </S.Owner>
      )}
      <S.Main>
        <S.Content>
          {renderPostImages()}
          <S.Header>
            <S.HeaderAside>
              <S.Title>{props.post.name}</S.Title>
              <S.HeaderInfo>
                {!canOrderState && (
                  <S.NotOpen>
                    <i className="material-icons">error</i>
                    <b>
                      {props.post.aasm_state !== 'published' ? '注文受付停止中' : '受付時間外'}
                      です。営業日：{getBusinessDays(props.post.business_days)}
                    </b>
                  </S.NotOpen>
                )}
                <S.Description>{props.post.description}</S.Description>
                <S.Place>
                  {props.post.state && props.post.city && (
                    <span>
                      <i className="material-icons">room</i>
                      {props.post.state}, {props.post.city}
                    </span>
                  )}
                </S.Place>

                <S.Times>
                  {/* {props.post.address?.latitude1 && props.post.address?.longitude1 && deliveryTime && (
                    <S.DeliveryTime>
                      <i className="material-icons">access_time</i>
                      配達目安時間: <b>約{deliveryTime}</b>
                    </S.DeliveryTime>
                  )} */}

                  {checkTimeValue(props.post.start_time1, props.post.end_time1) && (
                    <S.OpenTime>
                      <i className="material-icons">store_mall_directory</i>
                      トドクシー受付時間：
                      <b>
                        {props.post.start_time1} - {props.post.end_time1}
                      </b>
                    </S.OpenTime>
                  )}
                  {checkTimeValue(props.post.start_time2, props.post.end_time2) && (
                    <S.OpenTime>
                      <i className="material-icons">navigate_next</i>
                      <b>
                        {props.post.start_time2} - {props.post.end_time2}
                      </b>
                    </S.OpenTime>
                  )}
                  {checkTimeValue(props.post.start_time3, props.post.end_time3) && (
                    <S.OpenTime>
                      <i className="material-icons">navigate_next</i>
                      <b>
                        {props.post.start_time3} - {props.post.end_time3}
                      </b>
                    </S.OpenTime>
                  )}
                </S.Times>
                {props.post.event_flag && (
                  <S.Times>
                    <S.OpenTime>
                      <i className="material-icons">navigate_next</i>
                      <p>
                        配達日:
                        <b>
                          {' '}
                          {moment(props.post.delivery_start_time).format('YYYY年MM月DD日')} ~{' '}
                          {moment(props.post.delivery_end_time).format('YYYY年MM月DD日')}
                        </b>
                        <br />
                        配達時間:{' '}
                        <b>
                          {moment(props.post.delivery_start_time).format('HH:mm')} ~{' '}
                          {moment(props.post.delivery_end_time).format('HH:mm')}
                        </b>
                      </p>
                    </S.OpenTime>
                  </S.Times>
                )}
                {props.post.event_flag && props.post.force_order_date && (
                  <S.Times>
                    <S.OpenTime>
                      <i className="material-icons">navigate_next</i>
                      <p>
                        <b>予約注文は配達希望日の2日前まで注文可能です</b>
                      </p>
                    </S.OpenTime>
                  </S.Times>
                )}
                {props.post.event_flag && props.post.newyear_filter && (
                  <S.Times>
                    <S.OpenTime>
                      <i className="material-icons">navigate_next</i>
                      <p>
                        <b>お休み：12月29日～1月3日</b>
                      </p>
                    </S.OpenTime>
                  </S.Times>
                )}
              </S.HeaderInfo>
            </S.HeaderAside>
          </S.Header>
          {props.post.post_items && (
            <S.Section>
              <S.Items>
                {props.post.post_items.map(item => {
                  return (
                    <S.Item key={item.id} className={isOutOfStock(item) && 'disabled'}>
                      <S.ItemImage>
                        {item.image_url === '/images/no-avatar.svg' ? (
                          <S.NoImage>No Image</S.NoImage>
                        ) : (
                          <img src={item.image_url} />
                        )}
                      </S.ItemImage>
                      <S.ItemInfo>
                        <S.ItemName>{item.name}</S.ItemName>
                        <S.ItemDescription>{item.description}</S.ItemDescription>
                        <S.ItemPrice>{formatPrice(item.retail_price)}</S.ItemPrice>
                        <Button
                          primary={true}
                          disabled={!canOrderState}
                          handleClick={() => {
                            if (!props.user) {
                              location.href = '/users/sign_in'
                              return
                            }
                            handleSetCartItem(item)
                          }}
                        >
                          {item.aasm_state === 'published' ? 'カートに追加' : '注文停止中'}
                        </Button>
                      </S.ItemInfo>
                      {isNotPublishedPostItem(item) && <S.ItemDisabled />}
                    </S.Item>
                  )
                })}
              </S.Items>
            </S.Section>
          )}
          {props.post.note && (
            <S.Section>
              <h3>{I18n.t('post.note')}</h3>
              <S.Note>{props.post.note}</S.Note>
            </S.Section>
          )}
          {props.post.address?.latitude1 && props.post.address?.longitude1 && (
            <S.Section>
              <h3>{I18n.t('generic.access')}</h3>
              <h4>{getFullAddress()}</h4>
              {props.post.address && (
                <S.Phone>
                  <i className="material-icons">phone</i> {props.post.address.phone}
                </S.Phone>
              )}
              <S.Map id="map" />
            </S.Section>
          )}
        </S.Content>
        <SideBar
          post={props.post}
          showReservationPanel={showReservationPanel}
          setShowReservationPanel={setShowReservationPanel}
          reservationErrors={reservationErrors}
          isSignedIn={props.isSignedIn}
          userLiked={userLiked}
          cartItems={localCartItems}
          ordable={ordable}
          available={available}
          totalPrice={totalPrice}
          updateCartItems={updateCartItems}
          createReservation={createReservation}
          toggleLike={toggleLike}
          couponCodes={couponCodes}
          setCouponCodes={setCouponCodes}
          setStoreCouponCode={setStoreCouponCode}
          couponValues={couponValues}
          storeCouponValue={storeCouponValue}
          user_already_used_coupon={false}
        />
      </S.Main>
      {/* <RelatedPosts post={props.post} scrollContainerRef={scrollContainerRef} /> */}
      <S.Footer className={canOrderState ? '' : 'hidden'}>
        <div>
          <S.FooterPrice>{previewTotalPrice()}</S.FooterPrice>
        </div>
        {localCartItems.length > 0 && !ordable && (
          <S.FooterMinPrice>
            最低注文金額 {formatPrice(props.post.display_min_price)}
          </S.FooterMinPrice>
        )}
        <Button
          primary={true}
          disabled={!canOrderState || !ordable}
          handleClick={() => setShowReservationPanel(true)}
        >
          カートを見る
        </Button>
      </S.Footer>
    </S.PostShow>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.PostShow = styled.div``

S.CartModal = styled.div`
  min-height: 50%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  > p {
    width: 100%;
  }
`
S.CartItemName = styled.h3`
  flex-grow: 0;
  flex-basis: auto;
  font-size: 20px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 18px;
  }
`

S.ModalItemImage = styled.div`
  display: flex;
  width: 100%;
  min-height: 240px;
  margin-bottom: 16px;
  flex-grow: 0;
  flex-basis: auto;
  > img {
    max-height: 320px;
    min-height: 240px;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      max-height: 240px;
    }
  }
`

S.ModalItemDescription = styled.p`
  flex-grow: 1;
  flex-basis: 88px;
  overflow-y: auto;
  font-size: 14px;
  color: #8b8b8b;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 12px;
  }
`

S.AddCartWrapper = styled.div`
  flex-grow: 0;
  flex-basis: auto;
`
S.Price = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
`

S.ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 58px;
  > button {
    height: 100%;
    font-size: 18px;
    margin: 0 12px;
    padding: 8px 30px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    height: 40px;
    > button {
      font-size: 14px;
      padding: 8px;
    }
  }
`

S.Owner = styled.div`
  display: flex;
  height: 48px;
  padding: 0 24px;
  align-items: center;
  background-color: #ff9d33;
  color: #fff;
  font-weight: bold;

  > a {
    color: #fff;
    text-decoration: underline;
    margin-left: 12px;
  }
`

S.Note = styled.div`
  white-space: pre-wrap;
  overflow: auto;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    max-height: 10em;
  }
`

S.Main = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 24px 0;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding: 0 24px 96px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    flex-wrap: wrap;
    padding: 0 12px 96px;
  }
`

S.Header = styled.div`
  display: flex;
  padding-bottom: 16px;
`

S.Content = styled.div`
  flex: 1;
  margin-right: 16px;
  line-height: 1.5;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    flex: initial;
    margin-right: 0;
  }
`

S.Section = styled.section`
  border-top: solid 1px ${COLORS.Border};
  padding: 32px 0 48px;

  > h3 {
    font-size: 20px;
  }
`

S.Footer = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 72px;
  background-color: #fff;
  border-top: solid 1px ${COLORS.Border};
  z-index: 100;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: flex;
    &.hidden {
      display: none;
    }
  }
`

S.FooterMinPrice = styled.p`
  text-align: center;
  font-size: 14px;
`

S.FooterPrice = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 4px;
`

S.Tags = styled.div`
  margin-bottom: 32px;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    overflow: hidden;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 8px 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    color: var(${THEME_COLOR_VARIABLE_NAME});
    border-radius: 3px;
    overflow: hidden;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      padding: 6px 8px;
      font-size: 12px;

      > i {
        font-size: 10px;
        margin-right: 4px;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(${THEME_COLOR_VARIABLE_NAME});
      opacity: 0.12;
    }

    > i {
      font-size: 16px;
      margin-right: 8px;
    }
  }
`

S.Description = styled.div`
  white-space: pre-wrap;
  margin-bottom: 8px;
`

S.NotOpen = styled.div`
  display: flex;
  flex-shrink: 0;
  color: #888;
  margin-bottom: 16px;
  > b {
    font-weight: bold;
  }
`
S.Times = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    display: inline-flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin: 8px 0;
    }
  }
`
S.DeliveryTime = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-right: 8px;
  }
  > b {
    font-weight: bold;
  }
`

S.OpenTime = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-right: 8px;
  }
  > b {
    font-weight: bold;
  }
  p {
    font-size: 0.9rem;
  }
`
S.Phone = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

S.Items = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
S.Item = styled.div`
  position: relative;
  display: inline-flex;
  width: 50%;
  height: 200px;
  margin-bottom: 15px;
  overflow: hidden;

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`
S.ItemDisabled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff99;
`

S.ItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 220px;
  margin: 0 15px;
  > button {
    width: 100%;
  }
`
S.ItemName = styled.p`
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  height: 42px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`
S.ItemDescription = styled.p`
  width: 100%;
  font-size: 14px;
  height: 66px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #8b8b8b;
`
S.ItemPrice = styled.p`
  font-size: 23px;
  font-weight: bold;
`
S.ItemImage = styled.div`
  display: flex;
  position: relative;
  width: 280px;
  height: 100%;
  min-height: 160px;

  > img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`
S.Images = styled.div`
  position: relative;
`
S.ItemNoImageWrap = styled.div`
  height: 240px;
`

S.Image = styled.div`
  height: 520px;
  margin-bottom: 24px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: 400px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 320px;
  }

  > img {
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
`

S.NoImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`

S.MobileToggleLike = styled.div`
  display: none;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  color: #fff;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }

  .material-icons {
    font-size: 32px;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);

    &.liked {
      color: #fb585c;
    }
  }
`

S.HeaderAside = styled.div`
  flex: 1;
`

S.Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
`

S.HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
`

S.Place = styled.div`
  font-size: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
`

S.Map = styled.div`
  margin-top: 16px;
  height: 440px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 320px;
  }
`

S.ReviewScore = styled.div`
  margin: 24px 0 48px;
`

S.Host = styled.div`
  margin-top: 32px;

  > p {
    margin-top: 32px;
  }

  > .Button {
    margin-top: 24px;
  }
`

S.Avatar = styled.div`
  display: flex;
  align-items: center;
`

S.AvatarImage = styled.a`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 24px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

S.AvatarInfo = styled.div`
  flex: 1;

  h4 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  > span {
    display: block;
  }
`

export default PostShow
