import * as React from 'react'
import SliderMenu, { IMenuPanel } from '../molecules/SliderMenu'

const MENU_PANELS: IMenuPanel[] = [
  {
    link: '/posts/search?area=札幌',
    title: '札幌',
    image_path: '/images/top/img_sapporo.jpg',
  },
  {
    link: '/posts/search?area=帯広',
    title: '帯広',
    image_path: '/images/top/img_obihiro.jpg',
  },
]

const AreaSelect: React.FC<{}> = props => {
  return <SliderMenu title="エリアを選択" link={0} menuPanels={MENU_PANELS} />
}

export default AreaSelect
