import * as React from 'react'
import { CardElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { settingsService } from '../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'
import { getStripePublicKey } from '../../utils'
import StripeFormNote from './StripeFormNote'
interface IProps {
  stripe: any
  setStripeParamsGetter: any
}

const createOptions = () => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: COLORS.Text,
        letterSpacing: '0.5px',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: COLORS.Danger,
      },
    },
  }
}

const StripeCardSelect: React.FC<IProps> = props => {
  const [card, setCard] = React.useState(null)
  const [useRegisteredCard, setUseRegisteredCard] = React.useState(false)

  const getCard = async () => {
    const { card: currentCard } = await settingsService.getCard()
    setCard(currentCard)
  }

  React.useEffect(() => {
    getCard()
  }, [])

  React.useEffect(() => {
    setUseRegisteredCard(card !== null)
  }, [card])
  const getStripeParams = React.useCallback(async () => {
    if (useRegisteredCard) {
      return { stripe_card_id: card.id }
    } else {
      const createTokenResponse = await props.stripe.createToken()

      if (!createTokenResponse.token) {
        return null
      }

      return { stripeToken: createTokenResponse.token.id }
    }
  }, [card, useRegisteredCard])

  React.useEffect(() => {
    props.setStripeParamsGetter(() => getStripeParams)
  }, [getStripeParams])

  return (
    <S.CardForm>
      <S.CardFormLabel>{I18n.t('generic.payment')}</S.CardFormLabel>
      <S.Radio>
        {card && (
          <label>
            <input
              type="radio"
              name="useRegisteredCard"
              value=""
              checked={useRegisteredCard}
              onChange={() => setUseRegisteredCard(true)}
            />
            <S.RegisteredCard>
              <span>{I18n.t('card.registered_cards')} -</span>
              <span>{card.brand}</span>
              <span>{I18n.t('card.card_end')}: </span>
              <span>{card.last4}</span>
              <span className="exp">{I18n.t('generic.expiration_date')}: </span>
              <span>
                {card.exp_month}/{card.exp_year}
              </span>
            </S.RegisteredCard>
          </label>
        )}
        <label>
          <input
            type="radio"
            name="useRegisteredCard"
            value=""
            checked={!useRegisteredCard}
            onChange={() => setUseRegisteredCard(false)}
          />
          <span>{I18n.t('card.new_card')}</span>
        </label>
      </S.Radio>
      {!useRegisteredCard && (
        <div>
          <CardElement {...createOptions()} />
          <StripeFormNote />
        </div>
      )}
    </S.CardForm>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.CardForm = styled.div`
    span.exp {
      margin-left: 24px;
    }

    & + .Button {
      margin-top: 16px;
    }
  }

  .StripeElement {
    display: block;
    margin: 10px 0 10px 0;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
  }

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }

  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
`
S.CardFormLabel = styled.p`
  margin-top: 12px;
  font-weight: bold;
`
S.RegisteredCard = styled.span`
  span + span {
    margin-left: 8px;
  }
`

S.Radio = styled.div`
  margin: 24px 0 0;

  > h3 {
    display: block;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  > label {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 32px;

    &:hover {
      opacity: 0.75;
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    input + span {
      font-size: 14px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      &::before {
        width: 18px;
        height: 18px;
        border: solid 1px ${COLORS.Border};
        border-radius: 50%;
      }
    }

    input:checked + span {
      &::before {
        width: 18px;
        height: 18px;
        border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
        border-radius: 50%;
      }

      &::after {
        left: 4px;
        width: 10px;
        height: 10px;
        background-color: var(${THEME_COLOR_VARIABLE_NAME});
        border-radius: 50%;
      }
    }
  }

  > label + label {
    margin-top: 16px;
  }
`
S.StripeFormNote = styled.p`
  font-size: 14px;
`

const StripeInjected = injectStripe(StripeCardSelect)
const StripeCardSelectWithProvider: React.FC<{ setStripeParamsGetter: any }> = props => {
  return (
    <StripeProvider apiKey={getStripePublicKey()}>
      <Elements>
        <StripeInjected setStripeParamsGetter={props.setStripeParamsGetter} />
      </Elements>
    </StripeProvider>
  )
}

export default StripeCardSelectWithProvider
