import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { Button } from '../../../atoms'
import { EditTableCoupon } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

declare var window: IWindow

interface IProps {
  coupons: {
    coupons: IJsonResponse
  }
}

const COLUMNS = [
  {
    name: I18n.t('generic.code'),
    field: 'code',
    required: true,
  },
  {
    name: I18n.t('generic.value'),
    field: 'value',
    required: false,
  },
  {
    name: I18n.t('generic.start_at'),
    field: 'display_start_at',
    required: false,
  },
  {
    name: I18n.t('generic.end_at'),
    field: 'display_end_at',
    required: false,
  },
  {
    name: I18n.t('generic.target_post_id'),
    field: 'target_post_name',
    required: false,
  },
  {
    name: I18n.t('generic.once_a_day_limit'),
    field: 'once_a_day_limit',
    required: false,
  },
  {
    name: I18n.t('generic.total_quantity'),
    field: 'total_quantity',
    required: false,
  },
  {
    name: I18n.t('generic.remaining_quantity'),
    field: 'remaining_quantity',
    required: false,
  },
]

const AdminCouponIndex: React.FC<IProps> = props => {
  const { data, pagination } = adminService.getDataFromJson(props.coupons.coupons)
  const [coupons, setCoupons] = React.useState(data)
  const handleDelete = React.useCallback(
    async id => {
      const { flush } = await adminService.deleteCoupon(id)
      window.flashMessages.addMessage({
        text: flush.message,
        type: flush.type,
      })
      window.globalModal.closeModal()
      setCoupons(coupons.filter(coupon => coupon.id !== id))
      location.reload()
    },
    [coupons]
  )

  return (
    <AdminLayoutIndex
      model="coupon"
      heading={
        <S.Heading>
          <span>{I18n.t('activerecord.models.coupon')}</span>
          <Button small={true}>
            <a href="/admin/coupons/new">{I18n.t('generic.create_new')}</a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTableCoupon
          editable={true}
          columns={COLUMNS}
          records={coupons}
          pagination={pagination}
          handleDelete={handleDelete}
          getEditLink={id => `/admin/coupons/${id}/edit`}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default AdminCouponIndex
