import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { postService, utilService } from '../../../../core/services'
import { BREAKPOINT_DESKTOP, HEADER_HEIGHT } from '../../../../static/constants'
import { PostList } from '../../../organisms'
import Profile from './Profile'
// import Reviews from './Reviews'

interface IProps {
  user: IJsonResponse
  posts: {
    posts: IJsonResponse
  }
  reviews?: {
    reviews: IJsonResponse
  }
}

const Show: React.FC<IProps> = props => {
  // const { data: initialReviews } = utilService.getDataFromJson(props.reviews?.reviews)
  const { data: user } = utilService.getDataFromJson(props.user)
  const { posts: initialPosts, pagination } = postService.getPostsFromJson(props.posts.posts)
  // TODO pagination
  // const [reviews, setReviews] = React.useState({
  //   data: initialReviews,
  //   pagination: { ...props.reviews.reviews.meta },
  // })
  const [posts, setPosts] = React.useState(initialPosts)
  const [meta, setMeta] = React.useState(pagination)
  const toggleLike = (postId, like) => {
    setPosts(currentPosts => {
      return currentPosts.map(postItem => ({
        ...postItem,
        user_liked: postItem.id === postId ? like : postItem.user_liked,
      }))
    })
  }

  const getPostsByPage = async page => {
    location.href = `${location.pathname}?page=${page}`
  }

  return (
    <ShowWrapper>
      <div className="Show_Inner">
        <Profile user={user} />
        {/* <Reviews
          reviews={reviews}
          count={user.review_count || 8}
        /> */}
        <PostList
          title={count => I18n.t('post.users_post', { username: user.username })}
          noPostsTitle={I18n.t('post.users_post_none', { username: user.username })}
          getPostsByPage={getPostsByPage}
          isSignedIn={true}
          favorite={false}
          posts={posts}
          toggleLike={toggleLike}
          meta={meta}
        />
      </div>
    </ShowWrapper>
  )
}

const ShowWrapper = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: scroll;

  .Show_Inner {
    max-width: 1200px;
    margin: auto;
    padding: 24px 0;
    @media (max-width: ${BREAKPOINT_DESKTOP}px) {
      display: block;
    }
  }

  .PostList {
    margin-top: 32px;
  }

  .PostList_Header {
    font-size: 20px;
    font-weight: bold;
  }
`

export default Show
