import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { postService, reservationService } from '../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import * as forms from '../../../utils/form'
import { Button, InputTextArea } from '../../atoms'
import { Form } from '../../molecules'

interface IProps {
  review: any
  reservation: any
  post: any
  post_images: any[]
  reviewer: any
}

const FIELDS = {
  rating: 'rating',
  body: 'body',
}

const SCORE_VALUES = [1, 2, 3, 4, 5]

const ReviewNew: React.FC<IProps> = props => {
  const starsRef = React.useRef(null)
  const { post } = postService.getPostFromJson(props.post)
  const { reservation } = reservationService.getReservationFromJson(props.reservation)
  const [score, setScore] = React.useState(0)
  const [errors, setErrors] = React.useState({ body: null })
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [completed, setCompleted] = React.useState(false)

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const params = {
      reservation_id: reservation.id,
      rating: Number(values.rating),
      body: values.body,
    }

    await postService.createReview(params)
    setCompleted(true)
  }, [])

  return (
    <S.ReviewNew>
      <S.Main>
        <S.Post>
          <S.PostImage>
            <img src={post.post_images[0].image_url} alt="" />
          </S.PostImage>
          <S.PostInfo>
            <S.PostTitle>{post.name}</S.PostTitle>
            <S.PostOwner>
              <S.Avator>
                <img src={post.user.avatar_url} alt="" />
              </S.Avator>
              <span>{post.user.username}</span>
            </S.PostOwner>
          </S.PostInfo>
        </S.Post>
        <S.Editor>
          {completed ? (
            <div>
              <S.Done>
                <i className="material-icons filled">done</i>
                <p>{I18n.t('review.completed')}</p>
              </S.Done>
              <S.FlexCenter>
                <S.Link href="/reservations?past=true">{I18n.t('review.back_to_index')}</S.Link>
              </S.FlexCenter>
            </div>
          ) : (
            <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
              <h2>{I18n.t('review.add_review')}</h2>
              <S.Stars>
                <input
                  required={true}
                  readOnly={true}
                  type="text"
                  name="rating"
                  defaultValue=""
                  ref={starsRef}
                />
                <div>
                  {SCORE_VALUES.map(value => (
                    <span
                      key={value}
                      onClick={() => {
                        forms.setNativeValue(starsRef.current, value, false)
                        starsRef.current.dispatchEvent(new Event('input', { bubbles: true }))
                        setScore(value)
                      }}
                    >
                      <S.Star
                        filled={value <= score}
                        viewBox="0 0 64 64"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polygon
                          fillRule="nonzero"
                          points="32.5 45.9878947 48.877 56 44.531 37.13 59 24.4336842 39.9465 22.7963158 32.5 5 25.0535 22.7963158 6 24.4336842 20.469 37.13 16.123 56"
                        />
                      </S.Star>
                    </span>
                  ))}
                </div>
              </S.Stars>
              <InputTextArea
                required={true}
                name="body"
                placeholder={I18n.t('placeholder.review_body')}
                defaultValue=""
                error={errors.body}
              />
              <Button primary={true} disabled={!isSubmitEnabled}>
                {I18n.t('generic.send')}
              </Button>
            </Form>
          )}
        </S.Editor>
      </S.Main>
    </S.ReviewNew>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.ReviewNew = styled.div`
  padding: 24px 12px 0;
`

S.Main = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

S.Post = styled.div`
  display: flex;
  align-items: center;
`

S.PostImage = styled.div`
  width: 200px;
  height: 160px;
  border-radius: 3px;
  overflow: hidden;

  > img {
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
`

S.PostInfo = styled.div`
  flex: 1;
  margin-left: 16px;
`

S.PostTitle = styled.h2`
  font-size: 20px;
`

S.PostOwner = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 12px;
  }
`

S.Editor = styled.div`
  margin-top: 24px;
  border-top: solid 1px ${COLORS.Border};

  h2 {
    margin-top: 12px;
    font-size: 18px;
  }

  .Button {
    margin-top: 12px;
  }
`

S.FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

S.Avator = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 12px;

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

S.Stars = styled.div`
  margin-top: 4px;

  > input {
    display: none;
  }

  > div {
    display: flex;

    > span {
      cursor: pointer;
    }
  }
`

S.Star = styled.svg<{ filled: boolean }>`
  width: 32px;
  height: auto;
  fill: none;

  > polygon {
    stroke: #e0e4e6;
    stroke-width: 2px;
  }

  ${({ filled }) =>
    filled &&
    `
    stroke: none;
    fill: #ffb800;

    > polygon {
      stroke: none;
    }
  `}
`

S.Done = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  padding: 8px;
  color: ${COLORS.Success};
  border: solid 1px ${COLORS.Success};
  border-radius: 3px;
  box-shadow: 0 0 0 4px #dff7df inset;

  > p {
    margin-left: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  .material-icons {
    fill: ${COLORS.Success};
  }
`

S.Link = styled.div`
  color: var(${THEME_COLOR_VARIABLE_NAME});
  text-decoration: underline;
`

export default ReviewNew
