import * as React from 'react'
import I18n from '../../../../core/i18n'
import { InputText, Spacer } from '../../../atoms'
import AdminLayoutNew from '../_layouts/new'

const FIELDS = {
  user_id: 'user_id',
  post_id: 'post_id',
  start_date: 'start_date',
  end_date: 'end_date',
  workflow_state: 'workflow_state',
  price: 'price',
  paid_at: 'paid_at',
  canceled_at: 'canceled_at',
  updated_at: 'updated_at',
  refund_amount: 'refund_amount',
  authorized_at: 'authorized_at',
}

const title = I18n.t('admin.create_new', {
  model: I18n.t('reservation', { scope: 'activerecord.models' }),
})

const AdminReservationNew: React.FC<{}> = props => {
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    // const response = await api.createReservationByAdmin(values)
    // location.href = '/admin/reservations'
  }, [])

  return (
    <AdminLayoutNew
      model="reservation"
      title={title}
      fields={FIELDS}
      indexLink="/admin/reservations"
      formItems={
        <>
          <InputText name="user_id" defaultValue="" label="User id" />
          <Spacer />
          <InputText name="post_id" defaultValue="" label="Post id" />
          <Spacer />
          <InputText name="start_date" defaultValue="" label="Start date" />
          <Spacer />
          <InputText name="end_date" defaultValue="" label="End date" />
          <Spacer />
          <InputText name="workflow_state" defaultValue="" label="Workflow state" />
          <Spacer />
          <InputText name="price" defaultValue="" label="Price" />
          <Spacer />
          <InputText name="paid_at" defaultValue="" label="Paid at" />
          <Spacer />
          <InputText name="canceled_at" defaultValue="" label="Canceled at" />
          <Spacer />
          <InputText name="updated_at" defaultValue="" label="Updated at" />
          <Spacer />
          <InputText name="refund_amount" defaultValue="" label="Refund amount" />
          <Spacer />
          <InputText name="authorized_at" defaultValue="" label="Authorized at" />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminReservationNew
