import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../static/constants'

export interface ISliderMenuPanelProps {
  title: string
  link: string
  image_path?: string
  clickable?: boolean
}

const Panel: React.FC<ISliderMenuPanelProps> = ({ title, link, image_path, clickable }) => {
  const handleClick = () => {
    if (clickable === undefined || clickable) {
      location.href = link
    }
  }
  return (
    <S.Panel onClick={handleClick}>
      <span>{title}</span>
      {image_path && <img className="panels__img" src={image_path} alt="" />}
      <div className="shadow" />
    </S.Panel>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 240px;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
    height: 180px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  span {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    color: #fff;
    font-weight: bold;
    z-index: 1;
    margin: auto;
    text-align: center;
    font-size: 20px;
    line-height: 0;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}) {
      font-size: 24px;
    }
  }
  .shadow {
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 3%, #000 100%);
    opacity: 0.6;
    position: absolute;
    height: 60%;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`

export default Panel
