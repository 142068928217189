import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IRole } from '../../../core/interfaces'
import { COLORS, HEADER_HEIGHT, ROLES, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import LocaleSelect from '../../atoms/LocaleSelect'

type LayoutTypes = 'desktop' | 'mobile'

interface IProps {
  layout: LayoutTypes
  isSignedIn: boolean
  lang?: any
  role?: IRole
}
const MenuList: React.FC<IProps> = ({ layout, isSignedIn, lang, role }) => {
  const isGuest = isSignedIn && role.id === 1
  const isPost = isSignedIn && role.id === 2
  const isAdmin = isSignedIn && role.id === 3

  return (
    <S.MenuList className="MenuList" layout={layout}>
      {isSignedIn ? (
        <>
          {isAdmin && (
            <li>
              <a href="/admin/posts">{I18n.t('top_nav.admin')}</a>
            </li>
          )}
          <li>
            <a href="/posts/search">{I18n.t('top_nav.search_post')}</a>
          </li>
          {isAdmin && (
            <li>
              <a href="/posts/new">{I18n.t('top_nav.create_post')}</a>
            </li>
          )}
          {!isGuest && (
            <li>
              <a href="/posts">{I18n.t('top_nav.posts')}</a>
            </li>
          )}
          {/* <li>
            <a href="/favorites">{I18n.t('top_nav.favorites')}</a>
          </li> */}
          {!isPost && !isAdmin && (
            <li>
              <a href="/reservations">{I18n.t('top_nav.reservations')}</a>
            </li>
          )}
          {!isGuest && !isAdmin && (
            <li>
              <a href="/host/reservations">{I18n.t('top_nav.reservations')}</a>
            </li>
          )}
          {!isGuest && !isAdmin && (
            <li>
              <a href="/host/payments">{I18n.t('top_nav.payments')}</a>
            </li>
          )}
          {isAdmin && (
            <li>
              <a href="/admin/payments">{I18n.t('top_nav.payments')}</a>
            </li>
          )}

          <li className="separate">
            <a href="/settings">{I18n.t('top_nav.settings')}</a>
          </li>
          <li>
            <a href="/contacts/new">{I18n.t('top_nav.contacts')}</a>
          </li>
          <li className="separate">
            <a rel="nofollow" data-method="delete" href="/users/sign_out">
              {I18n.t('top_nav.logout')}
            </a>
          </li>
          {lang && (
            <li className="separate">
              <LocaleSelect />
            </li>
          )}
        </>
      ) : (
        <>
          <li>
            <a href="/users/sign_in">{I18n.t('top_nav.login')}</a>
          </li>
          <li>
            <a href="/users/sign_up">{I18n.t('top_nav.signup')}</a>
          </li>
          {lang && (
            <li className="separate">
              <LocaleSelect />
            </li>
          )}
        </>
      )}
    </S.MenuList>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}

S.MenuList = styled.ul<{ layout: LayoutTypes }>`
  display: none;
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  right: 0;
  width: 180px;
  padding: 12px 0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(21, 27, 38, 0.08);
  font-size: 14px;

  ${({ layout }) =>
    layout === 'mobile' &&
    `
    position: static;
    display: block;
    width: 100%;
    padding: 16px 0;
    box-shadow: none;
  `}

  > li {
    ${({ layout }) =>
      layout === 'desktop' &&
      `
      > a {
        display: block;
        padding: 4px 12px;
        cursor: pointer;
        color: ${COLORS.Text};
        text-decoration: none;

        :hover {
          color: var(${THEME_COLOR_VARIABLE_NAME});
        }
      }

      &.separate > a {
        border-top: solid 1px ${COLORS.Border};
        margin-top: 4px;
        padding-top: 6px;
      }
    `}

    ${({ layout }) =>
      layout === 'mobile' &&
      `
      > a {
        display: block;
        padding: 8px 16px;
        font-size: 15px;
        color: ${COLORS.Text};

        :hover {
          background-color: #f4f4f4;
        }
      }
    `}
  }

  > li.disabled a {
    color: #ccc;
    cursor: default;

    :hover {
      color: #ccc;
    }
  }
`

export default MenuList
