import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { BREAKPOINT_TABLET_SMALL } from '../../../../static/constants'
import { Button } from '../../../atoms'
import AdminLayoutIndex from '../_layouts/'
interface IProps {
  settings: any
}

const AdminSettingsShow: React.FC<IProps> = props => {
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = typeof setting.value === 'boolean' ? String(setting.value) : setting.value
    return obj
  }, {})

  return (
    <AdminLayoutIndex
      model="setting"
      heading={I18n.t('activerecord.models.setting')}
      main={
        <S.Settings>
          <dl>
            <dt>Default site</dt>
            <dd>{settings.default_site}</dd>
            <dt>Default title</dt>
            <dd>{settings.default_title}</dd>
            <dt>Default description</dt>
            <dd>{settings.default_description}</dd>
            <dt>Default keywords</dt>
            <dd>{settings.default_keywords}</dd>
            {/* <dt>Theme color</dt>
            <dd>{settings.theme_color}</dd>
            <dt>Ga tracking id</dt>
            <dd>{settings.ga_tracking_id}</dd>
            <dt>Skip identification</dt>
            <dd>{settings.skip_identification}</dd>
            <dt>Skip profile</dt>
            <dd>{settings.payment_required}</dd>
            <dt>Payment Reuired</dt>
            <dd>{settings.payment_required}</dd> */}
          </dl>
          <S.Buttons>
            <Button primary={true}>
              <a href="/admin/setting/edit">{I18n.t('generic.edit')}</a>
            </Button>
          </S.Buttons>
        </S.Settings>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Settings = styled.div`
  dl {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      flex-direction: column;
    }
  }

  dt {
    width: 240px;
    margin-top: 24px;
    font-weight: bold;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-top: 12px;
    }
  }

  dd {
    width: calc(100% - 240px);
    margin-top: 24px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-top: 12px;
    }
  }
`

S.Buttons = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  .Button[type='submit'] {
    width: 160px;
  }
`

export default AdminSettingsShow
