import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'
import FormItem from './_layouts/FormItem'

interface IProps {
  name: string
  defaultValue: string
  label?: null | string
  placeholder?: null | string
  error?: null | string
  maxLength?: null | number
  required?: boolean
}

const InputTextArea: React.FC<IProps> = ({
  name,
  defaultValue,
  label,
  placeholder,
  error,
  maxLength,
  required,
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <S.Textarea
        id={name}
        name={name}
        placeholder={placeholder}
        required={required ? true : false}
        defaultValue={defaultValue}
        maxLength={maxLength}
      />
    </FormItem>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Textarea = styled.textarea`
  display: block;
  width: 100%;
  height: 160px;
  padding: 8px 12px;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  font-size: 15px;
  transition: border 0.2s ease;
  outline: none;
  background: none;
  line-height: 1.5;

  &:focus {
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  }
`

export default InputTextArea
