import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  name: string
  checked: boolean
  onChangeHandler(event: any): void
}

const Toggle: React.FC<IProps> = props => {
  return (
    <S.Toggle className="Toggle">
      <input
        name={props.name}
        type="checkbox"
        checked={props.checked}
        onChange={props.onChangeHandler}
      />
      <S.Slider className="ToggleSlider" />
    </S.Toggle>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Toggle = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .ToggleSlider {
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }

  input:focus + .ToggleSlider {
    box-shadow: 0 0 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
  }

  input:checked + .ToggleSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`

S.Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  :before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`

export default Toggle
