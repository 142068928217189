import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { BREAKPOINT_TABLET_LARGE, BREAKPOINT_TABLET_MOBILE } from '../../static/constants'

interface IProps {
  score: number
}

const SCORE_VALUES = [1, 2, 3, 4, 5]

const Score: React.FC<IProps> = ({ score }) => {
  return (
    <S.Score>
      <S.Wrapper>
        <S.Stars>
          {SCORE_VALUES.map(value => (
            <Star key={value} />
          ))}
        </S.Stars>
        <S.Stars score={score}>
          {SCORE_VALUES.map(value => (
            <Star key={value} filled={true} />
          ))}
        </S.Stars>
      </S.Wrapper>
      <S.Rating>{score}</S.Rating>
    </S.Score>
  )
}

const Star: React.FC<{ filled?: boolean }> = ({ filled }) => (
  <S.Star filled={filled}>
    <svg viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon
        fillRule="nonzero"
        points="32.5 45.9878947 48.877 56 44.531 37.13 59 24.4336842 39.9465 22.7963158 32.5 5 25.0535 22.7963158 6 24.4336842 20.469 37.13 16.123 56"
      />
    </svg>
  </S.Star>
)

const S: { [key: string]: AnyStyledComponent } = {}

S.Score = styled.div`
  display: flex;
  align-items: center;
`

S.Wrapper = styled.div`
  position: relative;
  width: 90px;
  height: 18px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: 80px;
    height: 16px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 60px;
    height: 12px;
  }
`

S.Stars = styled.div<{ score?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  display: flex;
  ${({ score }) =>
    Number.isFinite(score) &&
    `
    width: ${(score / 5) * 100}%
    overflow: hidden;
  `}

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`

S.Star = styled.div<{ filled: boolean }>`
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: 16px;
    height: 16px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 12px;
    height: 12px;
  }

  svg {
    width: inherit;
    height: inherit;
    fill: ${({ filled }) => (filled ? '#ffb800' : '#ededed')};
  }
`

S.Rating = styled.div`
  margin-left: 6px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    font-size: 12px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 10px;
  }
`

export default Score
