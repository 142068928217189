import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { getMergedFilterParams, getMergedFilterQueryString } from '../../../core/queryString'
import { reservationService } from '../../../core/services'
import {
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../static/constants'
import { Button, CheckBox, Pagination, TypeFilter } from '../../atoms'
import { IImperativeMethods } from '../../molecules/Form'

declare var window: IWindow

interface IProps {
  reservations: {
    reservations: IJsonResponse
  }
  isGuest: boolean
}

interface IType {
  id: number | string
  name: string
}

export const getDeliveryState: (reservation: {
  workflow_state: string
  delivery: { status: string }
}) => string = reservation => {
  if (reservation.workflow_state === 'declined' || reservation.workflow_state === 'canceled') {
    return 'キャンセル'
  }
  const delivery = reservation.delivery
  return delivery && delivery.status ? I18n.t(`delivery.${delivery.status}`) : '準備中'
}

const ReservationsIndex: React.FC<IProps> = props => {
  // console.log('予約一覧', props)

  // Reload page each minute
  const refreshPage = () => {
    window.location.reload(true)
  }
  setInterval(() => {
    refreshPage()
  }, 60000)

  const formRef = React.useRef<IImperativeMethods>(null)
  const { reservations: initialReservations } = reservationService.getReservationsFromJson(
    props.reservations.reservations
  )
  // console.log('initialReservations', initialReservations)
  const [reservations, setReservations] = React.useState(initialReservations)
  const [meta, setMeta] = React.useState(props.reservations.reservations.meta)

  const getShowAllReservation = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('all') === 'true'
  }
  const [showAll, setShowAll] = React.useState(getShowAllReservation())

  const updateReservations = updatedReservation => {
    const updatedReservations = reservations.map(reservation =>
      reservation.id === updatedReservation.id ? updatedReservation : { ...reservation }
    )
    setReservations(updatedReservations)
  }
  const STATUS: IType[] = [
    {
      id: 'canceled',
      name: I18n.t('enums.reservation.workflow_state.canceled'),
    },
    {
      id: 'approved',
      name: props.isGuest
        ? I18n.t('enums.reservation.workflow_state.approved')
        : I18n.t('enums.reservation.workflow_state.post_approved'),
    },
    {
      id: 'declined',
      name: I18n.t('enums.reservation.workflow_state.declined'),
    },
    {
      id: 'requested',
      name: I18n.t('enums.reservation.workflow_state.requested'),
    },
    {
      id: 'temporary_approved',
      name: I18n.t('enums.reservation.workflow_state.temporary_approved'),
    },
  ]

  const showPastReservationHandler = event => {
    if (event.target.checked) {
      if (props.isGuest) {
        location.href = '/reservations?past=true'
      } else {
        location.href = '/host/reservations?past=true'
      }
    } else {
      if (props.isGuest) {
        location.href = '/reservations'
      } else {
        location.href = '/host/reservations'
      }
    }
  }

  const getShowPastReservation = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('past') === 'true'
  }
  const handleOnChange = filterValue => {
    setShowAll(filterValue.state.length > 0)
  }
  const handleOnSubmit = async filterValue => {
    const fields = showAll ? ['state'] : ['state', 'past']
    const filterParams = getMergedFilterParams(fields, filterValue)
    const filterQueryString = getMergedFilterQueryString(fields, filterParams)
    location.href = `${location.pathname}?${showAll ? 'all=true&' : ''}${filterQueryString}`
  }

  const onChangePageHandler = async page => {
    // const fields = ['state']
    // const filterParams = getMergedFilterParams(fields, { page, past: 'true' })
    // const {
    //  reservations: searchedReservations,
    //  pagination,
    // } = await reservationService.searchReservations(filterParams)

    // setReservations(searchedReservations)
    // setMeta(pagination)
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('past') === 'true') {
      if (props.isGuest) {
        location.href = '/reservations?past=true&page=' + page
      } else {
        location.href = '/host/reservations?past=true&page=' + page
      }
    } else {
      if (props.isGuest) {
        location.href = '/reservations?page=' + page
      } else {
        location.href = '/host/reservations?page=' + page
      }
    }
  }

  const renderList = () => {
    const getURL = reservation =>
      props.isGuest ? `/reservations/${reservation.slug}` : `/host/reservations/${reservation.id}`
    const getWorkflowStateLabel = reservation => {
      if (reservation.workflow_state !== 'approved' || props.isGuest) {
        return reservation.workflow_state_i18n
      }
      return I18n.t('enums.reservation.workflow_state.post_approved')
    }

    return (
      <ReservationsList className="Reser">
        {reservations.map((reservation, index) => (
          <li
            key={index}
            className={`ReservationsList_Item ${!props.isGuest && reservation.workflow_state}`}
          >
            <a href={getURL(reservation)}>
              <div className="ReservationsList_ItemTitle">{reservation.post_name}</div>
              <div className="ReservationsList_ItemInfo">
                {I18n.t('reservation.reservation_date')}: {reservation.created_at}
              </div>
              {!props.isGuest && (
                <div className="ReservationsList_ItemInfo">
                  {I18n.t('generic.payer')}: {reservation.user.username}
                </div>
              )}
              <div className="ReservationsList_ItemState">
                {I18n.t('reservation.workflow_state')}: {getWorkflowStateLabel(reservation)}
              </div>
              <div className="ReservationsList_ItemState">
                {I18n.t('price.reservation')}: ¥{reservation.total_price}
              </div>
              {reservation.used_campaign && (
                <div className="ReservationsList_ItemState">
                  ({reservation.campaign_name}: -¥{reservation.used_campaign})
                </div>
              )}
              {reservation.coupons && (
                <div className="ReservationsList_ItemState">
                  {reservation.coupons.map((coupon, index2) => (
                    <p key={index2}>
                      (クーポン / コード{coupon.code} : -¥{coupon.value})
                    </p>
                  ))}
                </div>
              )}

              <div className="ReservationsList_ItemInfo">
                {I18n.t('reservation.item')}
                <div className="ReservationsList_Items">
                  {reservation.reservation_items.map(item => {
                    return (
                      <p key={item.id}>
                        {item.post_item.name} x {item.quantity}
                      </p>
                    )
                  })}
                </div>
              </div>
            </a>
          </li>
        ))}
      </ReservationsList>
    )
  }

  return (
    <ReservationsIndexWrapper>
      <div className="ReservationIndex_Header">
        <h2>{I18n.t('generic.filter')}</h2>
        <div className="ReservationIndex_Filters">
          <TypeFilter
            name={I18n.t('reservation.workflow_state')}
            field="state"
            handleOnChange={handleOnChange}
            handleOnSubmit={handleOnSubmit}
            types={STATUS}
          />
          <span className="ReservationIndex_CheckBox">
            <CheckBox
              name="show_passed_reservation"
              label={
                props.isGuest
                  ? I18n.t('reservation.show_passed_reservation')
                  : I18n.t('reservation.post_show_passed_reservation')
              }
              defaultChecked={getShowPastReservation()}
              onChangeHandler={showPastReservationHandler}
              showLabelInline={true}
            />
          </span>
        </div>
      </div>
      {reservations.length === 0 ? (
        <div className="ReservationIndex_NoReservation">
          <i className="material-icons">calendar_today</i>
          <h1>{I18n.t('reservation.no_reservations')}</h1>
        </div>
      ) : (
        renderList()
      )}
      {meta && (
        <Pagination
          onChangePageHandler={onChangePageHandler}
          currentPage={meta.current_page}
          prevPage={meta.prev_page}
          nextPage={meta.next_page}
          totalPages={meta.total_pages}
          totalCount={meta.total_count}
        />
      )}
    </ReservationsIndexWrapper>
  )
}

const ReservationsList = styled.ul`
  a {
    color: black;
  }
  .ReservationsList_Item {
    padding: 12px;
    border-top: solid 1px ${COLORS.Border};
    &.requested {
      background-color: #FFFF0022
    }
  }

  .ReservationsList_ItemTitle {
    font-size: 18px
    font-weight: bold;

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 14px;
    }
  }

  .ReservationsList_ItemDate {
    font-size: 14px;
    margin-top: 8px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 12px;
    }
  }

  .ReservationsList_ItemState, .ReservationsList_ItemInfo {
    font-size: 14px;
    margin-top: 4px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 12px;
    }
  }

`

const ReservationsIndexWrapper = styled.div`
  /* max-width: 1280px; */
  margin: 0 auto;

  .ReservationIndex_NoReservation {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;

    > i {
      font-size: 32px;
    }

    > h1 {
      margin-top: 12px;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .ReservationIndex_Header {
    padding: 12px 16px;
    > h2 {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  .ReservationIndex_Filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
    margin: 0 -6px;

    > div {
      display: inline-block;
    }
  }
  .ReservationIndex_CheckBox {
    .description {
      font-size: 12px;
      color: gray;
    }
  }
`

export default ReservationsIndex
