import ClassNames from 'classnames'
import * as Color from 'color'
import { debounce } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { postService } from '../../core/services'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  PAYMENT_REQUIRED,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import Pagination from '../atoms/Pagination'
import Score from '../atoms/Score'
import Spinner from '../atoms/Spinner'

const urlParams = new URLSearchParams(window.location.search)

interface IPostListProps {
  showDescription?: boolean
  showMap?: boolean
  loading?: boolean
  isSignedIn: boolean
  favorite: boolean
  posts: any
  meta: any
  hasMap?: boolean
  noPostsTitle?: string
  keyword?: null | string
  handleOnMouseEnter?(markerId): void
  handleOnMouseLeave?(): void
  getPostsByPage?(page: number): void
  title(count: number): string
  toggleLike(postId: number, like: boolean): void
}

const PostList: React.FC<IPostListProps> = props => {
  const [mapMode, setMapMode] = React.useState(false)

  React.useEffect(() => {
    const resizeHandler = debounce(() => {
      if (window.matchMedia(`(max-width: ${BREAKPOINT_DESKTOP}px)`).matches) {
        setMapMode(false)
      } else {
        setMapMode(true)
      }
      return
    }, 100)
    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  const isPublished = (state: 'published' | 'closed' | 'suspended' | 'draft') => {
    return state === 'published'
  }

  // const toggleLike = async post => {
  //   if (!props.isSignedIn) {
  //     location.href = '/users/sign_in'
  //     return
  //   }

  //   const { like } = await postService.toggleLike(post)
  //   props.toggleLike(post.id, like.isUserLiked)
  // }

  const handleOnMouseEnter = (id: number) => {
    if (typeof props.handleOnMouseEnter === 'function') {
      props.handleOnMouseEnter(id)
    }
  }

  const handleOnMouseLeave = () => {
    if (typeof props.handleOnMouseLeave === 'function') {
      props.handleOnMouseLeave()
    }
  }
  const TIME_FORMAT = new RegExp('^([01]?[0-9]|2[0-4]):([0-5][0-9])$')
  const checkTimeValue = (start, end) => {
    return start && end && TIME_FORMAT.test(start) && TIME_FORMAT.test(end)
  }

  return (
    <PostListWrapper className={ClassNames('PostList', { mapMode, hideMap: !props.showMap })}>
      <div className="PostList_Main">
        {props.loading ? (
          <Spinner />
        ) : (
          <div className="PostList_Inner">
            {props.favorite && (
              <h1 className="PostList_FavoriteHeader">{I18n.t('post.favorite_post_heading')}</h1>
            )}
            <div className="PostList_Header">
              {props.keyword && (
                <p className="PostList_Keyword">「{props.keyword}」の検索結果を表示中</p>
              )}
              <p className="PostList_Count">
                {/* {!props.meta || !props.meta.total_count ? ( */}
                {props.posts.length === 0 ? ( // TODO
                  <span className="Wrapper_NoResult">
                    {props.noPostsTitle
                      ? props.noPostsTitle
                      : props.favorite
                      ? I18n.t('favorite.no_favorites')
                      : I18n.t('post.no_search_result')}
                  </span>
                ) : (
                  <span>{props.title(props.posts.length)}</span>
                )}
              </p>
              {/* {props.posts.length > 0 && (
                <div className="PostList_Sort">
                  <span>Sort </span>
                  <OrderSelect>
                    <select
                      defaultValue={getCurrentSortColumn()}
                      onChange={event => changeOrder(event.target.value, getCurrentSortDirection())}
                    >
                      <option value="name">Name</option>
                      {constants.PAYMENT_REQUIRED && <option value="price">Price</option>}
                    </select>
                    <span />
                  </OrderSelect>
                  <span onClick={() => changeOrder(getCurrentSortColumn(), 'asc')}>↓ Asc</span>
                  <span onClick={() => changeOrder(getCurrentSortColumn(), 'desc')}>↑ Desc</span>
                </div>
              )} */}
            </div>
            <div className="PostListItem_Wrapper">
              {props.posts.map((post, index) => (
                <div
                  key={index}
                  className="PostListItem"
                  onMouseEnter={() => handleOnMouseEnter(post.id)}
                  onMouseLeave={handleOnMouseLeave}
                >
                  <a href={`/posts/${post.slug}`} target="_blank">
                    <div className="PostListItem_Upper">
                      {!isPublished(post.aasm_state) ? (
                        <div className="PostListItem_CloseImage">
                          {post.post_image && post.post_image.image_url ? (
                            <img src={post.post_image.image_url} alt="" />
                          ) : (
                            <p className="PostListItem_NoImage">No Image</p>
                          )}
                          <p className="PostListItem_CloseMsg">受付停止中</p>
                        </div>
                      ) : (
                        post.is_open ? (
                          <div className="PostListItem_Image">
                            {post.post_image && post.post_image.image_url ? (
                              <img src={post.post_image.image_url} alt="" />
                            ) : (
                              <p className="PostListItem_NoImage">No Image</p>
                            )}
                          </div>
                        ) : (
                          <div className="PostListItem_CloseImage">
                            {post.post_image && post.post_image.image_url ? (
                              <img src={post.post_image.image_url} alt="" />
                              ) : (
                                <p className="PostListItem_NoImage">No Image</p>
                              )}
                            <p className="PostListItem_CloseMsg">受付時間外</p>
                            </div>
                        )
                      )}
                      <div className="PostListItem_Info">
                        <div className="PostListItem_Header">
                          <div className="PostListItem_HeaderInfo">
                            <h2 className="PostListItem_Title">{post.name}</h2>
                            {post.tags && (
                              <div className="PostListItem_Tags">
                                {post.tags.map((tag, tagIndex) => (
                                  <span key={tagIndex} className="Tag">
                                    <i className="material-icons">local_offer</i>
                                    {tag.name}
                                  </span>
                                ))}
                              </div>
                            )}
                            {post.category && (
                              <div className="PostListItem_Category">{post.category.name}</div>
                            )}
                            {isPublished(post.aasm_state) && (
                              <span className="hours">
                                <div className="PostListItem_Times">
                                  {checkTimeValue(post.start_time1, post.end_time1) && (
                                    <div className="PostListItem_OpenTime">
                                      <i className="material-icons">store_mall_directory</i>
                                      {post.start_time1} - {post.end_time1}
                                    </div>
                                  )}
                                  {checkTimeValue(post.start_time2, post.end_time2) && (
                                    <div className="PostListItem_OpenTime">
                                      <i className="material-icons">navigate_next</i>
                                        {post.start_time2} - {post.end_time2}
                                    </div>
                                  )}
                                  {checkTimeValue(post.start_time3, post.end_time3) && (
                                    <div className="PostListItem_OpenTime">
                                      <i className="material-icons">navigate_next</i>
                                        {post.start_time3} - {post.end_time3}
                                    </div>
                                  )}
                                </div>
                              </span>
                            )}
                            </div>
                          {/* <div
                            className="PostListItem_Like"
                            onClick={event => {
                              event.preventDefault()
                              toggleLike(post)
                            }}
                          >
                            {post.user_liked ? (
                              <i className="material-icons liked">favorite</i>
                            ) : (
                              <i className="material-icons">favorite_border</i>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {props.showDescription && (
                      <div className="PostListItem_Description">
                        <p>{post.description}</p>
                      </div>
                    )}
                  </a>
                </div>
              ))}
            </div>
            {!props.meta || props.meta.total_pages <= 1 ? null : (
              <Pagination
                onChangePageHandler={props.getPostsByPage}
                currentPage={Number(props.meta.current_page)}
                prevPage={props.meta.prev_page}
                nextPage={props.meta.next_page}
                totalPages={props.meta.total_pages}
                totalCount={props.meta.total_count}
              />
            )}
          </div>
        )}
      </div>
      {props.hasMap && (
        <>
          <Map className={ClassNames({ show: mapMode })}>
            <div id="map" />
          </Map>
          <div className="PostList_MapToggle" onClick={() => setMapMode(!mapMode)}>
            {mapMode ? I18n.t('generic.to_index') : I18n.t('generic.map')}
          </div>
        </>
      )}
    </PostListWrapper>
  )
}

const PostListWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    display: block;
  }

  .Spinner {
    width: 100%;
  }

  .PostList_MapToggle {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 24px;
    width: 100px;
    height: 32px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    margin: auto;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1000;
    @media (max-width: ${BREAKPOINT_DESKTOP}px) {
      display: flex;
    }
  }

  .PostList_Main {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: scroll;
    margin: 0 16px;
    padding: 12px 0;
    @media (max-width: ${BREAKPOINT_DESKTOP}px) {
      width: 100%;
      margin: 0;
      padding-bottom: 72px;
      position: relative;
      z-index: 900;
      display: block;
      background-color: #fff;
    }
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      padding: 0 12px 72px;
    }
  }

  &.mapMode {
    .PostList_Main {
      @media (max-width: ${BREAKPOINT_DESKTOP}px) {
        display: none;
      }
    }
  }

  .PostList_Inner {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;
  }

  .PostList_FavoriteHeader {
    margin-top: 24px;
    font-size: 24px;
  }

  .PostList_Keyword {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .PostList_Count {
    font-size: 18px;
    margin-left: 1em;
  }

  .PostList_Header {
    margin-top: 10px;
  }

  .PostListItem_NotAvailable {
    font-size: 12px;
    color: ${COLORS.Danger};
  }

  .PostList_Sort {
    display: flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      display: none;
    }
  }

  &.hideMap {
    .PostList_Main {
      display: block;
      width: 100%;
    }

    .PostList_Inner {
      max-width: 1440px;
      margin: auto;
    }

    .PostListItem {
      > a {
        display: block;
        border: none;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  &.hideMap.mapMode {
    .PostList_Main {
      @media (max-width: ${BREAKPOINT_DESKTOP}px) {
        display: none;
      }
    }
  }

  .PostListItem_Wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .PostListItem {
    padding: 10px;
    width: calc(100% / 3);
    flex-shrink: 0;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      width: calc(100% / 2);
    }
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      padding: 10px 0;
      width: 100%;
    }

    &.disabled {
      pointer-events: none;

      .PostListItem_Image {
        opacity: 0.4;
      }
    }

    > a {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 200px;
      overflow: hidden;
      color: ${COLORS.Text};
      background-color: #fff;
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        flex-direction: column;
      }
    }
  }

  .PostListItem_Upper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .PostListItem_Description {
    margin-top: 16px;
    padding-top: 16px;
    border-top: solid 1px ${COLORS.Border};

    > p {
      font-size: 14px;
    }
  }

  .PostListItem_NoImage {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
  }

  .PostListItem_Title {
    font-size: 16px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 14px;
    }
  }

  .PostListItem_Image {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #f4f4f4;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      width: 100%;
      height: 290px;
    }

    > img {
      width: inherit;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  .PostListItem_CloseImage {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #f4sf4f4;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      width: 100%;
      height: 290px;
    }
    > img {
      width: inherit;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    ::after {
      content: '';
      background-color: rgba(0,0,0,.5);
      position: absolute;
      left: 1px;
      right: 1px;
      top: 0px;
      bottom: 0;
      height: 200pxs;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  .PostListItem_CloseMsg{
    margin: 0;
    padding: .2em;
    font-size:2.0rem;
    color: #FFF;
    font-weight: bold;
    position: relative;
    z-index: 1;
    margin-top: -132px;
    text-align: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin-top: -170px;
    }
  }

  .PostListItem_Header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .PostListItem_Like {
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;

      .material-icons {
        color: ${Color(COLORS.Text)
          .lighten(3.6)
          .hex()};

        &.liked {
          color: #fb585c;
        }
      }
    }
  }

  .PostListItem_Category {
    font-size: 14px;
    margin-bottom: 4px;
    color: ${COLORS.TextPrimaryGray};
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }
  }

  .PostListItem_Tags {
    display: flex;
    flex-wrap: wrap;
    margin: 4px -4px;
    color: var(${THEME_COLOR_VARIABLE_NAME});
    font-size: 13px;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }

    .Tag {
      display: flex;
      align-items: center;
      margin: 0 4px;

      .material-icons {
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }

  .PostListItem_HeaderInfo {
    width: calc(100% - 28px);
    flex: 1;
  }

  .PostListItem_Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin-top: 12px;
    }
  }

  .PostListItem_Price {
    font-size: 14px;
    font-weight: bold;
  }

  .PostListItem_Info {
    flex: 1;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hours {
    font-size: 0.8rem;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 0.6rem;
  }

  .PostListItem_Times{
    display: flex;
    flex-wrap: wrap;
  
    > * {
      display: inline-flex;
      align-items: center;
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        margin: 8px 0;
      }
    }
  }

  .PostListItem_OpenTime{
    flex-shrink: 0;
    margin-right: 16px;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin: 0;
    }
    i {
      font-size: 17px;
      color: #fb585c;
    }
  }

  .Pagination {
    height: 48px;
    border-top: solid 1px ${COLORS.Border};
  }
`

const OrderSelect = styled.div`
  position: relative;
  border: solid 1px #eee;
  margin-left: 12px;
  border-radius: 6px;

  > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    color: inherit;
    box-sizing: content-box;
    outline: none;

    width: 120px;
    padding: 2px 8px;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    background-color: #fff;
  }

  > span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 9px;
    margin: auto;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #4f5959;
  }
`

const Map = styled.div`
  display: block;
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    position: absolute;
    top: 0;
    left: 0;
  }

  #map {
    height: 100%;
    width: 100%;
  }
`

export default PostList
