import { once } from 'lodash'
import * as React from 'react'
import { Waypoint } from 'react-waypoint'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import { BREAKPOINT_TABLET_SMALL } from '../../../../static/constants'
import { Button, Select, Spinner } from '../../../atoms'
import FormItem from '../../../atoms/_layouts/FormItem'
import { Form } from '../../../molecules'

const TAXI_FIELDS = {
  taxi_id: 'taxi_id',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  handleFormSubmit(initialValues: any, values: any): void
}

const Taxi: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [taxies, setTaxies] = React.useState(null)
  const [errors, setErrors] = React.useState<IErrors>({})
  const handleUpdateForm = React.useCallback((updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }, [])
  const formatSelectOptions = options => {
    return options.map((option: { id: number; name: string }) => ({
      value: option.id,
      label: option.name,
    }))
  }

  const getTaxies = async () => {
    const { taxiList } = await postService.getTaxies()
    setTaxies([{ value: '', label: '-' }, ...formatSelectOptions(taxiList)])
  }
  React.useEffect(() => {
    getTaxies()
  }, [])
  return (
    <>
      {taxies === null ? (
        <S.Loading>
          <Spinner />
        </S.Loading>
      ) : (
        <Form
          fields={TAXI_FIELDS}
          handleUpdateForm={handleUpdateForm}
          handleSubmit={props.handleFormSubmit}
        >
          <ul>
            <S.FormItem>
              <Select
                required={true}
                name="taxi_id"
                label={I18n.t('generic.taxi')}
                options={taxies}
                defaultValue={props.post.taxi_id || taxies[0].value}
                error={errors.taxi}
              />
            </S.FormItem>
          </ul>
          <S.Footer className="Editor_Footer">
            <Button primary={true} disabled={!isSubmitEnabled}>
              {I18n.t('generic.update')}
            </Button>
          </S.Footer>
        </Form>
      )}
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`
S.Footer = styled.div`
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-top: 20px;
  }
`
S.Loading = styled.div`
  margin: 32px 0;
`

export default Taxi
