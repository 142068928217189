import React from 'react'
import ClickOutside from 'react-click-outside'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IUser } from '../../../core/interfaces'
import { userService } from '../../../core/services'
import { COLORS } from '../../../static/constants'

interface INotification {
  id: number
  title: string
  created_at: string
  read_at: string
  url: string
}

interface IProps {
  user: IUser
}

const Notifications: React.FC<IProps> = ({ user }) => {
  const [notifications, setNotifications] = React.useState<INotification[]>([])
  const [showNotifications, setShowNotifications] = React.useState(false)

  const getNotifications = async () => {
    const result = await userService.getNotifications()

    setNotifications(result.notifications)
    setShowNotifications(true)
  }

  return (
    <S.Notifications>
      <ClickOutside onClickOutside={() => setShowNotifications(false)}>
        <S.Icon onClick={getNotifications}>
          <i className="material-icons">notifications</i>
          {user.unread_notifications_count > 0 && <span>{user.unread_notifications_count}</span>}
        </S.Icon>
        {showNotifications && (
          <S.List>
            {notifications.length > 0 ? (
              notifications.map(notification => (
                <li key={notification.id}>
                  <a href={notification.url}>
                    {notification.title}
                    <br />
                    <span>{notification.created_at}</span>
                  </a>
                </li>
              ))
            ) : (
              <li>{I18n.t('notifications.no_notifications')}</li>
            )}
          </S.List>
        )}
      </ClickOutside>
    </S.Notifications>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Notifications = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`

S.Icon = styled.div`
  cursor: pointer;

  > i {
    display: block;
    width: inherit;
    height: inherit;
    color: ${COLORS.Border};
  }

  > span {
    display: block;
    text-align: center;
    height: 17px;
    padding: 0 5px;
    color: #fff;
    border-radius: 9px;
    background-color: ${COLORS.Danger};
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    position: absolute;
    right: -6px;
    top: -6px;
  }
`

S.List = styled.ul`
  position: absolute;
  bottom: 0;
  right: -8px;
  width: 320px;
  padding: 12px 0;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(21, 27, 38, 0.08);
  transform: translateY(100%);
  border-radius: 4px;

  > li {
    padding: 4px 16px;
    cursor: pointer;

    :hover {
      background-color: #f7f7f7;
    }

    > a {
      color: ${COLORS.Text};
      font-size: 14px;

      span {
        font-size: 12px;
        color: #999;
      }
    }
  }
`

export default Notifications
