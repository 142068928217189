import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import injectGoogleMaps from 'utils/injectGoogleMaps'
import I18n from '../../../../core/i18n'
import { IPost, IWindow } from '../../../../core/interfaces'
import { Button, InputText } from '../../../atoms'
import { Form } from '../../../molecules'
import AddressForm, { HOST_ADDRESS_FIELDS, emptyToNull } from '../../../organisms/AddressForm'

declare var window: IWindow

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  handleFormSubmit(props: any): void
}

export const Address: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const handleFormSubmit = (initialValues: any, values: any) => {
    if (!values.latitude1 || !values.longitude1) {
      window.flashMessages.addMessage({
        text: '住所が誤っている可能性があります。再入力してください。',
        type: 'error',
      })

      return
    }
    const newAddres = {}
    Object.keys(HOST_ADDRESS_FIELDS).forEach(key => {
      newAddres[key] = emptyToNull(key, values[key])
    })
    props.handleFormSubmit({
      address: { ...newAddres },
      post: {
        id: props.post.id,
      },
    })
  }
  return (
    <Form
      fields={HOST_ADDRESS_FIELDS}
      handleUpdateForm={handleUpdateForm}
      handleSubmit={handleFormSubmit}
    >
      <AddressForm defaultAddress={props.post.address} />
      <S.Footer>
        {/* <Button primary={true} disabled={mapProssesing || !isSubmitEnabled}> */}
        <Button primary={true} disabled={!isSubmitEnabled}>
          {I18n.t('generic.update')}
        </Button>
      </S.Footer>
    </Form>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Footer = styled.div`
  display: flex;
  margin-top: 24px;
`

S.FormItem = styled.div<{ hidden?: true }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`

S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.Map = styled.div`
  height: 300px;
  margin-top: 32px;

  #map {
    height: 100%;
    width: 100%;
  }
`
S.Loading = styled.div`
  margin: 32px 0;
`

export default injectGoogleMaps(Address)
