import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IReservation, IWindow } from '../../../../../core/interfaces'
import { reservationService } from '../../../../../core/services'
import { DAYS, HOURS, MINUTES } from '../../../../../static/constants'
import { Button, InputText, Select } from '../../../../atoms'
import { InputDateTime } from '../../../../molecules'
import { Form } from '../../../../molecules'

declare var window: IWindow

interface IDeliveryDate {
  DD: string
  HH: string
  mm: string
}
interface IProps {
  eventFlag: boolean
  newStatus: string
  reservationId: number
  deliveryTime: number
  specifiedDeliveryTime: string
  setReservation(reservaton: any): void
}

const FIELDS = { minutes: 'minutes' }

const ApproveModal: React.FC<IProps> = props => {
  const [newStatus, setNewStatus] = React.useState<string>(props.newStatus)
  const [modalErrors, setModalErrors] = React.useState<{ [key: string]: null | string }>({
    reason: null,
  })
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [submittable, setSubmitable] = React.useState(false)
  const [selectedPickUpTime, setSelectedPickUpTime] = React.useState<moment.Moment | null>(
    moment(props.specifiedDeliveryTime)
  )

  const handleSubmit = async (initialValues, value) => {
    let param = {
      reservation: {
        cooking_time: '',
      },
      delivery: {
        estimated_delivery_time_at: '',
      },
    }

    if (!props.eventFlag) {
      const { minutes } = value

      // 調理時間（分） + 配達目安時間（分）
      const resultMoment = moment(props.specifiedDeliveryTime).add(minutes, 'm')
      param = {
        reservation: {
          cooking_time: `${minutes}分`,
        },
        delivery: {
          estimated_delivery_time_at: resultMoment.format('YYYY-MM-DD HH:mm'),
        },
      }
    }

    setIsProcessing(true)
    if (newStatus === 'temporary_approved') {
      const { reservation, flush } = await reservationService.temporaryApproveReservation(
        props.reservationId,
        param
      )
      props.setReservation(reservation)
      setNewStatus('approved')
      window.globalModal.closeModal()
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    } else if (newStatus === 'approved') {
      const { reservation, flush } = await reservationService.approveReservation(
        props.reservationId
      )
      props.setReservation(reservation)
      window.globalModal.closeModal()
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    }
  }

  const getOptionsData = name => {
    switch (name) {
      case 'minutes':
        return MINUTES
    }
  }
  const formatOptions = name => {
    const optionsData = getOptionsData(name)
    return optionsData.map(data => {
      return { value: data, label: `${data}分後` }
    })
  }

  return (
    <S.ModalWrapper>
      {props.eventFlag && (
        <Form
          fields={undefined}
          handleUpdateForm={updatedErrors => {
            setModalErrors(updatedErrors)
          }}
          handleSubmit={handleSubmit}
        >
          <S.Footer>
            {!submittable && (
              <Button
                primary={false}
                disabled={isProcessing}
                handleClick={() => setSubmitable(true)}
              >
                {I18n.t('reservation.approved')}
              </Button>
            )}
            {submittable && (
              <>
                <p>承認してよろしいですか？</p>
                <Button primary={true} disabled={isProcessing}>
                  {I18n.t('reservation.approved')}
                </Button>
              </>
            )}
          </S.Footer>
        </Form>
      )}
      {!props.eventFlag && (
        <>
          {newStatus === 'temporary_approved' && (
            <div>{I18n.t('reservation.input_cooking_time')}</div>
          )}
          <Form
            fields={newStatus === 'temporary_approved' && FIELDS}
            handleUpdateForm={updatedErrors => {
              setModalErrors(updatedErrors)
            }}
            handleSubmit={handleSubmit}
          >
            {newStatus === 'temporary_approved' && (
              <Select
                required={true}
                name="minutes"
                options={formatOptions('minutes')}
                defaultValue={'20'}
              />
            )}
            <S.Footer>
              {!submittable && (
                <Button
                  primary={false}
                  disabled={isProcessing}
                  handleClick={() => setSubmitable(true)}
                >
                  {newStatus === 'approved' && I18n.t('reservation.approved')}
                  {newStatus === 'temporary_approved' && I18n.t('reservation.temporary_approve')}
                </Button>
              )}
              {submittable && (
                <>
                  <p>
                    {newStatus === 'temporary_approved' ? '事前承認' : '受注完了'}
                    してよろしいですか？
                  </p>
                  <Button primary={true} disabled={isProcessing}>
                    {newStatus === 'approved' && I18n.t('reservation.approved')}
                    {newStatus === 'temporary_approved' && I18n.t('reservation.temporary_approve')}
                  </Button>
                </>
              )}
            </S.Footer>
          </Form>
        </>
      )}
    </S.ModalWrapper>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.ModalWrapper = styled.div``

S.Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`
export default ApproveModal
