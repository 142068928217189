import * as Color from 'color'
import I18n from 'core/i18n'
import moment from 'moment'
import React from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import { Button } from '../atoms'
import { Form } from '../molecules'

const SearchPanel: React.FC<{}> = props => {
  const [inputText, setInputText] = React.useState<string>('')
  const [focusedInput, setFocusedInput] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState({
    start: null,
    end: null,
  })

  const handleCalendarOutSideRange = React.useCallback(calendarMoment => {
    if (calendarMoment.isBefore(moment())) {
      return true
    }
  }, [])
  const onDateRangePickerChange = React.useCallback(
    (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      setSelectedDate({
        start: dates.startDate,
        end: dates.endDate,
      })
    },
    []
  )

  const handleClickKeywords = () => {
    location.href = `/posts/search?keywords=${inputText}`
  }

  return (
    <SearchPanelWrapper className="SearchPanel">
      <h1>{I18n.t('search_pan.title')}</h1>
      <div className="Form">
        <div className="SearchPanel_FormItem">
          <label htmlFor="keywords">{I18n.t('generic.keywords')}</label>
          <input
            className="SearchPanel_AreaInput"
            type="text"
            name="keywords"
            placeholder={I18n.t('search_pan.name_or_title')}
            onChange={event => {
              setInputText(event.currentTarget.value)
            }}
          />
        </div>
        {/* <div className="Date SearchPanel_FormItem">
          <div className="SearchPanel_DateRangeLabels">
            <label htmlFor="startDate">{I18n.t('generic.check_in')}</label>
            <label htmlFor="endDate">{I18n.t('generic.check_out')}</label>
          </div>
          <DateRangePicker
            startDatePlaceholderText={'yyyy/mm/dd'}
            startDate={selectedDate.start}
            startDateId="startDate"
            endDatePlaceholderText={'yyyy/mm/dd'}
            endDate={selectedDate.end}
            endDateId="endDate"
            onDatesChange={onDateRangePickerChange}
            focusedInput={focusedInput}
            onFocusChange={(currentFocusedInput: string | null) =>
              setFocusedInput(currentFocusedInput)
            }
            enableOutsideDays={false}
            isOutsideRange={handleCalendarOutSideRange}
            // isOutsideRange={() => false}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
          />
        </div> */}
        {/*
        <Button
          primary={true}
          backgroundColor={'#FC9803'}
          handleClick={handleClickKeywords}
          hoverColor={'#FABA48'}
        >
          {I18n.t('search_pan.search_cuisine')}
        </Button>
        */}
        <div className="mapSearchButton">
          <Button hoverColor={COLORS.Border}>
            <a href="/posts/search?area=%E6%9C%AD%E5%B9%8C">{I18n.t('search_pan.search_map')}</a>
          </Button>
        </div>
      </div>
    </SearchPanelWrapper>
  )
}

const themeColor =
  getComputedStyle(document.documentElement)
    .getPropertyValue(`${THEME_COLOR_VARIABLE_NAME}`)
    .trim() || '#A7813F'

const SearchPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 12px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    max-width: none;
    width: 100%;
  }

  h1 {
    font-size: 40px;
    text-align: center;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      font-size: 26px;
    }
  }

  label {
    display: none;
    font-size: 14px;
    margin-bottom: 6px;
  }

   .Date {
       display: none;
   }
   .Form {
        width: 100%;
        display: flex;
        justify-content: center;
        @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        flex-wrap: wrap;
        }
   }
  }
  .SearchPanel_FormItem {
    width: 100%;
    margin: 24px 10px 0;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin: 24px 0 0;
    }
    display: none;
  }

  .SearchPanel_AreaInput {
    width: 100%;
    height: 48px;
    border: solid 1px ${COLORS.Border};
    padding: 12px;
    font-size: 18px;
    &:focus {
      border: solid 1px ${themeColor};
    }
  }

  .SearchPanel_DateRangeLabels {
    display: flex;

    > label {
      width: 50%;
    }
  }

  .Button {
    white-space: nowrap;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin: 24px 10px 0;
    padding: 0 15px;
    border-radius: 0;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      padding: 0;
      width: 180px;
    }
  }

  .mapSearchButton {
    button {
      border-radius: 6px;
      border-width: 2px;
      padding: 0;
      a {
        padding: 0 50px;
      }
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        padding: 0;
        width: 240px;
      }
    }
  }
`

const DateRangePickerCustomStyle = createGlobalStyle`
  .DateRangePicker.DateRangePicker_1 {
    width: 100%;
  }

  .DateRangePickerInput.DateRangePickerInput_1 {
    width: 100%;
    border: none;
  }

  .DateInput.DateInput_1 {
    width: 50%;
  }

  .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
    display: none;
  }

  input#startDate {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: solid 1px ${COLORS.Border};
  }

  input#endDate {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: solid 1px ${COLORS.Border};
  }

  input#startDate,
  input#endDate {
    &.DateInput_input__focused {
      border: solid 1px ${themeColor};
    }
  }

  /* .DateInput_input__focused {
    border-color: ${themeColor};
  } */

  .CalendarDay__selected_span {
    background: ${Color(themeColor)
      .lighten(0.25)
      .hex()};
    border: 1px double
      ${Color(themeColor)
        .lighten(0.15)
        .hex()};
    color: #fff;
  }

  .CalendarDay__selected {
    background: ${themeColor};
    border: 1px double ${themeColor};
    color: white;
  }

  .CalendarDay__selected:hover {
    background: ${themeColor};
    color: white;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${Color(themeColor)
      .lighten(0.25)
      .hex()};
    border: 1px double
      ${Color(themeColor)
        .lighten(0.15)
        .hex()};
    color: #fff;
  }
`

export default SearchPanel
