import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPayments } from '../../../../core/interfaces'
import { payments } from '../../../../fixtures'
import { IconCard } from '../../../../icon'
import * as constants from '../../../../static/constants'
import { Button, Panel } from '../../../atoms'
import { EditTable } from '../../../organisms'
import SettingLayoutIndex from '../../settings/_layouts'
import { ActiveTypes } from '../../settings/Nav'

interface IProps {
  payments: IPayments[]
}
const COLUMNS = [
  {
    name: I18n.t('payment_history.day'),
    field: 'paid_date',
  },
  {
    name: I18n.t('payment_history.method'),
    field: record => (
      <S.Method>
        <IconCard />
        <span>{record.card}</span>
      </S.Method>
    ),
  },
  {
    name: I18n.t('payment_history.price'),
    field: record => <span>¥{record.amount}</span>,
  },
  {
    name: I18n.t('generic.receipt'),
    field: record => (
      <Button>
        <a href={record.id}>{I18n.t('generic.download')}</a>
      </Button>
    ),
  },
]

const PaymentHistory: React.FC<IProps> = (props = { payments }) => {
  return (
    <SettingLayoutIndex
      // TODO:ルーティング設定してもらったら変数にactive={this.props.active_path}
      activePath="payment_history"
      main={
        <S.Main>
          <Panel title={I18n.t('payment_history.history')}>
            {/* TODO: propsに値が渡ってくるようになったらrecords={props.payments}に変更 */}
            <EditTable editable={false} columns={COLUMNS} records={payments} isFixed={true} />
          </Panel>
        </S.Main>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Main = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table td,
  table th {
    border-width: 1px 0px; /* 上下だけ引く */
  }
`
S.Method = styled.p`
  align-items: center;
  display: flex;
  svg {
    width: 24px;
    margin-right: 12px;
    object-fit: cover;
  }
`
export default PaymentHistory
