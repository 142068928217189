import axios from 'axios'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { IPost } from '../../core/interfaces'
import { postService } from '../../core/services'
import { BREAKPOINT_TABLET_LARGE, BREAKPOINT_TABLET_MOBILE, COLORS } from '../../static/constants'
import SliderMenu from '../molecules/SliderMenu'

const TopNewPosts: React.FC<{}> = props => {
  const [posts, setPosts] = React.useState<IPost[]>(null)

  const getData = async () => {
    const { posts: newPosts } = await postService.getNewPosts()
    setPosts(newPosts)
  }

  React.useEffect(() => {
    getData()
  }, [])
  const isPublished = (state: 'published' | 'closed' | 'suspended' | 'draft') => {
    return state === 'published'
  }
  const TIME_FORMAT = new RegExp('^([01]?[0-9]|2[0-4]):([0-5][0-9])$')
  const checkTimeValue = (start, end) => {
    return start && end && TIME_FORMAT.test(start) && TIME_FORMAT.test(end)
  }
  return (
    <S.NewPosts>
      {posts?.length > 0 && (
        <SliderMenu title={I18n.t('meta.post.recent_index')} link={0}>
          {posts.slice(0, 10).map(
            post =>
              post.is_open && (
                <S.ItemWrapper
                  key={post.id}
                  className={(!post.is_open || !isPublished(post.aasm_state)) && 'disabled'}
                >
                  <S.Item href={`/posts/${post.slug}`} title={post.name}>
                    <S.ItemContainer>
                      <S.Image>
                        {post.post_image && post.post_image.image_url ? (
                          <img src={post.post_image.image_url} alt="" />
                        ) : (
                          <S.NoImage>No Image</S.NoImage>
                        )}
                      </S.Image>
                      <S.Info>
                        <S.Name>{post.name}</S.Name>
                        {!post.is_open && <S.State>受付時間外です</S.State>}
                        {post.is_open && !isPublished(post.aasm_state) && (
                          <S.State>受付停止中です</S.State>
                        )}
                        {/*}
                    <S.Tags>
                      {post.tags.map((tag, tagIndex) => (
                        <S.Tag key={tagIndex}>{tag.name} </S.Tag>
                      ))}
                    </S.Tags>
                    */}
                        {post.is_open && isPublished(post.aasm_state) && (
                          <span className="hours">
                            <S.Times>
                              {checkTimeValue(post.start_time1, post.end_time1) && (
                                <S.OpenTime>
                                  <i className="material-icons">store_mall_directory</i>
                                  {post.start_time1} - {post.end_time1}
                                </S.OpenTime>
                              )}
                              {checkTimeValue(post.start_time2, post.end_time2) && (
                                <S.OpenTime>
                                  <i className="material-icons">navigate_next</i>
                                  {post.start_time2} - {post.end_time2}
                                </S.OpenTime>
                              )}
                              {checkTimeValue(post.start_time3, post.end_time3) && (
                                <S.OpenTime>
                                  <i className="material-icons">navigate_next</i>
                                  {post.start_time3} - {post.end_time3}
                                </S.OpenTime>
                              )}
                            </S.Times>
                          </span>
                        )}
                      </S.Info>
                    </S.ItemContainer>
                  </S.Item>
                </S.ItemWrapper>
              )
          )}
        </SliderMenu>
      )}
    </S.NewPosts>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.NewPosts = styled.div`
  width: 100%;
  max-width: 1360px;
  overflow: hidden;
  margin: auto;
  `

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`

S.ItemWrapper = styled.div`
  position: relative;
  height: 240px;
  max-width: 320px;

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    max-width: 160px;
    height: 180px;
  }

  :hover {
    opacity: 0.8;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`
S.Item = styled.a`
  color: ${COLORS.Text};
  overflow: hidden;

  @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-top: 16px;
  }
`
S.ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding: 0;
  }
`
S.NoImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
`

S.Name = styled.p`
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 16px;
  font-weight: normal;
  margin: 8px 0;
  white-space: pre-wrap;

  @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 12px;
    margin-top: 0;
  }
`
S.State = styled.p`
  font-size: 12px;
`
S.Image = styled.div`  
  height: 138px
  overflow: hidden;
  z-index: -1;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`

S.Info = styled.div`
  width: 100%;
  min-height: 72px;
  line-height: 1em;
  background-color: #fff;
  padding: 4px 4px 4px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.15);
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding-left: 16px;
    flex: 1;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding-left: 4px;
    min-height: 64px;
  }
  .hours {
    font-size: 0.8rem;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 0.6rem;
    }
  }
`
S.Tags = styled.div`
  /* display: flex; */
  overflow: hidden;
  text-overflow: ellipsis;
`

S.Tag = styled.span`
  margin-top: 8px;
  color: #7c7c7c;
  font-size: 12px;
`
S.Times = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    display: inline-flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin: 8px 0;
    }
  }
`
S.OpenTime = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin: 0;
  }
  i {
    font-size: 17px;
    color: #fb585c;
  }
`
export default TopNewPosts
