import * as moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { postService } from '../../../../core/services'
import {
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../static/constants'
import { Button } from '../../../atoms'
import { Form } from '../../../molecules'

const REVIEW_REPLY_FIELDS = {
  body: 'body',
}

interface IProps {
  review: any
  post: any
  updateReviews(review: any): void
}

const ReviewReply: React.FC<IProps> = props => {
  const [edit, setEdit] = React.useState(false)
  const textareaRef = React.useRef(null)
  const handleSubmit = async (initialValues, values) => {
    const { reviewReply } = await postService.createReviewReply({
      review_id: props.review.id,
      body: values.body,
    })

    props.updateReviews({
      ...props.review,
      review_replies: [
        {
          body: reviewReply.body,
          id: reviewReply.id,
          created_at: moment().format('YYYY/MM/DD'),
        },
      ],
    })
  }

  React.useEffect(() => {
    if (textareaRef.current !== null) {
      textareaRef.current.focus()
    }
  }, [edit])

  return (
    <S.ReviewReply>
      {edit ? (
        <S.Editor>
          <S.Avatar>
            <S.AvatarImage>
              <img src={props.post.user.avatar_url} alt="" />
            </S.AvatarImage>
            <S.AvatarInfo>
              <h4>{props.post.user.username}</h4>
            </S.AvatarInfo>
          </S.Avatar>
          <Form fields={REVIEW_REPLY_FIELDS} handleSubmit={handleSubmit}>
            <textarea ref={textareaRef} name="body" placeholder="返信を入力" />
            <S.Footer>
              <Button primary={true}>{I18n.t('generic.post')}</Button>
              <Button handleClick={() => setEdit(false)}>{I18n.t('generic.cancel')}</Button>
            </S.Footer>
          </Form>
        </S.Editor>
      ) : (
        <Button handleClick={() => setEdit(true)}>{I18n.t('generic.reply')}</Button>
      )}
    </S.ReviewReply>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.ReviewReply = styled.div`
  margin-top: 24px;

  .Form {
    margin-top: 12px;
  }

  textarea {
    display: block;
    width: 100%;
    height: 160px;
    padding: 8px 12px;
    border: solid 1px ${COLORS.Border};
    border-radius: 4px;
    font-size: 15px;
    transition: border 0.2s ease;
    outline: none;
    background: none;

    &:focus {
      border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
    }
  }
`

S.Editor = styled.div`
  margin-left: 48px;
`

S.Footer = styled.div`
  margin-top: 12px;
`

S.Avatar = styled.div`
  display: flex;
  align-items: center;
`

S.AvatarImage = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 24px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

S.AvatarInfo = styled.div`
  flex: 1;

  h4 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  > span {
    display: block;
  }
`

export default ReviewReply
