import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { BREAKPOINT_TABLET_SMALL, COLORS } from '../../../static/constants'

interface IProps {
  label: string
  required?: boolean
  error?: null | string
}

const FormItem: React.FC<IProps> = ({ label, required, error, children }) => {
  return (
    <S.FormItem>
      {(label || required) && (
        <S.Title className="FormLabel">
          {label}
          {required && <S.Required>{I18n.t('generic.required')}</S.Required>}
        </S.Title>
      )}
      <S.Main>
        {children}
        {error && <S.Error>{error}</S.Error>}
      </S.Main>
    </S.FormItem>
  )
}

export const S: { [key: string]: AnyStyledComponent } = {}

S.FormItem = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }
`

S.Title = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`

S.Main = styled.div`
  flex: 1;
`

S.Required = styled.span`
  display: inline-block;
  border-radius: 3px;
  color: #fff;
  background-color: ${COLORS.Danger};
  vertical-align: middle;
  font-size: 11px;
  font-weight: normal;
  padding: 4px 6px;
  margin-left: 8px;
`

S.Error = styled.span`
  display: block;
  margin-top: 8px;
  color: ${COLORS.Danger};
  font-size: 14px;
`

export default FormItem
