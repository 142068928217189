import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { adminService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { CheckBox, InputText, Select, Spacer } from '../../../atoms'
import AdminLayoutNew from '../_layouts/new'

const FIELDS = {
  username: 'username',
  email: 'email',
  password: 'password',
  // email_notification: 'email_notification',
  role_id: 'role_id',
  phone: 'phone',
}

const title = I18n.t('admin.create_new', {
  model: I18n.t('user', { scope: 'activerecord.models' }),
})

const AdminUserIndex: React.FC<{}> = props => {
  const [userType, setUserType] = React.useState<string>(String(constants.ROLES[0].value))
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const params: {
      user
      user_role
      address?
    } = {
      user: {
        email: values.email,
        username: values.username,
        password: values.password,
      },
      user_role: {
        role_id: values.role_id,
      },
    }
    if (values.role_id === String(constants.ROLES[0].value)) {
      params.address = {
        phone: values.phone,
      }
    }
    await adminService.createUser(params)
    location.href = '/admin/users'
  }, [])

  return (
    <AdminLayoutNew
      model="user"
      title={title}
      fields={FIELDS}
      indexLink="/admin/users"
      formItems={
        <>
          <InputText
            required={true}
            name="username"
            defaultValue=""
            label={I18n.t('generic.username')}
          />
          <Spacer />
          <InputText required={true} name="email" defaultValue="" label={I18n.t('generic.email')} />
          <Spacer />
          <InputText
            required={true}
            name="password"
            defaultValue=""
            label={I18n.t('generic.password')}
          />
          <Spacer />
          <S.FormComment>
            <p>英文字、数字、記号の内2種類以上</p>
            <p>8文字以上32文字以内で作成して下さい</p>
          </S.FormComment>
          <Spacer />
          <Select
            required={true}
            name="role_id"
            label={I18n.t('admin.type_of_user')}
            options={constants.ROLES}
            defaultValue={String(constants.ROLES[0].value)}
            onChangeHandler={event => {
              setUserType(event.currentTarget.value)
            }}
          />
          <Spacer />
          {/* <CheckBox
            name="email_notification"
            defaultChecked={false}
            label={I18n.t('admin.email_notice')}
          /> */}
          <S.Address isGuest={userType === String(constants.ROLES[0].value)}>
            <InputText
              required={userType === String(constants.ROLES[0].value)}
              name="phone"
              defaultValue={''}
              label={I18n.t('generic.phone')}
            />
          </S.Address>
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Address = styled.div<{ isGuest: boolean }>`
  display: ${({ isGuest }) => (isGuest ? 'block' : 'none')};
`
S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`

export default AdminUserIndex
