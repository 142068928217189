import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'

interface IProps {
  name: string
  defaultChecked: boolean
  label?: string
  showLabelInline?: boolean
  value?: number | string
  readonly?: boolean
  onChangeHandler?(event: any): void
}

const CheckBox: React.FC<IProps> = ({
  name,
  defaultChecked,
  label,
  showLabelInline,
  onChangeHandler,
  value = 'on',
  readonly,
}) => {
  return (
    <S.CheckBox className="CheckBox">
      {label && !showLabelInline && <S.Title>{label}</S.Title>}
      <S.Main>
        <label>
          <input
            type="checkbox"
            value={value}
            name={name}
            defaultChecked={defaultChecked}
            onChange={event => {
              if (typeof onChangeHandler === 'function') {
                onChangeHandler(event)
              }
            }}
            disabled={readonly}
          />
          <S.Icon className="CheckBoxIcon">
            <i className={`material-icons filled ${readonly ? 'disable' : ''}`}>check_box</i>
            <i className="material-icons outline">check_box_outline_blank</i>
          </S.Icon>
          {showLabelInline && <span>{label}</span>}
        </label>
      </S.Main>
    </S.CheckBox>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.CheckBox = styled.div`
  display: flex;
`

S.Title = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
`

S.Main = styled.div`
  flex: 1;

  > label {
    display: block;
    position: relative;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    input:checked + .CheckBoxIcon {
      i {
        &.filled {
          display: block;
        }

        &.outline {
          display: none;
        }
      }
    }

    span {
      font-size: 14px;
      padding-left: 28px;
    }
  }

  > label + label {
    margin-top: 16px;
  }
`

S.Icon = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 24px;
  height: 24px;

  i {
    position: absolute;
    top: 0;
    left: 0;

    &.filled {
      display: none;
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }

    &.disable {
      color: #eee;
    }

    &.outline {
      display: block;
      color: ${COLORS.Border};
    }
  }
`

export default CheckBox
