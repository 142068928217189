import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { BREAKPOINT_TABLET_LARGE, HEADER_HEIGHT } from '../../../../static/constants'
import { Panel } from '../../../atoms'
import Nav, { ActiveTypes } from '../Nav'

interface IProps {
  model: ActiveTypes
  main: React.ReactElement
  heading?: string | React.ReactElement
}

const LayoutIndex: React.FC<IProps> = ({ model, heading, main }) => (
  <S.LayoutIndex>
    <Nav active={model} />
    <S.Main>
      <Panel title={heading} fit={true}>
        {main}
      </Panel>
    </S.Main>
  </S.LayoutIndex>
)

const S: { [key: string]: AnyStyledComponent } = {}

S.LayoutIndex = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`

S.Main = styled.section`
  flex: 1;
  padding: 16px;
`

export default LayoutIndex
