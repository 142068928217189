import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { InputText, Spacer } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  reservation: IJsonResponse
}

const FIELDS = {
  id: 'id',
  user_id: 'user_id',
  post_id: 'post_id',
  start_date: 'start_date',
  end_date: 'end_date',
  workflow_state: 'workflow_state',
  price: 'price',
  paid_at: 'paid_at',
  canceled_at: 'canceled_at',
  updated_at: 'updated_at',
  refund_amount: 'refund_amount',
  authorized_at: 'authorized_at',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('reservation', { scope: 'activerecord.models' }),
})

const AdminReservationEdit: React.FC<IProps> = props => {
  const { data } = adminService.getDataFromJson(props.reservation)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    // const response = await api.updateReservationByAdmin(values)
    // location.href = '/admin/reservations'
  }, [])

  return (
    <AdminLayoutEdit
      model="reservation"
      title={title}
      fields={FIELDS}
      indexLink="/admin/reservations"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={data.id} label="ID" />
          <Spacer />
          <InputText name="user_id" defaultValue={data.user_id} label="User id" />
          <Spacer />
          <InputText name="post_id" defaultValue={data.post_id} label="Post id" />
          <Spacer />
          <InputText name="start_date" defaultValue={data.start_date} label="Start date" />
          <Spacer />
          <InputText name="end_date" defaultValue={data.end_date} label="End date" />
          <Spacer />
          <InputText
            name="workflow_state"
            defaultValue={data.workflow_state}
            label="Workflow state"
          />
          <Spacer />
          <InputText name="price" defaultValue={data.price} label="Price" />
          <Spacer />
          <InputText name="paid_at" defaultValue={data.paid_at} label="Paid at" />
          <Spacer />
          <InputText name="canceled_at" defaultValue={data.canceled_at} label="Canceled at" />
          <Spacer />
          <InputText name="updated_at" defaultValue={data.updated_at} label="Updated at" />
          <Spacer />
          <InputText name="refund_amount" defaultValue={data.refund_amount} label="Refund amount" />
          <Spacer />
          <InputText name="authorized_at" defaultValue={data.authorized_at} label="Authorized at" />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminReservationEdit
