import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { formatPrice } from 'utils/number'
import I18n from '../../../../core/i18n'
import { IUser } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { reservationService } from '../../../../core/services'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { Button } from '../../../atoms'
import { getDeliveryState } from '../index'

export interface IMessage {
  body: string
  conversationId: number
  created_at: string
  file_attached: boolean
  file_url: null | string
  formatted_sent_time: string
  id: number
  sender: IUser
  sent_at: string
  updated_at: string
  other?: boolean
}

interface IProps {
  user: IJsonResponse
  reservation: IJsonResponse
  receipt_id: number
  post_image: any
}

const ShowDetail: React.FC<IProps> = props => {
  // const { data: user } = reservationService.getDataFromJson(props.user)
  const { data: reservation } = reservationService.getDataFromJson(props.reservation)
  const { data: postImage } = reservationService.getDataFromJson(props.post_image)
  const post = reservation.post
  const reservationItems = reservation.reservation_items

  return (
    <S.Main>
      <h2>注文履歴詳細</h2>
      <div className="Panel">
        <div className="˝Reservation_Post">
          {postImage && (
            <a href={'/posts/' + post.slug}>
              <div className="Reservation_Image">
                <img src={postImage.image_url} alt="" />
              </div>
            </a>
          )}
          <div className="Reservation_PostInfo">
            <h3 className="Reservation_PostTitle">{post.name}</h3>
          </div>
        </div>
        <div className="Panel_Section">
          <dt>注文状況</dt>
          <dd>{reservation.workflow_state_i18n}</dd>
          <dt>注文日</dt>
          <dd>{reservation.created_at}</dd>
          {post.event_flag && <dt>お届け希望時間</dt>}
          {post.event_flag && <dd>{reservation.specified_delivery_time_at}</dd>}
          <dt>配達先</dt>
          <dd>
            {`${reservation.address?.google_full_address} ${reservation.address?.other1_2}`}
            {/* {reservation.address?.postal_code1 && (
              <span>〒{reservation.address?.postal_code1}</span>
            )}
            <span> {reservation.address?.state1 || ''}</span>
            <span> {reservation.address?.city1 || ''}</span>
            <span> {reservation.address?.other1_1 || ''}</span>
            <span> {reservation.address?.other1_2 || ''}</span> */}
          </dd>
        </div>
        <div className="Panel_Section">
          <h3>{I18n.t('reservation.reservation_detail')}</h3>
          <table className="Reservation_Table">
            <thead>
              <tr>
                <th>商品</th>
                <th>個数</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {reservationItems.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.post_item && item.post_item.name}</td>
                  <td>{item.quantity}</td>
                  <td>¥{item.price}</td>
                </tr>
              ))}
            </tbody>
            <tbody>
              <tr>
                <td />
                <td>{I18n.t('price.subtotal')}</td>
                <td>¥{reservation.price}</td>
              </tr>
              <tr>
                <td />
                <td>{I18n.t('price.taxi_fare')}</td>
                <td>¥{reservation.actual_taxi_fare}</td>
              </tr>
              {reservation.used_campaign && (
                <tr>
                  <td />
                  <td>{reservation.campaign_name}</td>
                  <td>-¥{reservation.used_campaign}</td>
                </tr>
              )}
              {reservation.coupons.length > 0 && (
                <tr>
                  <td />
                  <td>クーポン</td>
                  {reservation.coupons.map((coupon, index) => (
                    <tr key={index}>
                      <td>-¥{coupon.value}</td>
                      <td>(コード / {coupon.code})</td>
                    </tr>
                  ))}
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td>{I18n.t('price.total')}</td>
                <td>¥{reservation.total_price}</td>
              </tr>
            </tfoot>
          </table>
          {reservation.remarks && (
            <div className="Panel_Section">
              <h4>{I18n.t('generic.note')}</h4>
              <p className="reservationRemark">{reservation.remarks}</p>
            </div>
          )}
          {post.address && (
            <div className="Panel_Section">
              <h4>{I18n.t('reservation.remarks_notes')}</h4>
              <p>
                注文確定後すぐにお届け準備が開始されますので、商品が食品である事から、ご注文後の変更・キャンセルは原則お受けしておりません。
                ※詳細は特定商取引に基づく表記をご確認ください。
              </p>
              {/* <p>
                基本的にご注文後の変更・キャンセルはお承りしておりませんが、発送準備前でしたら、ご注文のキャンセル、内容の変更をお受けできる場合がございます。店舗の電話番号まで、お電話にてご連絡ください。
              </p>
              <p>
                <i className="material-icons">phone</i> {post.address.phone}
              </p> */}
            </div>
          )}
        </div>
        <div className="Panel_Section">
          <Button small={true}>
            <a href={`/reservations/`}>注文履歴一覧へ</a>
          </Button>
        </div>
      </div>
    </S.Main>
  )
}
const Show: React.FC<IProps> = props => {
  const WithGoogleMap = injectGoogleMaps(ShowDetail)

  // console.log('Conversation/show props', props)
  return <WithGoogleMap {...props} />
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Main = styled.div`
  margin: 0 auto;
  max-width: 880px;

  h2 {
    margin: 16px;
  }
  .Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    padding: 24px;
  }
  .Panel_Section {
    margin-top: 24px;
    padding-top: 16px;
    border-top: solid 1px #eaedef;

    > h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin-top: 4px;
    }

    dd + dt {
      margin-top: 12px;
    }
    .reservationRemark {
      font-weight: bold;
      color: red;
    }
  }

  .Reservation_Post {
    display: flex;
    align-items: center;
  }

  .Reservation_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .Reservation_PostInfo {
    flex: 1;
  }

  .Reservation_PostTitle {
    font-size: 20px;
  }

  .Reservation_Table {
    width: 100%;
    max-width: 600px;
    font-size: 15px;
    tr {
      height: 40px;
      td {
        min-width: 100px;
      }
    }
    tbody {
      border-bottom: 1px solid #eaedef;
    }
  }
`

export default Show
