import * as React from 'react'

const SvgTodoxiPin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={92} height={92} fill="none" {...props}>
    <path
      d="M63.25 18.476c0 6.841-6.996 12.512-9.973 15.452C51.66 35.525 46 46.718 46 46.718s-5.672-11.204-7.277-12.79c-2.977-2.94-9.973-8.61-9.973-15.452 0-9.41 7.723-17.038 17.25-17.038 9.527 0 17.25 7.628 17.25 17.038z"
      fill="#A4732A"
    />
    <path
      d="M46.467 9.643c0 .578.007 1.148 0 1.725-.006.494-.295.84-.687.848-.392.008-.702-.339-.709-.832a150.354 150.354 0 010-3.528c.007-.517.323-.87.73-.856.391.024.666.37.666.871v1.772zM43.53 11.291c0 .447-.13.686-.392.825-.268.146-.543.108-.763-.124-.392-.416-.77-.84-1.149-1.263-.378-.424-.763-.847-1.135-1.279-.323-.37-.33-.855-.034-1.17.296-.317.688-.293 1.018.069l2.187 2.426c.158.177.268.37.268.516zM52.156 8.865a.801.801 0 01-.227.57c-.75.84-1.5 1.687-2.256 2.519-.303.331-.709.331-.99.023a.833.833 0 010-1.11 195.22 195.22 0 012.31-2.58c.214-.239.482-.3.757-.154.275.139.413.385.406.732zM54 19.72a5.03 5.03 0 00-.448-2.111c-.488-1.079-1.004-2.15-1.5-3.22-.412-.894-1.059-1.318-1.953-1.318H46c-1.389 0-2.785-.008-4.174 0-.805.008-1.431.4-1.816 1.194-.496 1.025-.963 2.065-1.452 3.097a5.821 5.821 0 00-.557 2.58c.007.933 0 1.865 0 2.797 0 1.017.392 1.764 1.21 2.211.07.039.097.085.104.17.11 1.047.867 1.818 1.837 1.872.873.046 1.698-.67 1.891-1.657.027-.146.076-.177.2-.177 1.864.008 3.728.008 5.592 0 .123 0 .172.031.206.177.22 1.025 1.032 1.718 1.933 1.649.97-.07 1.699-.848 1.802-1.934.007-.084.041-.123.103-.17.715-.454 1.094-1.14 1.1-2.064.028-1.032.021-2.064.021-3.097z"
      fill="#fff"
    />
    <path
      d="M45.394 20.52c0-1.147-.007-2.303.007-3.45 0-.232-.062-.286-.254-.27-.282.015-1.94 0-2.222.007-.124.008-.186-.03-.179-.184.014-.27.007-.54 0-.81-.007-.13.041-.169.151-.169 1.115.008 4.987.008 6.102 0 .103 0 .144.03.137.154-.007.285-.007.57 0 .855 0 .123-.041.154-.144.154-.296-.007-1.967.016-2.256-.007-.22-.024-.255.077-.255.292.007 2.296 0 4.207.007 6.502 0 .208-.041.285-.234.27a4.022 4.022 0 00-.68 0c-.152.008-.2-.046-.2-.223.02-1.171.02-1.95.02-3.12z"
      fill="#A4732A"
    />
  </svg>
)

export default SvgTodoxiPin
