import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost, IWindow } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import { Button, Select } from '../../../atoms'
import { Form } from '../../../molecules'
import { IState } from './index'
declare var window: IWindow

interface IProps {
  post: IPost
  currentStatus: string
  setPublishState: (IState) => void
  statuses?: IState[]
  setStatuses?(statuses: IState[]): void
}

const StatusModal: React.FC<IProps> = props => {
  const [modalErrors, setModalErrors] = React.useState<{ [key: string]: null | string }>({
    reason: null,
  })
  const [isProcessing, setIsProcessing] = React.useState(false)

  const isDraft = props.currentStatus === 'draft'
  const [isSubmitable, setIsSubmitable] = React.useState(isDraft)

  const initValue = isDraft ? 'published' : props.currentStatus
  const options = [
    {
      label: I18n.t('enums.post.aasm_state.published'),
      value: 'published',
    },
    {
      label: I18n.t('enums.post.aasm_state.suspended'),
      value: 'suspended',
    },
    {
      label: I18n.t('enums.post.aasm_state.closed'),
      value: 'closed',
    },
  ]

  const onChangeHandle = e => {
    const changed =
      options.findIndex(option => option.value === e.target.value) !==
      options.findIndex(option => option.value === props.currentStatus)
    setIsSubmitable(isDraft || changed)
  }
  const handleSubmit = async (initialValues, value) => {
    setIsProcessing(true)
    const { flush, post } = await postService.changeStateFromNewState(props.post, value.status)
    const { post: parsedPost } = postService.getPostFromJson(post)
    const updatedStatus = {
      id: parsedPost.id,
      label: parsedPost.aasm_state_i18n,
      value: parsedPost.aasm_state,
    }
    props.setPublishState(updatedStatus)

    // For list only
    const statuses = props.statuses
    if (statuses !== undefined) {
      const updatedStatuses = statuses.map(status =>
        status.id === parsedPost.id ? updatedStatus : status
      )
      props.setStatuses(updatedStatuses)
    }

    window.globalModal.closeModal()
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }

  return (
    <Form
      fields={{ status: 'status' }}
      handleUpdateForm={updatedErrors => {
        setModalErrors(updatedErrors)
      }}
      handleSubmit={handleSubmit}
    >
      <Select
        required={true}
        name="status"
        options={options}
        label={I18n.t('generic.status')}
        defaultValue={initValue}
        onChangeHandler={onChangeHandle}
      />
      <S.Footer>
        <Button primary={true} disabled={isProcessing || !isSubmitable}>
          {I18n.t('generic.update')}
        </Button>
      </S.Footer>
    </Form>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}

S.Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`
export default StatusModal
