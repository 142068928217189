import Color from 'color'
import React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { LogoFacebook, LogoGoogle, LogoTwitter } from '../../icon/index'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'

const SocialLoginButtons: React.FC = props => {
  return (
    <SocialLoginButtonsWrapper>
      {/* <a className="_google" href="/users/auth/google_aouth2">
        <img src="/images/logo_google.svg" alt="" />
        <span>Sign in with Google</span>
      </a> */}
      <p>{I18n.t('session.login_with_another_services')}</p>
      <a className="_facebook" href="/users/auth/facebook">
        {/* <img src="/images/logo_facebook.svg" alt="" /> */}
        <LogoFacebook />
        <span>Facebook{I18n.t('session.login_with')}</span>
      </a>
      <a className="_google" href="/users/auth/google">
        <LogoGoogle />
        <span>Google{I18n.t('session.login_with')}</span>
      </a>
      <a className="_twitter" href="/users/auth/twitter">
        <LogoTwitter />
        <span>Twitter{I18n.t('session.login_with')}</span>
      </a>
    </SocialLoginButtonsWrapper>
  )
}

const COLOR_FACEBOOK = '#1877F2'
const COLOR_TWITTER = '#1DA1F2'

const SocialLoginButtonsWrapper = styled.div`
  max-width: 360px;
  margin: 24px auto 0;

  p {
    text-align: center;
    margin-bottom: 21px;
  }

  a {
    display: flex;
    align-items: center;
    height: 50px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    border: 1px solid var(${THEME_COLOR_VARIABLE_NAME});
    border-radius: 4px;
    text-decoration: none;
    overflow: hidden;
    color: ${COLORS.Text};
    font-size: 16px;
    transition: all 0.1s;

    &:hover {
      box-shadow: 0 1px 3px 2px rgba(21, 27, 38, 0.15);
    }
    &._google {
      > svg {
        width: 20px;
      }
    }
    &._myrate {
      > svg {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
    > svg {
      width: 24px;
      margin-left: 16px;
    }

    > span {
      margin-left: 12px;
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }
  }

  a + a {
    margin-top: 12px;
  }
`

export default SocialLoginButtons
