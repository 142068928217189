import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class PostService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getPostFromJson(data) {
    const post = this.serializer.parseResourceData(data, data.data)

    return { post }
  }

  public getPostsFromJson(data) {
    const posts = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { posts, pagination }
  }

  public async toggleLike(post) {
    const { data } = await this.httpClient.put(`/api/posts/${post.id}/toggle_like`)

    return { like: data.like }
  }

  public async create(params) {
    const { data } = await this.httpClient.post('/api/posts', params)
    const { edit_post_path } = data
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post, edit_post_path }
  }

  public async update(params) {
    const { data } = await this.httpClient.patch(`/api/posts/${params.post.id}`, params)
    const { flush } = data
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post, flush }
  }

  public async changeStateFromNewState(params, newState: 'published' | 'suspended' | 'closed') {
    if (newState === 'published') {
      return await this.publish(params)
    } else if (newState === 'suspended') {
      return await this.suspend(params)
    } else if (newState === 'closed') {
      return await this.close(params)
    }
  }

  public async publish(params) {
    const { data } = await this.httpClient.put(`/api/posts/${params.id}/publish`)
    const { flush, post } = data

    return { flush, post }
  }
  public async suspend(params) {
    const { data } = await this.httpClient.put(`/api/posts/${params.id}/suspend`)
    const { flush, post } = data

    return { flush, post }
  }
  public async close(params) {
    const { data } = await this.httpClient.put(`/api/posts/${params.id}/close`)
    const { flush, post } = data

    return { flush, post }
  }

  public async search(params) {
    const { data } = await this.httpClient.get('/api/posts/search', { params })
    const posts = this.serializer.parseResourceData(data.posts.posts, data.posts.posts.data)
    const pagination = data.posts.posts.meta

    return { posts, pagination }
  }

  public async getFavorites(params) {
    const { data } = await this.httpClient.get('/api/favorites', { params })
    const posts = this.serializer.parseResourceData(data.posts.posts, data.posts.posts.data)
    const pagination = data.posts.posts.meta

    return { posts, pagination }
  }

  public async createCollectionCalendar(post_id, calendar_attributes) {
    const formData = {
      post: { calendar_attributes },
    }
    const { data } = await this.httpClient.post(
      `/api/posts/${post_id}/collection_calendars`,
      formData
    )

    return { flush: data.flush }
  }

  public async getCalendarCollections(post_id, month) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/calendars`, {
      params: { month },
    })
    const calendarCollections = this.serializer.parseResourceData(
      data.calendars,
      data.calendars.data
    )

    return { calendarCollections }
  }

  public async getCalculation(post_id, selectedDate) {
    const { start_date, end_date } = selectedDate
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/calculate`, {
      params: {
        start_date,
        end_date,
      },
    })

    return { price: data.price }
  }

  public async getRelatedPosts(post) {
    const { data } = await this.httpClient.get(`/api/related_posts/${post.id}`)
    const relatedPosts = this.serializer.parseResourceData(data.posts, data.posts.data)

    return { relatedPosts }
  }

  public async createReview(params) {
    return this.httpClient.post(`/api/reviews`, params)
  }

  public async createReviewReply(params) {
    const { data } = await this.httpClient.post(`/api/review_replies`, params)

    return { reviewReply: data }
  }

  public async getTaxies() {
    const { data } = await this.httpClient.get('/admin/api/taxies')
    const taxiList = this.serializer.parseResourceData(data.taxies.taxis, data.taxies.taxis.data)

    return { taxiList }
  }

  public async getNewPosts() {
    const { data } = await this.httpClient.get('/api/posts/recent_index')
    const posts = this.serializer.parseResourceData(data.posts.posts, data.posts.posts.data)
    return { posts }
  }

  public async getCategoriesPosts() {
    const { data } = await this.httpClient.get('/api/posts/categories_index')
    const categories = this.serializer.parseResourceData(data.categories, data.categories.data)
    const all_cat_posts = {}

    if (Object.keys(data.all_cat_posts.length > 0 )){
      Object.keys(data.all_cat_posts).map(
          (key) => {
            all_cat_posts[key] = this.serializer.parseResourceData(data.all_cat_posts[key].posts, data.all_cat_posts[key].posts.data)
        })
    }
    return { all_cat_posts , categories }
  }

  public async getUsers() {
    const { data } = await this.httpClient.get('/admin/api/users/index_without_pagination')
    const users = this.serializer.parseResourceData(data.users, data.users.data)
    // const { data } = await this.httpClient.get('/admin/api/users')
    // const users = this.serializer.parseResourceData(data.users.users, data.users.users.data)

    return { users }
  }
}

export default PostService
