import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IUser } from '../../../../core/interfaces'
import { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET_MOBILE } from '../../../../static/constants'

interface IProps {
  user: IUser
}

const Profile: React.FC<IProps> = ({ user }) => (
  <S.Profile>
    <S.Avator>
      <img src={user.avatar_url} alt="" />
    </S.Avator>
    <S.Info>
      <S.UserName>{user.username}</S.UserName>
      <S.JoinedAt>{I18n.t('avatar.avatar_created_at', { created_at: user.created_at })}</S.JoinedAt>
      <S.Bio>{user.bio}</S.Bio>
    </S.Info>
  </S.Profile>
)

const S: { [key: string]: AnyStyledComponent } = {}

S.Profile = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 24px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    display: block;
  }
`

S.Info = styled.div`
  flex: 1;
  margin-left: 24px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin: 0;
  }
`

S.Avator = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    margin: 0;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin: auto;
  }

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

S.UserName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 8px;
  text-align: left;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    text-align: center;
  }
`

S.JoinedAt = styled.div`
  display: block;
  color: #999;
  font-size: 12px;
  margin-top: 8px;
  text-align: left;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    text-align: center;
  }
`

S.Bio = styled.p`
  margin-top: 24px;
`

export default Profile
