import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { userService } from '../../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import { Button, InputText } from '../../../atoms'
import { Form } from '../../../molecules'

declare var window: IWindow

const FIELDS = {
  username: 'username',
  email: 'email',
  password: 'password',
}

const UserRegistrationsNew: React.FC<{}> = props => {
  const [errors, setErrors] = React.useState<{ username: string; email: string; password: string }>(
    {
      username: '',
      email: '',
      password: '',
    }
  )
  const [confirm, setConfirm] = React.useState<boolean>(false)
  const [email, setEmail] = React.useState<null | string>(null)

  const handleUpdateForm = (error, hasNoError) => {
    setErrors({ ...error })
  }

  const handleSubmit = async (initialValues, values) => {
    const { flush } = await userService.createUser({ user: values })
    setConfirm(true)
    setEmail(values.email)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }
  return (
    <S.UserRegistrationsNew>
      <S.Panel>
        <S.Title>
          <p>{I18n.t('generic.create_new_account')}</p>
        </S.Title>
        {confirm ? (
          <S.PanelConfirmation>
            <p>
              <span>{email}</span>
              <br />
              {I18n.t('session.confirm_email')}
            </p>
          </S.PanelConfirmation>
        ) : (
          <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
            <S.FormItem>
              <InputText
                required={true}
                name="username"
                defaultValue=""
                label=""
                placeholder={I18n.t('generic.username_kana')}
                error={errors.username}
              />
              <S.FormComment>カタカナで入力してください</S.FormComment>
            </S.FormItem>
            <S.FormItem>
              <InputText
                required={true}
                name="email"
                defaultValue=""
                label=""
                placeholder={I18n.t('generic.email')}
                error={errors.email}
              />
            </S.FormItem>
            <S.FormItem>
              <InputText
                required={true}
                password={true}
                name="password"
                defaultValue=""
                label=""
                placeholder={I18n.t('generic.password')}
                error={errors.password}
              />
              <S.FormComment>
                <p>英文字、数字、記号の内2種類以上</p>
                <p>8文字以上32文字以内で作成して下さい</p>
              </S.FormComment>
            </S.FormItem>
            <S.Buttons>
              <Button primary={true}>{I18n.t('generic.create_new_account')}</Button>
              <p>
                <a href="/users/sign_in">{I18n.t('session.have_account')}</a>
              </p>
            </S.Buttons>
          </Form>
        )}
      </S.Panel>
    </S.UserRegistrationsNew>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.UserRegistrationsNew = styled.main`
  padding-top: 36px;
`

S.Title = styled.div`
  text-align: center;
  margin-bottom: 28px;
`

S.Buttons = styled.div`
  margin-top: 24px;
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
  }
`

S.Panel = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
`
S.PanelConfirmation = styled.div`
  text-align: center;
  span {
    font-weight: bold;
  }
`
S.FormItem = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;

  & + & {
    margin-top: 16px;
  }

  p {
    width: 48px;

    span {
      margin-left: 0px;
    }
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  text-align: right;
  color: #777;
  p {
    width: auto;
  }
`

export default UserRegistrationsNew
