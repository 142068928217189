import I18n from 'core/i18n'
import { IAddress } from 'core/interfaces'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import injectGoogleMaps from 'utils/injectGoogleMaps'
import {
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  PHONE_INPUT_PATTERN,
  TEL_INPUT_PATTERN,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import { Button, FormItemLayout, InputText, Spinner } from '../atoms'
import AddressMap from './AddressMap'
export interface IAddressFormMethods {
  getAddress(): IAddress
}

interface IProps {
  defaultAddress?: any // TODO
  withMap?: boolean
  required?: boolean
  phoneRequired?: boolean
  isGuest?: boolean
  errors?: {
    [key: string]: any
  }
  updateForm?(): void
}
const FLASH_TIME = 300
export const ADDRESS_FIELDS = {
  google_full_address: '',
  phone: '',
  postal_code1: null,
  state1: null,
  city1: null,
  other1_1: null,
  other1_2: '',
  latitude1: '',
  longitude1: '',
} as const

export const HOST_ADDRESS_FIELDS = {
  google_full_address: '',
  phone: '',
  emergency_phone: '',
  postal_code1: null,
  state1: null,
  city1: null,
  other1_1: null,
  other1_2: '',
  latitude1: '',
  longitude1: '',
} as const

export const emptyToNull = (key, val) => {
  if (key === 'phone' || key === 'other1_2') {
    return val
  }
  return val.length === 0 ? null : val
}

const addressTemplate: IAddress = {
  google_full_address: '',
  phone: '',
  emergency_phone: '',
  postal_code1: null,
  state1: null,
  city1: null,
  other1_1: null,
  other1_2: '',
} as const

const getInitAddress = ({ google_full_address = '', phone = '', emergency_phone = '', other1_2 }: IAddress) => {
  return {
    google_full_address,
    phone,
    emergency_phone,
    postal_code1: null,
    state1: null,
    city1: null,
    other1_1: null,
    other1_2,
  }
}

const removeDashValue = e => {
  const removedDash = e.target.value.split('-').join('')
  e.target.value = removedDash
  return removedDash
}
let timeoutID = null

const AddressForm = React.forwardRef<IAddressFormMethods, IProps>(
  (
    {
      defaultAddress,
      withMap = true,
      errors = {},
      phoneRequired = true,
      isGuest = false,
      updateForm,
    },
    ref
  ) => {
    const hasDefaultAddress = !!defaultAddress
    const initAddress = defaultAddress ? getInitAddress({ ...defaultAddress }) : addressTemplate
    const [address, setAddress] = React.useState<IAddress>(initAddress)
    const [fullAddress, setFullAddress] = React.useState<string>(initAddress.google_full_address)
    const [updated, setUpdated] = React.useState<boolean>(false)
    const [showInput, setShowInput] = React.useState<boolean>(!hasDefaultAddress)
    const [isRoofTop, setIsRoofTop] = React.useState<boolean>(!hasDefaultAddress)

    // 精度低めの札幌駅
    const [coordinate, setCoordinate] = React.useState({
      latitude: defaultAddress?.latitude1 ?? 43.0696,
      longitude: defaultAddress?.longitude1 ?? 141.3506,
    })

    const other12 = React.useRef(null)

    React.useEffect(() => {
      if (!google) {
        return
      }

      const geocoder = new google.maps.Geocoder()
      geocoder.geocode(
        { location: new google.maps.LatLng(coordinate.latitude, coordinate.longitude) },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            const locationType: string = results[0].geometry.location_type
            const roofTop = locationType === 'ROOFTOP'
            setIsRoofTop(roofTop)
            const newAddress = {
              google_full_address: roofTop ? results[0].formatted_address : '',
              phone: address.phone,
              emergency_phone: address.emergency_phone,
              postal_code1: '',
              state1: '',
              city1: '',
              other1_1: '',
              other1_2: address.other1_2,
            }
            setAddress(newAddress)

            if (roofTop) {
              setFullAddress(results[0].formatted_address)
            } else {
              setFullAddress('')
            }
          }
        }
      )
    }, [coordinate])
    React.useEffect(() => {
      setUpdated(() => {
        if (timeoutID) {
          clearTimeout(timeoutID)
        }
        timeoutID = setTimeout(() => setUpdated(false), FLASH_TIME)
        return true
      })
      if (updateForm) {
        updateForm()
      }
    }, [fullAddress])

    const setFields = place => {
      const result = {
        google_full_address: place.formatted_address,
        postal_code1: '',
        state1: '',
        city1: '',
        other1_1: '',
        other1_2: '',
      }

      setAddress(current => ({
        ...current,
        ...result,
      }))

      setCoordinate({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      })

      if (updateForm) {
        updateForm()
      }
    }

    const showInputForm = () => {
      setShowInput(true)
    }

    const cancelChange = () => {
      if (hasDefaultAddress) {
        setAddress({ ...defaultAddress })
      }
      setShowInput(false)
    }

    const updateOther12 = e => {
      setAddress({
        ...address,
        other1_2: e.target.value,
      })
    }

    const getAddress = () => {
      return {
        ...address,
        latitude1: coordinate.latitude,
        longitude1: coordinate.longitude,
      }
    }

    React.useImperativeHandle(ref, () => ({
      getAddress,
    }))

    return (
      <S.Inner>
        <S.FormItem>
          <InputText
            required={phoneRequired}
            name="phone"
            defaultValue={address?.phone ?? ''}
            label={isGuest ? I18n.t('generic.mobile') : I18n.t('generic.phone')}
            error={errors.phone}
            onBlurHandler={removeDashValue}
            pattern={isGuest ? PHONE_INPUT_PATTERN : TEL_INPUT_PATTERN}
          />
          {isGuest && (
            <S.FormItemNote>
              商品お届け時にこの番号宛にタクシー会社から連絡が入りますので、当日連絡がつく番号を入力ください
            </S.FormItemNote>
          )}
          <S.FormItemNote>半角・ハイフンなしで入力してください</S.FormItemNote>
        </S.FormItem>
        {!isGuest && (
          <S.FormItem>
            <InputText
              name="emergency_phone"
              defaultValue={address?.emergency_phone ?? ''}
              label={'緊急連絡先'}
              error={errors.emergency_phone}
              onBlurHandler={removeDashValue}
              pattern={TEL_INPUT_PATTERN}
            />
          <S.FormItemNote>半角・ハイフンなしで入力してください</S.FormItemNote>
          </S.FormItem>
        )}
        {!showInput && (
          <S.FormItem hidden={showInput}>
            <FormItemLayout label={'現在の住所'}>
              <p>{`${fullAddress ?? ''} ${address?.other1_2 ?? ''}`}</p>
              <S.Button>
                <Button small={true} handleClick={showInputForm}>
                  <a href="#map-input">変更する</a>
                </Button>
              </S.Button>
            </FormItemLayout>
          </S.FormItem>
        )}

        <S.AddressFields hidden={!showInput} id="map-input">
          <FormItemLayout label={'新規住所の設定'}>
            <S.Note>ピンに建物を合わせてください</S.Note>
            {withMap && (
              <AddressMap
                coordinate={coordinate}
                setCoordinate={setCoordinate}
                setFields={setFields}
              />
            )}
          </FormItemLayout>
          {!isRoofTop && (
            <S.FormItemImportantNote>
              位置情報が取得できませんでした。建物が中心に来るように設定してください
            </S.FormItemImportantNote>
          )}
          <S.FormItem>
            <FormItemLayout label="新規住所">
              <S.Completed className={updated ? 'updated' : ''}>{fullAddress}</S.Completed>
              <S.FormItemNote>検索後・ピンの位置変更後に自動で反映されます</S.FormItemNote>
            </FormItemLayout>
          </S.FormItem>
          <S.FormItem>
            <FormItemLayout label="その他の住所（部屋など）">
              <S.Input
                type="text"
                id="other1_2"
                name="other1_2"
                ref={other12}
                value={address.other1_2 ?? ''}
                onChange={updateOther12}
                autocomplete="off"
              />
              {hasDefaultAddress && (
                <S.FormItem>
                  <S.Button>
                    <Button small={true} handleClick={cancelChange}>
                      キャンセル
                    </Button>
                  </S.Button>
                </S.FormItem>
              )}
            </FormItemLayout>
          </S.FormItem>
          <S.Hidden>
            <input
              type="text"
              id="google_full_address"
              name="google_full_address"
              value={fullAddress || ''}
              readOnly={true}
            />
            <input type="text" id="postal_code1" name="postal_code1" readOnly={true} value="" />
            <input type="text" id="state1" name="state1" readOnly={true} value="" />
            <input type="text" id="city1" name="city1" readOnly={true} value="" />
            <input type="text" id="other1_1" name="other1_1" readOnly={true} value="" />
            <input
              type="text"
              id="latitude1"
              name="latitude1"
              readOnly={true}
              value={coordinate.latitude ?? ''}
            />
            <input
              type="text"
              id="longitude1"
              name="longitude1"
              readOnly={true}
              value={coordinate.longitude ?? ''}
            />
          </S.Hidden>
        </S.AddressFields>
      </S.Inner>
    )
  }
)

const S: { [key: string]: AnyStyledComponent } = {}

S.Inner = styled.div`
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: solid 1px ${COLORS.Border};
`

S.Hidden = styled.div`
  display: none;
`

S.FormItemNote = styled.p`
  color: #888;
  font-size: 12px;
  text-align: right;
  margin-top: 4px;
`

S.FormItemImportantNote = styled.p`
  color: ${COLORS.Danger};
  font-size: 12px;
  text-align: right;
  margin-top: 4px;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    text-align: left;
  }
`

S.Button = styled.div`
  margin-top: 12px;
`

S.AddressFields = styled.div<{ hidden?: true }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin-top: 16px;
`
S.Completed = styled.div`
  min-height: 2em;
  border-bottom: solid 1px ${COLORS.Border};
  &.updated {
    animation: flash ease-in ${FLASH_TIME}ms;
  }

  @keyframes flash {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
S.AutocompleteItems = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 16px 24px;
  margin: 16px 0;

  .FormLabel {
    font-size: 12px;
    font-weight: normal;
  }
`

S.Note = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`

S.TextArea = styled.textarea`
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  font-size: 15px;
  transition: border 0.2s ease;
  outline: none;
  background: none;

  &.error {
    border: 1px solid ${COLORS.Danger};
  }

  &:not([readonly]]):focus {
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  }

  &[readonly] {
    /* background-color: ${COLORS.Border}; */
    color: #888;
  }
`

S.Input = styled.input`
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  font-size: 15px;
  transition: border 0.2s ease;
  outline: none;
  background: none;

  &.error {
    border: 1px solid ${COLORS.Danger};
  }

  &:not([readonly]]):focus {
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  }

  &[readonly] {
    /* background-color: ${COLORS.Border};
    color: #888; */
  }
`

S.FormItem = styled.div`
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  text-align: right;
  color: #777;
`

export default AddressForm
