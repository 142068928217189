import * as React from 'react'
import I18n from '../../../../core/i18n'
import { settingsService } from '../../../../core/services'
import { InputText, Spacer } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  settings: any
}

const FIELDS = {
  default_site: 'default_site',
  default_title: 'default_title',
  default_description: 'default_description',
  default_keywords: 'default_keywords',
  // theme_color: 'theme_color',
  // ga_tracking_id: 'ga_tracking_id',
  // skip_identification: 'skip_identification',
  // skip_profile: 'skip_profile',
  // payment_required: 'payment_required',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('setting', { scope: 'activerecord.models' }),
})

const AdminSettingsEdit: React.FC<IProps> = props => {
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = setting.value
    return obj
  }, {})
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const updatedParams: any = {}
    Object.keys(FIELDS).forEach(field => {
      if (initialValues[field] !== values[field]) {
        updatedParams[field] = values[field]
      }
    })

    await settingsService.updateSetting(updatedParams)
    location.href = '/admin/setting'
  }, [])

  return (
    <AdminLayoutEdit
      model="setting"
      title={title}
      fields={FIELDS}
      indexLink="/admin/setting"
      formItems={
        <>
          <InputText
            name="default_site"
            defaultValue={settings.default_site}
            label="Default site"
          />
          <Spacer />
          <InputText
            name="default_title"
            defaultValue={settings.default_title}
            label="Default title"
          />
          <Spacer />
          <InputText
            name="default_description"
            defaultValue={settings.default_description}
            label="Default description"
          />
          <Spacer />
          <InputText
            name="default_keywords"
            defaultValue={settings.default_keywords}
            label="Default keywords"
          />
          {/* <InputText name="theme_color" defaultValue={settings.theme_color} label="Theme color" />
          <InputText
            name="ga_tracking_id"
            defaultValue={settings.ga_tracking_id}
            label="Ga tracking id"
          />
          <InputText
            name="skip_identification"
            defaultValue={settings.skip_identification}
            label="Skip identification"
          />
          <InputText
            name="skip_profile"
            defaultValue={settings.skip_profile}
            label="Skip profile"
          />
          <InputText
            name="payment_required"
            defaultValue={settings.payment_required}
            label="Payment Required"
          /> */}
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminSettingsEdit
