import * as React from 'react'
import { CardElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { settingsService } from '../../../core/services'
import { BREAKPOINT_TABLET_MOBILE, COLORS } from '../../../static/constants'
import { getStripePublicKey } from '../../../utils'
import { Button, Panel } from '../../atoms'
import { FlashMessage, StripeFormNote } from '../../organisms'
import SettingLayoutIndex from '../settings/_layouts'

interface IWindow {
  flashMessages: FlashMessage
}
declare var window: IWindow

interface ICard {
  brand: string
  exp_month: number
  exp_year: number
  id: number
  last4: string
}

const createOptions = () => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: COLORS.Text,
        letterSpacing: '0.5px',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: COLORS.Danger,
      },
    },
  }
}

interface IStripeFormProps {
  stripe: any
  isNew: boolean
  setCard(card: ICard): void
  setEdit(edit: boolean): void
  setIsNew?(isNew: boolean): void
}

const StripeForm: React.FC<IStripeFormProps> = props => {
  const [isProcessing, setIsProcessing] = React.useState(false)

  const getStripeToken = async () => {
    return await props.stripe.createToken()
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsProcessing(true)
    const { token } = await getStripeToken()

    if (token?.id) {
      const { card, flush } = await settingsService.createCard(token.id)
      if (card) {
        props.setCard(card)
        props.setEdit(false)
        if (props.isNew) {
          props.setIsNew(false)
        }
      }
      setIsProcessing(false)
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    } else {
      setIsProcessing(false)
    }
  }

  const renderButtons = () => {
    return props.isNew ? (
      <Button primary={true}>{I18n.t('generic.registration')}</Button>
    ) : (
      <>
        <Button primary={true}>{I18n.t('generic.change')}</Button>
        <Button handleClick={() => props.setEdit(false)}>{I18n.t('generic.cancel')}</Button>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CardElement {...createOptions()} />
        <StripeFormNote />
        {isProcessing ? <div>{I18n.t('generic.connecting')}</div> : renderButtons()}
      </div>
    </form>
  )
}
const StripeInjectedForm = injectStripe(StripeForm)

interface IProps {
  card?: ICard
  isNew: boolean
}

const CardShow: React.FC<IProps> = props => {
  const [card, setCard] = React.useState<ICard>({ ...props.card })
  const [edit, setEdit] = React.useState(false)
  const [isNew, setIsNew] = React.useState(props.isNew)

  const renderRegisterCard = () => {
    return (
      <>
        <h3>{I18n.t('card.register_card')}</h3>
        <StripeProvider apiKey={getStripePublicKey()}>
          <Elements>
            <StripeInjectedForm
              isNew={isNew}
              setCard={setCard}
              setEdit={setEdit}
              setIsNew={setIsNew}
            />
          </Elements>
        </StripeProvider>
      </>
    )
  }

  const renderEditCard = () => {
    return (
      <>
        <h3>{I18n.t('card.registered_cards')}</h3>
        <S.PanelBody>
          {edit ? (
            <StripeProvider apiKey={getStripePublicKey()}>
              <Elements>
                <StripeInjectedForm setCard={setCard} setEdit={setEdit} />
              </Elements>
            </StripeProvider>
          ) : (
            <div>
              <S.RegisteredCard>
                <span>{card.brand}</span>
                <span>{I18n.t('card.card_end')}: </span>
                <span>{card.last4}</span>
                <span className="exp">{I18n.t('generic.expiration_date')}: </span>
                <span>
                  {card.exp_month}/{card.exp_year}
                </span>
              </S.RegisteredCard>
              <Button handleClick={() => setEdit(true)}>{I18n.t('generic.change')}</Button>
              {/* <Button>{I18n.t('card.delete_card')}</Button> */}
            </div>
          )}
        </S.PanelBody>
      </>
    )
  }

  return (
    <SettingLayoutIndex
      activePath="card"
      main={
        <S.Main>
          <Panel title={I18n.t('generic.card')}>
            {isNew ? renderRegisterCard() : renderEditCard()}
          </Panel>
        </S.Main>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Main = styled.div`
  h3 {
    font-size: 16px;
  }

  .Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    max-width: 880px;
    margin: 0 auto;
    padding: 24px;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      padding: 12px 8px;
    }
  }

  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
  }

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }

  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
`
S.RegisteredCard = styled.p`
  span + span {
    margin-left: 8px;
  }

  span.exp {
    margin-left: 24px;
  }

  & + .Button {
    margin-top: 16px;
  }
`
S.PanelBody = styled.div`
  margin-top: 24px;
`
S.StripeFormNote = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`

export default CardShow
