import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { COLORS, THEME_COLOR_VARIABLE_NAME, UPLOAD_FILE_MAX_SIZE } from '../../static/constants'

interface IProps {
  onDrop: any
}

const DropZoneUploader = React.memo<IProps>(props => {
  const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
    accept: 'image/*',
    onDrop: props.onDrop,
    maxSize: UPLOAD_FILE_MAX_SIZE,
    minSize: 0,
  })
  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > UPLOAD_FILE_MAX_SIZE

  return (
    <S.DropZone
      {...getRootProps()}
      className="DropZoneUploader file-drop-input"
      active={isDragActive && !isDragReject}
      rejected={isDragReject || isFileTooLarge}
    >
      <input {...getInputProps()} />
      <p className="file-drop-input-text">
        {!isDragActive && I18n.t('dropzone.select_or_drag_and_drop')}
        {isDragActive && !isDragReject && I18n.t('dropzone.drop')}
        {isDragReject && I18n.t('dropzone.invalid_file')}
        {isFileTooLarge && I18n.t('dropzone.too_large')}
      </p>
    </S.DropZone>
  )
})

const S: { [key: string]: AnyStyledComponent } = {}

S.DropZone = styled.div<{ active: boolean; rejected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(33.33% - 32px);
  height: 240px;
  border: dashed 2px ${COLORS.Border};
  border-radius: 4px;
  margin: 24px 16px 0;
  padding: 8px;
  color: #888;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    color: var(${THEME_COLOR_VARIABLE_NAME});
    border: dashed 2px var(${THEME_COLOR_VARIABLE_NAME});
  `}

  ${({ rejected }) =>
    rejected &&
    `
    color: ${COLORS.Danger};
    border: dashed 2px ${COLORS.Danger};
  `}
`

export default DropZoneUploader
