import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'

interface IPanelProps {
  title?: string | React.ReactElement
}

const StripeFormNote: React.FC<IPanelProps> = ({ title, children }) => {
  return (
    <S.StripeFormNote>
      <S.NoteSection>
        <S.FormComment>CVCとはクレジットカードに印字される暗証番号です。</S.FormComment>
        <S.CVC src="/images/card_logos/cvc.png" />
      </S.NoteSection>

      <S.FormComment>
        カード裏にある署名欄の右上に印字されている事が多いですが、ご不明な場合にはご利用のカード会社へお問い合わせください。
      </S.FormComment>
      <S.NoteSection>
        <h4>■支払方法について</h4>
        <p>ご使用になれる支払方法は、一括支払のみです。</p>
      </S.NoteSection>
      <S.NoteSection>
        <h4>■使用できるクレジットカードについて</h4>
        <p>国内で発行されたVISA、MasterCard、JCB、Dinersカード、AMEXカードがお使いいただけます。</p>
        <div className="images">
          <img src="/images/card_logos/00_visa.jpg" />
          <img src="/images/card_logos/01_master.jpg" />
          <img src="/images/card_logos/02_american.jpg" />
          <img src="/images/card_logos/03_discover.jpg" />
          <img src="/images/card_logos/04_jcb.jpg" />
          <img src="/images/card_logos/05_amex.jpg" />
        </div>
      </S.NoteSection>
      <S.NoteSection>
        <h4>■決済システムについて</h4>
        <p>
          クレジットカード決済にはStripe社のサービスを導入しており、お客様のクレジットカード情報は決済代行会社に保管されるので、当社が保管することはございません。
        </p>
        <p>より安全にご利用いただけます。</p>
      </S.NoteSection>
      <S.NoteSection>
        <h4>■申込者名とカード名義は異なっていても決済可能です</h4>
        <p>(カード名義の入力欄はございません。)</p>
      </S.NoteSection>
    </S.StripeFormNote>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.StripeFormNote = styled.div`
  font-size: 14px;
  padding-bottom: 16px;
`
S.CVC = styled.img`
  max-width: 150px;
`
S.NoteSection = styled.div`
  padding: 8px 0;
  .images {
    padding-top: 8px;
    img {
      padding: 0 8px;
    }
  }
`
S.FormComment = styled.div`
  font-size: 12px;
`
export default StripeFormNote
