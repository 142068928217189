import * as React from 'react'
import makeAsyncScriptLoader from 'react-async-script'
import styled, { AnyStyledComponent } from 'styled-components'
import { Spinner } from '../components/atoms'

const Loading = () => {
  return (
    <S.Loading>
      <Spinner />
    </S.Loading>
  )
}
function asyncLoader() {
  const element = document.querySelector('meta[name="google-api-key"]')
  const apiKey = element ? element.getAttribute('content') : ''
  const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`

  return makeAsyncScriptLoader(url, { globalName: 'google' })(Loading)
}
const AsyncScriptLoader = asyncLoader()

const injectGoogleMaps = (Component: any) => {
  const Wrapper = (props: any) => {
    const [isGoogleLoaded, setGoogleLoaded] = React.useState(false)
    if (isGoogleLoaded) {
      return <Component {...props} />
    }
    return (
      <AsyncScriptLoader
        asyncScriptOnLoad={() => {
          setGoogleLoaded(true)
        }}
      />
    )
  }
  return Wrapper
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Loading = styled.div`
  margin: 50vh auto 0;
`
export default injectGoogleMaps
