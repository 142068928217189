import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IMasterItem, IPost, ITag } from '../../../../core/interfaces'
import {
  BREAKPOINT_TABLET_SMALL,
  BUSINESS_TIMES,
  DAYS_OF_THE_WEEK,
  MIN_ORDER_PRICE,
  VALUES_OF_THE_WEEK_DAYS,
} from '../../../../static/constants'
import { formatPrice } from '../../../../utils/number'
import { Button, CheckBox, InputText, InputTextArea, Select } from '../../../atoms'
import FormItem from '../../../atoms/_layouts/FormItem'
import { Form, InputDateTime } from '../../../molecules'
const BASIC_INFO_FIELDS = {
  event_flag: 'event_flag',
  force_order_date: 'force_order_date',
  newyear_filter: 'newyear_filter',
  category_id: 'category_id',
  name: 'name',
  description: 'description',
  email: 'email',
  note: 'note',
  min_order_price: 'min_order_price',
  business_days: 'business_days',
  delivery_start_time: 'delivery_start_time',
  delivery_end_time: 'delivery_end_time',
  start_time1: 'start_time1',
  end_time1: 'end_time1',
  start_time2: 'start_time2',
  end_time2: 'end_time2',
  start_time3: 'start_time3',
  end_time3: 'end_time3',
}
const EVENT_START_TIME = [{ label: '00:00', value: '00:00' }]
const EVENT_END_TIME = [{ label: '24:00', value: '23:59' }]
interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  isNew: boolean
  categories: any
  isAdmin: boolean
  tags: ITag[]
  conditions: IMasterItem[]
  handleFormSubmit(initialValues: any, values: any): void
  setIsNew(isNew: boolean): void
}

const formatSelectOptions = options => {
  return options.map((option: { id: number; name: string }) => ({
    value: option.id,
    label: option.name,
  }))
}

const formatBusinessTimeOptions = (firstLabel: string) => {
  const times = [{ label: firstLabel, value: '' }]
  BUSINESS_TIMES.map(time =>
    times.push({
      value: time,
      label: time,
    })
  )
  return times
}

const AllCheckedBusinessDays: React.FC<{}> = props => {
  return (
    <>
      {DAYS_OF_THE_WEEK.map((day, index) => (
        <CheckBox
          key={day}
          value={VALUES_OF_THE_WEEK_DAYS[index]}
          name="business_days"
          defaultChecked={true}
          label={day}
          showLabelInline={true}
        />
      ))}
    </>
  )
}

const BasicInfo: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [changeMinOrderPrice, setChangeMinOrderPrice] = React.useState(false)

  const [errors, setErrors] = React.useState<IErrors>({})
  const [eventFlag, setEventFlag] = React.useState<boolean>(props.post.event_flag)
  const handleUpdateForm = React.useCallback((updatedErrors, updatedIsSubmitEnabled, values) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }, [])

  const getMinPrice = () => {
    return props.post.display_min_price > 700 ? props.post.display_min_price : 0
  }

  const getDefaultStartTime1 = () => {
    return props.post.start_time1 ? props.post.start_time1 : I18n.t('meta.post.set_start_time')
  }

  const getDefaultEndTime1 = () => {
    return props.post.end_time1 ? props.post.end_time1 : I18n.t('meta.post.set_end_time')
  }

  return (
    <Form
      fields={BASIC_INFO_FIELDS}
      handleUpdateForm={handleUpdateForm}
      handleSubmit={props.handleFormSubmit}
    >
      <ul>
        <FormItem label={I18n.t('meta.post.event_flag')} required={false} error={errors.event_flag}>
          <CheckBox
            name="event_flag"
            defaultChecked={props.post.event_flag}
            onChangeHandler={e => {
              setEventFlag(e.target.checked)
            }}
            readonly={!props.isAdmin}
          />
        </FormItem>
        <S.FormItem hidden={!eventFlag}>
          <FormItem
            label={I18n.t('meta.post.force_order_date')}
            required={false}
            error={errors.force_order_date}
          >
            <CheckBox
              name="force_order_date"
              defaultChecked={props.post.force_order_date}
              readonly={!props.isAdmin}
            />
          </FormItem>
        </S.FormItem>
        <S.FormItem hidden={!eventFlag}>
          <FormItem
            label={I18n.t('meta.post.newyear_filter')}
            required={false}
            error={errors.newyear_filter}
          >
            <CheckBox
              name="newyear_filter"
              defaultChecked={props.post.newyear_filter}
              readonly={!props.isAdmin}
            />
          </FormItem>
        </S.FormItem>

        <S.FormItem>
          <Select
            required={true}
            name="category_id"
            label={I18n.t('generic.category')}
            options={formatSelectOptions(props.categories)}
            defaultValue={props.post.category ? String(props.post.category.id) : null}
            error={errors.category_id}
          />
        </S.FormItem>
        <S.FormItem>
          <InputText
            required={true}
            name="name"
            label={I18n.t('post.post_name')}
            placeholder={I18n.t('post.post_name')}
            defaultValue={props.post.name}
            error={errors.name}
          />
          <S.FormComment>*最大100文字</S.FormComment>
        </S.FormItem>
        <S.FormItem>
          <InputText
            required={false}
            name="email"
            label={I18n.t('post.email')}
            placeholder={I18n.t('post.email')}
            defaultValue={props.post.email}
            error={errors.email}
          />
          <S.FormComment>*最大100文字</S.FormComment>
        </S.FormItem>
        <S.FormItem>
          <InputTextArea
            required={true}
            name="description"
            label={I18n.t('post.post_description')}
            placeholder={I18n.t('post.post_description')}
            defaultValue={props.post.description}
            error={errors.description}
            maxLength={500}
          />
          <S.FormComment>*最大500文字</S.FormComment>
        </S.FormItem>
        <S.FormItem>
          <InputTextArea
            required={false}
            name="note"
            label={I18n.t('post.note')}
            placeholder={I18n.t('post.note')}
            defaultValue={props.post.note}
            error={errors.description}
            maxLength={500}
          />
          <S.FormComment>*最大500文字</S.FormComment>
        </S.FormItem>
        {/* <S.FormItem hidden={changeMinOrderPrice}>
          <S.FormDisplayValue>
            <S.FormLabel>
              <span>最低注文金額 </span>
            </S.FormLabel>
            <S.FormValue>
              {formatPrice(props.post.display_min_price)}
              <Button primary={false} small={true} handleClick={() => setChangeMinOrderPrice(true)}>
                設定する
              </Button>
            </S.FormValue>
          </S.FormDisplayValue>
        </S.FormItem> */}
        <S.FormItem>
          <InputText
            readonly={true}
            name="min_order_price"
            label={I18n.t('post.min_order_price')}
            placeholder={I18n.t('post.min_order_price')}
            defaultValue={`${MIN_ORDER_PRICE}`}
            error={errors.name}
          />
          {/* <S.FormComment>
            <p>*{MIN_ORDER_PRICE}円が最低値です</p>
            <p>*下部の「更新する」ボタンで反映されます</p>
          </S.FormComment> */}
        </S.FormItem>

        <S.FormItem hidden={!eventFlag}>
          <InputDateTime
            required={eventFlag}
            name="delivery_start_time"
            label={I18n.t('meta.post.delivery_start_time')}
            defaultValue={props.post.delivery_start_time ? props.post.delivery_start_time : ''}
            error={errors.delivery_start_time}
          />
        </S.FormItem>
        <S.FormItem hidden={!eventFlag}>
          <InputDateTime
            required={eventFlag}
            name="delivery_end_time"
            label={I18n.t('meta.post.delivery_end_time')}
            defaultValue={props.post.delivery_end_time ? props.post.delivery_end_time : ''}
            error={errors.delivery_end_time}
          />
        </S.FormItem>

        <S.FormItem>
          <S.BusinessDays>
            <FormItem
              label={I18n.t('meta.post.buisiness_days')}
              required={true}
              error={errors.buisiness_days}
            >
              <div>
                {DAYS_OF_THE_WEEK.map((day, index) => (
                  <CheckBox
                    key={day}
                    value={VALUES_OF_THE_WEEK_DAYS[index]}
                    name="business_days"
                    defaultChecked={
                      props.post.business_days
                        ? props.post.business_days
                            .map((dayItem: string) => dayItem)
                            .includes(VALUES_OF_THE_WEEK_DAYS[index])
                        : false
                    }
                    label={day}
                    showLabelInline={true}
                  />
                ))}
              </div>
            </FormItem>
          </S.BusinessDays>
        </S.FormItem>

        {/* set start end time */}
        <S.FormItem>
          <Select
            required={true}
            name="start_time1"
            label={I18n.t('meta.post.start_time1')}
            options={formatBusinessTimeOptions(I18n.t('meta.post.set_start_time'))}
            defaultValue={getDefaultStartTime1()}
            error={errors.start_time1}
          />
        </S.FormItem>
        <S.FormItem>
          <Select
            required={true}
            name="end_time1"
            label={I18n.t('meta.post.end_time1')}
            options={formatBusinessTimeOptions(I18n.t('meta.post.set_end_time'))}
            defaultValue={getDefaultEndTime1()}
            error={errors.end_time1}
          />
        </S.FormItem>

        <S.FormItem>
          <Select
            name="start_time2"
            label={I18n.t('meta.post.start_time2')}
            options={formatBusinessTimeOptions(I18n.t('meta.post.set_start_time'))}
            defaultValue={
              props.post.start_time2 ? props.post.start_time2 : I18n.t('meta.post.set_start_time')
            }
            error={errors.start_time2}
          />
        </S.FormItem>
        <S.FormItem>
          <Select
            name="end_time2"
            label={I18n.t('meta.post.end_time2')}
            options={formatBusinessTimeOptions(I18n.t('meta.post.set_end_time'))}
            defaultValue={
              props.post.end_time2 ? props.post.end_time2 : I18n.t('meta.post.set_end_time')
            }
            error={errors.end_time2}
          />
        </S.FormItem>
        <S.FormItem>
          <Select
            name="start_time3"
            label={I18n.t('meta.post.start_time3')}
            options={formatBusinessTimeOptions(I18n.t('meta.post.set_start_time'))}
            defaultValue={
              props.post.start_time3 ? props.post.start_time3 : I18n.t('meta.post.set_start_time')
            }
            error={errors.start_time3}
          />
        </S.FormItem>
        <S.FormItem>
          <Select
            name="end_time3"
            label={I18n.t('meta.post.end_time3')}
            options={formatBusinessTimeOptions(I18n.t('meta.post.set_end_time'))}
            defaultValue={
              props.post.end_time3 ? props.post.end_time3 : I18n.t('meta.post.set_end_time')
            }
            error={errors.end_time3}
          />
        </S.FormItem>
      </ul>
      <S.Footer className="Editor_Footer">
        <Button primary={true} disabled={!isSubmitEnabled}>
          {props.isNew ? I18n.t('generic.create') : I18n.t('generic.update')}
        </Button>
      </S.Footer>
    </Form>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.FormItemTitle = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`

S.FormDisplayValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
S.FormLabel = styled.p`
  display: flex;
  align-items: center;
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
`

S.FormValue = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  button {
    margin-left: 16px;
  }
`
S.BusinessDays = styled.div`
  display: flex;
`
S.Footer = styled.div`
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-top: 20px;
  }
`

export default BasicInfo
