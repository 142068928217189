import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IWindow } from '../../../../../core/interfaces'
import { reservationService } from '../../../../../core/services'
import { Button, Select } from '../../../../atoms'
import { Form } from '../../../../molecules'

declare var window: IWindow

interface IDelivery {
  status: string
}
interface IProps {
  reservationId: number
  deliveryStatus: string
  setDeliveryStatus(IDelivery): void
}

const StatusModal: React.FC<IProps> = props => {
  const [modalErrors, setModalErrors] = React.useState<{ [key: string]: null | string }>({
    reason: null,
  })
  const [isSubmitable, setIsSubmitable] = React.useState<boolean>(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const deliveryOptions = [
    {
      label: '準備中',
      value: 'requested',
    },
    {
      label: 'タクシーへの受渡完了',
      value: 'delivered',
    },
  ]
  const onChangeHandle = e => {
    setIsSubmitable(
      deliveryOptions.findIndex(option => option.value === e.target.value) >
        deliveryOptions.findIndex(option => option.value === props.deliveryStatus)
    )
  }

  const handleSubmit = async (initialValues, value) => {
    setIsProcessing(true)
    if (value.status === 'delivered') {
      const { flush, reservation } = await reservationService.delivered(props.reservationId)
      props.setDeliveryStatus(reservation.delivery)
      window.globalModal.closeModal()
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    }
  }

  return (
    <Form
      fields={{ status: 'status' }}
      handleUpdateForm={updatedErrors => {
        setModalErrors(updatedErrors)
      }}
      handleSubmit={handleSubmit}
    >
      <Select
        required={true}
        name="status"
        options={deliveryOptions}
        label={I18n.t('generic.status')}
        defaultValue="requested"
        onChangeHandler={onChangeHandle}
      />
      <S.Footer>
        <Button primary={true} disabled={isProcessing || !isSubmitable}>
          {I18n.t('generic.update')}
        </Button>
      </S.Footer>
    </Form>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}

S.Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`
export default StatusModal
