import { IUser } from 'core/interfaces'
import * as React from 'react'
import styled, { AnyStyledComponent, createGlobalStyle } from 'styled-components'
import I18n from '../../../core/i18n'
import { LogoTodoxiPc, LogoTodoxiSp } from '../../../icon/index'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
} from '../../../static/constants'
import { IsDesktop, IsMobileOrTablet } from '../../../utils/responsive'
import Button from '../../atoms/Button'
import MenuList from './MenuList'
import Search from './Search'

interface IWindow {
  localePath: { [key: string]: string }
  location: any
}
declare var window: IWindow

interface IProps {
  isSignedIn: boolean
  user: any
  notifications: any[]
  lang?: { [key: string]: string }
  top?: boolean
}

const Avatar: React.FC<{ user: IUser; layout: 'desktop' | 'mobile' }> = ({ user, layout }) => (
  <S.Avator layout={layout}>
    <S.AvatorName>{user.username}</S.AvatorName>
    <S.AvatorIcon>
      <img src={user.avatar_url} />
    </S.AvatorIcon>
  </S.Avator>
)

const Header: React.FC<IProps> = props => {
  const [isBurgerOpen, setIsBurgerOpen] = React.useState(false)
  const { isSignedIn, user } = props

  React.useEffect(() => {
    if (props.lang) {
      window.localePath = { ...props.lang }
    }
  }, [])

  return (
    <S.Header top={props.top}>
      <GlobalStyle top={props.top} />
      <S.Inner>
        <S.Flex>
          <S.Logo href="/">
            {' '}
            <LogoTodoxiPc />{' '}
          </S.Logo>
          <S.LogoTabletMobile href="/">
            <LogoTodoxiSp />
          </S.LogoTabletMobile>
        </S.Flex>
        {isSignedIn ? (
          <S.Flex>
            <IsDesktop>
              {/* {props.lang && (
                <S.Margin>
                  <LocaleSelect />
                </S.Margin>
              )} */}
              {/* <Notifications user={props.user} /> */}
              <S.Profile>
                <Avatar user={user} layout="desktop" />
                <MenuList role={props.user.role} layout="desktop" isSignedIn={isSignedIn} />
              </S.Profile>
              <S.Buttons>
                <Button small={true} textColor={COLORS.Primary}>
                  <a rel="nofollow" data-method="delete" href="/users/sign_out">
                    {I18n.t('top_nav.logout')}
                  </a>
                </Button>
              </S.Buttons>
            </IsDesktop>
            <IsMobileOrTablet>
              <S.Burger isOpen={isBurgerOpen} onClick={() => setIsBurgerOpen(!isBurgerOpen)}>
                <span />
                <span />
                <span />
              </S.Burger>
              {isBurgerOpen && (
                <S.Mobile top={props.top}>
                  <Avatar user={user} layout="mobile" />
                  <MenuList role={props.user.role} layout="mobile" isSignedIn={isSignedIn} />
                </S.Mobile>
              )}
            </IsMobileOrTablet>
          </S.Flex>
        ) : (
          <>
            <IsDesktop>
              <S.Buttons>
                <Button small={true} textColor={COLORS.Primary}>
                  <a href="/users/sign_up">{I18n.t('top_nav.signup')}</a>
                </Button>
                <Button small={true} textColor={COLORS.Primary}>
                  <a href="/users/sign_in">{I18n.t('top_nav.login')}</a>
                </Button>
              </S.Buttons>
            </IsDesktop>
            <IsMobileOrTablet>
              <S.Burger isOpen={isBurgerOpen} onClick={() => setIsBurgerOpen(!isBurgerOpen)}>
                <span />
                <span />
                <span />
              </S.Burger>
              {isBurgerOpen && (
                <S.Mobile top={props.top}>
                  {props.isSignedIn && <Avatar user={user} layout="mobile" />}
                  <MenuList layout="mobile" isSignedIn={isSignedIn} />
                </S.Mobile>
              )}
            </IsMobileOrTablet>
          </>
        )}
      </S.Inner>
    </S.Header>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

const GlobalStyle = createGlobalStyle<{ top: boolean }>`
  main {
    padding-top: 0;
    margin-top: ${HEADER_HEIGHT}px;
    
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin-top: ${HEADER_HEIGHT_MOBILE}px;
    }
  }
`

S.Inner = styled.div`
  display: flex;
  height: ${HEADER_HEIGHT}px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: ${HEADER_HEIGHT_MOBILE}px;
  }
`

S.Header = styled.div<{ top?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  padding: 0px 16px;
  border-bottom: solid 1px ${COLORS.Border};
  background-color: #fff;
  z-index: 5000;

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: ${HEADER_HEIGHT_MOBILE}px;
  }
  ${({ top }) =>
    top &&
    `
    height: ${HEADER_HEIGHT}px;
    background-color: #fff;
    border-bottom: none;
    color: #000;
    
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      height: ${HEADER_HEIGHT_MOBILE}px;
    }

    ${S.Inner} {
      display: flex;
      height: ${HEADER_HEIGHT}px;
      margin: 0 auto;
      
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        height: ${HEADER_HEIGHT_MOBILE}px;
      }
    }
  `}
`

S.Margin = styled.div`
  margin-right: 24px;
`

S.Flex = styled.div`
  display: flex;
  align-items: center;
`

S.Logo = styled.a`
  width: 138px;
  height: 62px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    display: none;
  }
`
S.LogoTabletMobile = styled.a`
  width: 84px;
  height: 38px;
  @media (min-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    display: none;
  }
  > svg {
    width: 84px;
    height: 38px;
  }
`

S.Mobile = styled.div<{ top: boolean }>`
  position: absolute;
  top: ${HEADER_HEIGHT_MOBILE}px;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
  color: ${COLORS.Text};

  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    top: ${HEADER_HEIGHT_MOBILE}px;
    position: fixed;
    z-index: 8000;
  }
`

S.Burger = styled.div<{ isOpen: boolean }>`
  position: relative;
  margin-right: -16px;
  width: ${HEADER_HEIGHT}px;
  height: ${HEADER_HEIGHT}px;

  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9000;
    height: ${HEADER_HEIGHT_MOBILE}px;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  > span {
    display: block;
    height: 1px;
    width: 16px;
    left: calc(50% - 8px);
    position: absolute;
    background-color: currentColor;
    transform-origin: center;
    transition-duration: 86ms;
    transition-property: background-color, opacity, transform;
    transition-timing-function: ease-out;

    :nth-child(1) {
      top: calc(50% - 6px);
    }

    :nth-child(2) {
      top: calc(50% - 1px);
    }

    :nth-child(3) {
      top: calc(50% + 4px);
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    > span {
      :nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      :nth-child(2) {
        opacity: 0;
      }

      :nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  `}
`

S.Avator = styled.div<{ layout: 'desktop' | 'mobile' }>`
  display: flex;
  align-items: center;

  ${({ layout }) =>
    layout === 'mobile' &&
    `
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 16px;
  `}
`

S.AvatorName = styled.span`
  padding: 0 12px;
`

S.AvatorIcon = styled.span`
  width: 40px;
  height: 40px;
  margin: 2px;
  background: #fff;
  border-radius: 50%;
  margin-left: 10px;
  overflow: hidden;

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

S.Profile = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  margin-left: 32px;
  cursor: pointer;

  :hover {
    .MenuList {
      display: block;
    }
  }
`

S.Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > button {
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px;
    border: none;
  }
`

export default Header
