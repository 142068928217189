import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { reservationService } from '../../../../core/services'
import { COLORS } from '../../../../static/constants'
import * as forms from '../../../../utils/form'
import { Button } from '../../../atoms'
import { Form } from '../../../molecules'

interface IProps {
  user: any
  conversation: any
}

const FIELDS_MESSAGE = {
  file: 'file',
  body: 'body',
}

const MessageForm: React.FC<IProps> = ({ user, conversation }) => {
  const messageBodyRef = React.useRef(null)

  const [messageAttachFile, setMessageAttachFile] = React.useState(null)
  const [messageAttachFileUrl, setMssageAttachFileUrl] = React.useState(null)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(null)
  const [errors, setErrors] = React.useState({})

  const handleUpdateMessage = React.useCallback(
    (updatedErrors, updatedIsSubmitEnabled) => {
      setErrors(updatedErrors)
      setIsSubmitEnabled(updatedIsSubmitEnabled)
    },
    [errors, isSubmitEnabled]
  )

  const handleSubmitMessage = React.useCallback(
    async (initialValues, values) => {
      const formData = new FormData()

      Object.keys(FIELDS_MESSAGE).forEach(field => {
        if (field === 'file') {
          if (messageAttachFile) {
            formData.append(`message[${field}]`, messageAttachFile)
          }
        } else {
          formData.append(`message[${field}]`, values[field])
        }
      })
      formData.append('message[sender_id]', user.id)
      formData.append('message[conversation_id]', conversation.id)

      await reservationService.createMessage(formData)

      forms.setNativeValue(messageBodyRef.current, '', false)
      messageBodyRef.current.dispatchEvent(new Event('input', { bubbles: true }))
      // reset message[file]
      setMessageAttachFile(null)
      setMssageAttachFileUrl('')
    },
    [messageAttachFile]
  )

  const updateMessageFile = file => {
    const fileReader = new FileReader()

    fileReader.onloadend = () => {
      setMessageAttachFile(file)
      setMssageAttachFileUrl(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  }

  return (
    <S.MessageForm>
      <S.Baloon>
        <Form
          fields={FIELDS_MESSAGE}
          handleSubmit={handleSubmitMessage}
          handleUpdateForm={handleUpdateMessage}
        >
          <S.Header>
            <textarea
              required={true}
              placeholder={I18n.t('reservation.input_message')}
              name="body"
              ref={messageBodyRef}
            />
            {messageAttachFileUrl && (
              <S.ImagePreview>
                <img src={messageAttachFileUrl} alt="" />
                <S.PreviewDelete
                  onClick={() => {
                    setMessageAttachFile(null)
                    setMssageAttachFileUrl('')
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </S.PreviewDelete>
              </S.ImagePreview>
            )}
          </S.Header>
          <S.Footer>
            <Button>
              <label>
                <input
                  name="file"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={event => updateMessageFile(event.currentTarget.files[0])}
                />
                {I18n.t('reservation.add_image')}
              </label>
            </Button>
            <Button primary={true} disabled={!isSubmitEnabled}>
              {I18n.t('generic.send')}
            </Button>
          </S.Footer>
        </Form>
      </S.Baloon>
      <S.Avator>
        <img src={user.avatar_url} alt="" />
      </S.Avator>
    </S.MessageForm>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.MessageForm = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: solid 1px ${COLORS.Border};

  input[type='file'] {
    display: none;
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 8px 12px;
    border: none;
  }
`

S.ImagePreview = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.75);

  > img {
    height: inherit;
  }
`

S.PreviewDelete = styled.span`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  > svg {
    fill: #fff;
  }
`

S.Header = styled.div`
  margin-bottom: 12px;
`

S.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

S.Baloon = styled.div`
  flex: 1;
  padding: 16px;
  margin-right: 12px;
  border: solid 1px ${COLORS.Border};
  border-radius: 12px;
  border-top-right-radius: 0;
`

S.Avator = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

export default MessageForm
