import { clamp, defer } from 'lodash'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'

interface IValue {
  min: number | null
  max: number | null
}

interface IProps {
  value: IValue
  setDragging?(dragging: boolean): void
  onChangeHandler?(value: IValue): any
}

const Slider: React.FC<IProps> = props => {
  const sliderRef = React.useRef(null)
  const [dragItem, setDragItem] = React.useState(null)
  const [sliderValue, setSliderValue] = React.useState({ min: null, max: null })

  // Set slider value by passed value
  React.useEffect(() => {
    const sliderWidth = sliderRef.current.offsetWidth
    setSliderValue({ min: sliderWidth * props.value.min, max: sliderWidth * props.value.max })
  }, [props.value])

  const onDragStart = React.useCallback(
    event => {
      if (dragItem) {
        return
      }
      event.persist()
      const sliderWidth = sliderRef.current.offsetWidth
      const boundingClientRect = sliderRef.current.getBoundingClientRect()
      const value = event.nativeEvent.pageX - boundingClientRect.left
      const targetDragItem =
        Math.abs(sliderValue.min - value) < Math.abs(sliderValue.max - value) ? 'start' : 'end'
      const nextSliderValue =
        targetDragItem === 'start'
          ? { min: value, max: sliderValue.max }
          : { min: sliderValue.min, max: value }

      if (typeof props.onChangeHandler === 'function') {
        props.onChangeHandler({
          min: nextSliderValue.min / sliderWidth,
          max: nextSliderValue.max / sliderWidth,
        })
      }

      setDragItem(targetDragItem)
      setSliderValue(nextSliderValue)
      if (typeof props.setDragging === 'function') {
        props.setDragging(true)
      }
    },
    [sliderValue, dragItem]
  )

  const onDragEnd = event => {
    defer(() => {
      setDragItem(null)
      if (typeof props.setDragging === 'function') {
        props.setDragging(false)
      }
    })
  }

  React.useEffect(() => {
    const sliderWidth = sliderRef.current.offsetWidth
    const boundingClientRect = sliderRef.current.getBoundingClientRect()

    const onMouseMoveHandler = event => {
      const value = event.pageX - boundingClientRect.left

      setSliderValue(currentValue => {
        const clampedValue =
          dragItem === 'start'
            ? clamp(value, 0, currentValue.max)
            : clamp(value, currentValue.min, sliderWidth)

        const changedValue =
          dragItem === 'start'
            ? { min: clampedValue, max: currentValue.max }
            : { min: currentValue.min, max: clampedValue }

        if (typeof props.onChangeHandler === 'function') {
          props.onChangeHandler({
            min: changedValue.min / sliderWidth,
            max: changedValue.max / sliderWidth,
          })
        }

        return changedValue
      })
    }

    if (dragItem) {
      window.addEventListener('mousemove', onMouseMoveHandler)
      window.addEventListener('mouseup', onDragEnd)
      document.body.style.setProperty('-webkit-user-select', 'none')
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMoveHandler)
      window.removeEventListener('mouseup', onDragEnd)
      document.body.style.removeProperty('-webkit-user-select')
    }
  }, [dragItem])

  return (
    <S.Slider className="Slider" onMouseDown={onDragStart} ref={sliderRef}>
      {typeof sliderValue.min === 'number' && (
        <S.Control type="min" style={{ left: `${sliderValue.min}px` }} />
      )}
      {typeof sliderValue.min === 'number' && typeof sliderValue.max === 'number' && (
        <>
          <S.Rail type="base" />
          <S.Rail
            type="active"
            style={{ left: sliderValue.min, width: `${sliderValue.max - sliderValue.min}px` }}
          />
        </>
      )}
      {typeof sliderValue.max === 'number' && (
        <S.Control type="max" style={{ left: `${sliderValue.max}px` }} />
      )}
    </S.Slider>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Slider = styled.div`
  position: relative;
  height: 32px;
  margin: 0 16px 16px;
  cursor: pointer;
`

S.Control = styled.div<{ type: 'min' | 'max' }>`
  -webkit-user-select: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  background-color: var(${THEME_COLOR_VARIABLE_NAME});
  border-radius: 50%;
  box-shadow: 0 0 3px 0 rgba(21, 27, 38, 0.15);
  z-index: 1000;

  ${({ type }) =>
    type === 'min' &&
    `
    left: 0;
    transform: translateX(-50%);
  `}

  ${({ type }) =>
    type === 'max' &&
    `
    left: 320px;
    transform: translateX(-50%);
  `}
`

S.Rail = styled.div<{ type: 'base' | 'active' }>`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 2px;

  ${({ type }) =>
    type === 'base' &&
    `
    background-color: ${COLORS.Border};
    left: 0;
    width: 100%;
  `}

  ${({ type }) =>
    type === 'active' &&
    `
    background-color: var(${THEME_COLOR_VARIABLE_NAME});
  `}
`

export default Slider
