import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import {
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../static/constants'

const ENTER_KEYCODE = 13

const Search: React.FC<{}> = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const defaultArea = urlParams.get('area') || ''
  const defaultKeywords = urlParams.get('keywords') || ''
  const defaultTime = urlParams.get('time') || ''

  const onSearchHandler = async event => {
    const currentUrlParams = new URLSearchParams(window.location.search)

    if (event.target.value) {
      currentUrlParams.set(event.target.name, event.target.value)
    } else {
      currentUrlParams.delete(event.target.name)
    }

    location.href = `/posts/search?${currentUrlParams.toString()}`
  }

  const onKeyDownHandler = event => {
    if (event.keyCode && event.keyCode === ENTER_KEYCODE) {
      onSearchHandler(event)
    }
  }
  if (window.location.search === '') {
    return null
  }
  return (
    <S.Search>
      {/*
      <div className="Search_Input _area">
        <input
          name="area"
          type="text"
          placeholder={I18n.t('generic.area')}
          defaultValue={defaultArea}
          onBlur={onSearchHandler}
          onKeyDown={onKeyDownHandler}
        />
        <i className="material-icons">room</i>

      </div>
    */}
      {/* <div className="Search_Input _time">
        <select
          name="time"
          defaultValue={defaultTime}
          onChange={event => {
            onSearchHandler(event)
          }}
        >
          <option value="">時間を指定</option>
          <option value="0-3">0〜3時</option>
          <option value="4-7">4〜7時</option>
          <option value="8-11">8〜11時</option>
          <option value="12-15">12〜15時</option>
          <option value="16-19">16〜19時</option>
          <option value="20-23">20〜23時</option>
        </select>
      </div> */}
      <div className="Search_Input _keywords">
        <input
          name="keywords"
          type="text"
          placeholder={I18n.t('generic.keyword')}
          defaultValue={defaultKeywords}
          onBlur={onSearchHandler}
          onKeyDown={onKeyDownHandler}
        />
        <i className="material-icons">search</i>
      </div>
    </S.Search>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Search = styled.div`
  display: flex;
  position: relative;
  width: 360px;
  margin-right: 14px;
  margin-left: 10px;

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-left: 5px;
  }

  .Search_Input {
    position: relative;
    display: flex;
    align-items: center;
    &._area {
      margin-right: 24px;
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        width: 96px;
        margin-right: 12px;
      }
    }
    &._time {
      width: 154px;
      margin-right: 24px;
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        width: 127px;
        margin-right: 12px;
      }
    }
    &._keywords {
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        width: 140px;
      }
    }

    > i {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 8px;
      margin: auto;
      width: 24px;
      height: 24px;
    }
    > input,
    select {
      display: block;
      width: 100%;
      height: 43px;
      border: solid 1px ${COLORS.Border};
      font-size: 15px;
      transition: border 0.2s ease;
      outline: none;
      background: none;
    }
    > select {
      appearance: button;
      background-color: white;
      padding: 0 12px;
      > .time_placeholder {
        color: #babdbf;
      }
    }
    > input {
      padding: 0 12px 0 36px;

      ::placeholder {
        color: #babdbf;
      }

      :focus {
        border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
      }
    }
  }
`

export default Search
