import I18n from 'core/i18n'
import IReservationErrorItem from 'core/interfaces/IReservationErrorItem'

export function formatReservationError(errors: IReservationErrorItem[]): string {
  let message = ''
  errors.forEach(element => {
    message += element.name + ' : ' + I18n.t('post.in_stock') + element.in_stock + '. '
  })
  return message
}
