import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { settingsService } from '../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import { Button, DropZoneUploader, Panel } from '../../atoms'
import SettingLayoutIndex from './_layouts'
import { ActiveTypes } from './Nav'

declare var window: IWindow

interface IProps {
  identification: IJsonResponse
  active_path: ActiveTypes
}

interface IPreviewImage {
  file: any
  url: string
}

const Identification: React.FC<IProps> = props => {
  // console.log('settings/identification props', props)
  const { data: identification } = settingsService.getDataFromJson(props.identification)
  // console.log('identification', identification)
  const [previewImages, setPreviewImage] = React.useState<IPreviewImage[]>([])
  const [requested, setRequested] = React.useState(false)
  const onDropHandler = addedImages => {
    addedImages.forEach(addedImage => {
      const fileReader = new FileReader()

      fileReader.onloadend = (event: any) => {
        const loadedImage = {
          file: addedImage,
          url: event.target.result,
        }

        setPreviewImage(currentPreviewImages => [...currentPreviewImages, loadedImage])
      }
      fileReader.readAsDataURL(addedImage)
    })
  }

  const submitIdentification = React.useCallback(async () => {
    let result
    for (const image of previewImages) {
      result = await settingsService.createIdentificationImage(image.file)
    }
    if (result) {
      setRequested(true)
      const { flush } = result
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    }
  }, [previewImages])

  const deletePreviewImage = deleteIndex => {
    setPreviewImage(currentPreviewImages =>
      currentPreviewImages.filter((image, index) => index !== deleteIndex)
    )
  }

  const renderState = state => {
    return (
      <S.Field>
        <label>{I18n.t('generic.status')}</label>
        <div>{state}</div>
      </S.Field>
    )
  }

  const renderUploadField = () => {
    return requested ? (
      <S.Requested>{I18n.t('settings.images_has_sent')}</S.Requested>
    ) : (
      <div>
        <S.Field>
          <label>{I18n.t('generic.image')}</label>
          <S.Preview>
            {previewImages.map((image, index) => (
              <S.PreviewItem key={index}>
                <S.PreviewImage>
                  <img src={image.url} alt="" />
                </S.PreviewImage>
                <Button handleClick={() => deletePreviewImage(index)}>
                  {I18n.t('generic.delete')}
                </Button>
              </S.PreviewItem>
            ))}
            <DropZoneUploader onDrop={onDropHandler} />
          </S.Preview>
        </S.Field>
        <S.Footer>
          <Button
            primary={true}
            handleClick={submitIdentification}
            disabled={previewImages.length === 0}
          >
            {I18n.t('generic.send')}
          </Button>
        </S.Footer>
      </div>
    )
  }

  return (
    <SettingLayoutIndex
      activePath={props.active_path}
      main={
        <S.Main>
          <Panel title={I18n.t('settings.set_identification')}>
            {identification && identification.workflow_state
              ? renderState(identification.workflow_state)
              : renderUploadField()}
          </Panel>
        </S.Main>
      }
    />
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.Field = styled.div`
  display: flex;

  & + & {
    margin-top: 24px;
    padding-top: 20px;
    border-top: solid 1px ${COLORS.Border};
  }

  > label {
    width: 200px;
    font-weight: bold;
  }

  > div {
    flex: 1;
  }

  a {
    color: var(${THEME_COLOR_VARIABLE_NAME});
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
  }
`

S.Main = styled.div`
  .DropZoneUploader {
    width: 100%;
    margin: 12px 0 0;
  }
`
S.Preview = styled.div`
  img {
    display: block;
    max-width: 400px;
  }

  .Button {
    margin-top: 12px;
  }
`
S.Footer = styled.div`
  margin-top: 24px;
`
S.Requested = styled.div`
  text-align: center;
  padding: 16px 0;
`
S.PreviewImage = styled.div`
  width: 360px;
  height: 240px;
  background-color: #fafafa;

  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`
S.PreviewItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: solid 1px ${COLORS.Border};

  .Button {
    margin-left: 24px;
  }
`

export default Identification
