import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { postService } from '../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../static/constants'
import { Pagination } from '../../atoms'
import { Button } from '../../atoms'
import StateChangeModal from './new/StateChangeModal'

declare var window: IWindow

interface IProps {
  posts: {
    posts: IJsonResponse
  }
}

interface IState {
  id: string
  label: string
  value: string
}

const COLUMNS = [
  {
    name: I18n.t('post.post_name'),
    field: 'name',
  },
  {
    name: '',
    field: '',
  },
  {
    name: 'ID',
    field: 'id',
  },
  {
    name: I18n.t('generic.status'),
    field: 'aasm_state',
  },
  {
    name: I18n.t('generic.updated_at'),
    field: 'updated_at',
  },
  {
    name: I18n.t('generic.created_at'),
    field: 'created_at',
  },
]

function getCurrentSortColumn() {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('sort') || 'name'
}

function getCurrentSortDirection() {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('direction') || 'asc'
}

function trimText(text) {
  const MAX_LENGTH = 50

  if (typeof text === 'string') {
    return text.length > MAX_LENGTH ? `${text.slice(0, MAX_LENGTH)}...` : text
  } else {
    return ''
  }
}

const PostIndex: React.FC<IProps> = props => {
  const { posts, pagination } = postService.getPostsFromJson(props.posts.posts)
  const [order, setOrder] = React.useState({
    column: getCurrentSortColumn(),
    direction: getCurrentSortDirection(),
  })
  // const tableRef = React.useRef(null)
  // const scrollerRef = React.useRef(null)

  // React.useEffect(() => {
  //   if (tableRef && scrollerRef) {
  //     const tableWidth = tableRef.current.offsetWidth
  //     scrollerRef.current.style.width = `${tableWidth}px`
  //   }
  // }, [tableRef, scrollerRef])

  const getDirection = column => {
    if (order.column === column) {
      return order.direction === 'asc' ? 'desc' : 'asc'
    } else {
      return 'asc'
    }
  }

  const changeOrder = column => {
    const direction = getDirection(column)

    location.href = `/posts?sort=${column}&direction=${direction}`
  }
  const getOrderIndicator = column => {
    if (order.column !== column) {
      return null
    } else {
      return order.direction === 'asc' ? '↓' : '↑'
    }
  }

  const onChangePageHandler = page => {
    const params = new URLSearchParams(window.location.search)
    params.set('page', page)

    location.href = `${location.pathname}?${params.toString()}`
  }

  const Statuses = []
  const initstates = posts.forEach(post => {
    Statuses.push({ id: post.id, label: post.aasm_state_i18n, value: post.aasm_state })
  })
  const [publishState, setPublishState] = React.useState<IState>(Object)
  const [updatedStatuses, setStatuses] = React.useState<IState[]>(Statuses)

  const showStateChangeModal = (post, index) => {
    window.globalModal.showModal({
      title: I18n.t('reservation.update_publish'),
      body: (
        <StateChangeModal
          post={post}
          currentStatus={updatedStatuses[index].value}
          setPublishState={setPublishState}
          statuses={updatedStatuses}
          setStatuses={setStatuses}
        />
      ),
    })
  }

  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>{I18n.t('top_nav.posts')}</h2>
      </div>
      <div className="Wrapper_Main">
        <PostList>
          <table>
            <thead>
              <tr>
                {COLUMNS.map(column => (
                  <th key={column.name} onClick={() => changeOrder(column.field)}>
                    {getOrderIndicator(column.field)}
                    {` ${column.name}`}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {posts.map((post, index) => (
                <tr key={index}>
                  <td className="PostList_Image_Colmun">
                    <div className="PostList_Image">
                      {post.post_image && post.post_image.image_url ? (
                        <img src={post.post_image.image_url} alt="" />
                      ) : (
                        <p className="PostList_NoImage">No Image</p>
                      )}
                    </div>
                    <a className="post_name" href={`/posts/${post.slug}`} target="_blank">
                      {' '}
                      {post.name}
                    </a>
                  </td>
                  <td>
                    <a href={`/posts/${post.slug}/edit`}>{I18n.t('reservation.edit')}</a>
                  </td>
                  <td>
                    <p className="td_label">{I18n.t('generic.id')}</p>
                    {post.id}
                  </td>
                  <td>
                    <p className="td_label">{I18n.t('generic.status')}</p>
                    <p>{updatedStatuses[index].label}</p>
                    <Button
                      primary={true}
                      small={true}
                      handleClick={() => showStateChangeModal(post, index)}
                    >
                      {I18n.t('reservation.update')}
                    </Button>
                  </td>
                  <td>
                    <p className="td_label">{I18n.t('generic.updated_at')}</p>
                    {post.updated_at}
                  </td>
                  <td>
                    <p className="td_label">{I18n.t('generic.created_at')}</p>
                    {post.created_at}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pagination && (
            <Pagination
              onChangePageHandler={onChangePageHandler}
              currentPage={pagination.current_page}
              prevPage={pagination.prev_page}
              nextPage={pagination.next_page}
              totalCount={pagination.total_count}
              totalPages={pagination.total_pages}
            />
          )}
        </PostList>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;

  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Wrapper_Main {
    overflow: scroll;
  }
`

const PostList = styled.div`
  width: 100vw;
  font-size: 14px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    font-size: 12px;
  }
  .PostList_Image {
    width: 160px;
    height: 120px;
    border-radius: 3px;
    overflow: hidden;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      width: 120px;
      height: 90px;
    }
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: inline-block;
      min-width: 80px;
      min-height: 60px;
    }
    > img {
      object-fit: cover;
      width: inherit;
      height: inherit;
    }
  }

  .PostList_NoImage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
  }

  .td_label {
    display: none;
    width: 40px;
    margin-right: 4px;
    font-weight: bold;

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: inline-block;
    }
  }
  .PostList_Image_Colmun {
    max-width: 184px;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      max-width: 144px;
    }

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      max-width: 100%;
    }
  }
  .post_name {
    display: inline-block;
    white-space: pre;
    font-weight: bold;
    padding: 4px 0;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      white-space: pre-wrap;
      margin-left: 8px;
    }
  }

  table {
    width: auto;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      width: 100%;
    }
  }
  thead {
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: none;
    }
  }
  th,
  td {
    padding: 8px 12px;
    max-width: 240px;
    min-width: 5em;
    word-break: keep-all;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
      padding: 4px 6px;
      max-width: 100vw;
    }
    > a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  th {
    cursor: pointer;

    &:hover {
      background-color: #f6f8f9;
    }
  }

  tbody {
    > tr {
      border-top: solid 1px ${COLORS.Border};
    }
  }

  .Pagination {
    height: 48px;
    border-top: solid 1px ${COLORS.Border};
  }
`

export default PostIndex
