import * as moment from 'moment'
import * as React from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'

interface ISelectedDate {
  start: null | moment.Moment
  end: null | moment.Moment
}

interface IEdit {
  reserved: boolean
  price: string
  availablility: string
}

interface IProps {
  selectedDate: ISelectedDate
  updateDateInfo: () => void
  focusedInput: null | 'startDate' | 'endDate'
  onDateRangePickerChange: (dates: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => void
  changeFocusedInput: (focusedInput: string | null) => void
  upDateEditValue: (event: React.FormEvent<HTMLInputElement>) => void
  clearSelectedDate: () => void
  edit: IEdit
  anyTouched: boolean
}

interface IButtonProps {
  primary?: boolean
}

interface IInputProps {
  text?: boolean
  radio?: boolean
}

const themeColor = getComputedStyle(document.documentElement)
  .getPropertyValue(THEME_COLOR_VARIABLE_NAME)
  .trim()

const CalendarDetail: React.FC<IProps> = props => {
  const {
    selectedDate,
    updateDateInfo,
    focusedInput,
    onDateRangePickerChange,
    changeFocusedInput,
    upDateEditValue,
    clearSelectedDate,
    edit,
    anyTouched,
  } = props

  return (
    <Detail>
      {moment.isMoment(selectedDate.start) && moment.isMoment(selectedDate.end) ? (
        <form onSubmit={updateDateInfo}>
          <Inputs>
            <h2>{I18n.t('calendar.selected_date')}</h2>
            <DateRangePicker
              startDate={selectedDate.start}
              startDateId="startDate"
              endDate={selectedDate.end}
              endDateId="endDate"
              onDatesChange={onDateRangePickerChange}
              focusedInput={focusedInput}
              onFocusChange={(currentFocusedInput: string | null) =>
                changeFocusedInput(currentFocusedInput)
              }
              isOutsideRange={() => false}
              numberOfMonths={1}
            />
            {edit.reserved ? (
              <p>{I18n.t('calendar.cannot_edit_reserved_days')}</p>
            ) : (
              <div>
                <Input text={true}>
                  <label>{I18n.t('generic.price')}</label>
                  <input type="text" name="price" onChange={upDateEditValue} value={edit.price} />
                </Input>
                <Input radio={true}>
                  <h3>{I18n.t('calendar.state')}</h3>
                  <label>
                    <input
                      type="radio"
                      name="availablility"
                      value="available"
                      checked={edit.availablility === 'available'}
                      onChange={upDateEditValue}
                    />
                    <span>{I18n.t('calendar.available')}</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="availablility"
                      value="blocked"
                      checked={edit.availablility === 'blocked'}
                      onChange={upDateEditValue}
                    />
                    <span>{I18n.t('calendar.blocked')}</span>
                  </label>
                </Input>
              </div>
            )}
          </Inputs>
          <Buttons>
            <Button primary={true} type="submit" disabled={!anyTouched}>
              {I18n.t('generic.save')}
            </Button>
            <Button type="button" onClick={clearSelectedDate}>
              {I18n.t('generic.cancel')}
            </Button>
          </Buttons>
        </form>
      ) : (
        <h2>{I18n.t('calendar.selected_date')}</h2>
      )}
    </Detail>
  )
}

const Detail = styled.div`
  position: relative;
  width: 340px;
  margin-left: -1px;
  border-left: solid 1px ${COLORS.Border};
  padding: 24px;

  h2 {
    margin: 0 0 16px;
    font-size: 16px;
  }

  > form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
  }
`

const Inputs = styled.div`
  flex: 1;

  > p {
    margin-top: 24px;
  }
`

const Buttons = styled.div`
  display: flex;
  height: 40px;
`

const Input = styled.div`
  margin-top: 32px;

  ${(props: IInputProps) =>
    props.text &&
    `
    label {
      display: block;
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 14px;
    }

    > input[type="text"] {
      // reset default style
      padding: 0;
      border: none;
      border-radius: 0;
      outline: none;
      background: none;

      // added style
      width: 100%;
      padding: 8px 12px;
      border: solid 1px ${COLORS.Border};
      border-radius: 4px;
    }
  `}

  ${(props: IInputProps) =>
    props.radio &&
    `
    > h3 {
      display: block;
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 14px;
    }

    > label {
      display: block;
      position: relative;
      cursor: pointer;

      &:hover {
        opacity: .75;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      span {
        font-size: 14px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }

        &::before {
          width: 18px;
          height: 18px;
          border: solid 1px ${COLORS.Border};
          border-radius: 50%;
        }
      }

      input:checked + span {
        &::before {
          width: 18px;
          height: 18px;
          border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
          border-radius: 50%;
        }

        &::after {
          right: 4px;
          width: 10px;
          height: 10px;
          background-color: var(${THEME_COLOR_VARIABLE_NAME});
          border-radius: 50%;
        }
      }
    }

    > label + label {
      margin-top: 16px;
    }
  `}
`

const Button = styled.button`
  // reset default style
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;

  // added style
  display: block;
  height: 40px;
  border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  border-radius: 4px;
  padding: 0 12px;
  color: ${(props: IButtonProps) => (props.primary ? '#fff' : themeColor)};
  background-color: ${(props: IButtonProps) => (props.primary ? themeColor : 'transparent')};

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    background-color: ${COLORS.Border};
    border: solid 1px ${COLORS.Border};
  }

  & + & {
    margin-left: 12px;
  }
`

export default CalendarDetail
